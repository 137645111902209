import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { PayrollClosesReducer } from './payroll-close.reducers';
import { PayrollClosesEffects } from './payroll-close.effects';

@NgModule({
  imports: [
    StoreModule.forFeature('payrollCloses', PayrollClosesReducer),
    EffectsModule.forFeature([PayrollClosesEffects]),
  ],
})
export class PayrollCloseStoreModule {}
