import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'eventStatusValue',
})
export class EventStatusValuePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}
  transform(value: unknown, ...args: unknown[]): unknown {
    // let result = EventStatus.find((x) => x.value === value);
    // if (result !== undefined || result === null) {
    //   return result.label;
    // }
    // return [null, undefined].includes(args[0]) ? '' : args[0];
    if ([null, undefined].includes(value)) {
      return this.translateService.instant('commons.requestSent');
    }
    return this.translateService.instant('commons.' + value); // expected approved or rejected
  }
}
