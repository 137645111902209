import { Injectable } from '@angular/core';
import {
  ExportsService,
  PayrollCloseDetailService,
  PayrollCloseService,
} from '@app/tenant/services';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import {
  catchError,
  map,
  mergeMap,
  repeat,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { PayrollCloseActions } from './payroll-close.actions';
import { selectSelectedCompany } from '../session';

@Injectable()
export class PayrollClosesEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly payrollCloseService: PayrollCloseService,
    private readonly payrollCloseDetailService: PayrollCloseDetailService,
    private readonly exportService: ExportsService,
    private store: Store<{}>
  ) {}

  getPayrollCloses$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PayrollCloseActions.getPayrollCloses),
      withLatestFrom(this.store.select(selectSelectedCompany)),
      switchMap(([{ beginAt, endAt, status }, selectedCompany]) =>
        this.payrollCloseService.getPayrollCloses(
          selectedCompany.id,
          beginAt,
          endAt,
          status
        )
      ),
      map((response) =>
        PayrollCloseActions.getPayrollClosesSuccess({
          payrollCloses: response.data,
        })
      ),
      catchError((payload) =>
        of(PayrollCloseActions.catchErrorFromApi({ payload }))
      ),
      repeat()
    )
  );

  getAPayrollClose$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PayrollCloseActions.getAPayrollClose),
      mergeMap(({ payrollCloseId }) =>
        this.payrollCloseService.getAPayrollClose(payrollCloseId)
      ),
      map((response) =>
        PayrollCloseActions.getAPayrollCloseSuccess({
          payrollClose: response.data,
        })
      ),
      catchError((payload) =>
        of(PayrollCloseActions.catchErrorFromApi({ payload }))
      ),
      repeat()
    )
  );

  closePayrollClose$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PayrollCloseActions.closePayrollClose),
      switchMap(({ payrollCloseId, sendEmailVoucher }) =>
        this.payrollCloseService.closePayrollClose(
          payrollCloseId,
          sendEmailVoucher
        )
      ),
      map((response) =>
        PayrollCloseActions.closePayrollCloseSuccess({
          payrollClose: response.data,
        })
      ),
      tap(({ payrollClose }) => {
        this.store.dispatch(
          PayrollCloseActions.downloadPayrollCloseCsv({ payrollClose })
        );
      }),
      catchError((payload) =>
        of(PayrollCloseActions.catchErrorFromApi({ payload }))
      ),
      repeat()
    )
  );

  calculatePayrollClose$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PayrollCloseActions.calculatePayrollClose),
      mergeMap(({ payrollCloseId }) =>
        this.payrollCloseService.calculate(payrollCloseId).pipe(
          catchError((payload) => {
            this.store.dispatch(
              PayrollCloseActions.calculatePayrollCloseFail({ payrollCloseId })
            );
            return of(payload);
          })
        )
      ),
      map(() => PayrollCloseActions.calculatePayrollCloseSuccess()),
      catchError((payload) =>
        of(PayrollCloseActions.catchErrorFromApi({ payload }))
      ),
      repeat()
    )
  );

  deleteAPayrollClose$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PayrollCloseActions.deletePayrollClose),
      switchMap(({ payrollCloseId }) =>
        this.payrollCloseService.delete(payrollCloseId)
      ),
      map((response) =>
        PayrollCloseActions.deletePayrollCloseSuccess({
          payrollClose: response.data,
        })
      ),
      catchError((payload) =>
        of(PayrollCloseActions.catchErrorFromApi({ payload }))
      ),
      repeat()
    )
  );

  createPayrollClose$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PayrollCloseActions.createPayrollClose),
      switchMap(({ payrollId, beginAt, endAt }) =>
        this.payrollCloseService.create(payrollId, beginAt, endAt)
      ),
      map((response) =>
        PayrollCloseActions.createPayrollCloseSuccess({
          payrollClose: response.data,
        })
      ),
      catchError((payload) =>
        of(PayrollCloseActions.catchErrorFromApi({ payload }))
      ),
      repeat()
    )
  );

  downloadCsv$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PayrollCloseActions.downloadPayrollCloseCsv),
      switchMap(({ payrollClose }) => {
        this.exportService.getPayrollDetails(
          [payrollClose.id],
          payrollClose.payroll.name,
          payrollClose.beginAt,
          payrollClose.endAt
        );
        return of(payrollClose);
      }),
      map(() => PayrollCloseActions.downloadPayrollCloseCsvSuccess()),
      catchError((payload) =>
        of(PayrollCloseActions.catchErrorFromApi({ payload }))
      ),
      repeat()
    )
  );

  downloadFullCsv$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PayrollCloseActions.downloadPayrollCloseFullCsv),
      switchMap(({ payrollClose }) => {
        this.exportService.getPayrollDetailsFullDetails(payrollClose);
        return of(payrollClose);
      }),
      map(() => PayrollCloseActions.downloadPayrollCloseFullCsvSuccess()),
      catchError((payload) =>
        of(PayrollCloseActions.catchErrorFromApi({ payload }))
      ),
      repeat()
    )
  );

  updateDetailNote$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PayrollCloseActions.updateAPayrollCloseDetailNote),
      switchMap(({ payrollCloseDetailId, note }) =>
        this.payrollCloseDetailService.updateNote(payrollCloseDetailId, note)
      ),
      map((response) =>
        PayrollCloseActions.updateAPayrollCloseDetailNoteSuccess({
          payrollCloseDetail: response.data,
        })
      ),
      catchError((payload) =>
        of(PayrollCloseActions.catchErrorFromApi({ payload }))
      ),
      repeat()
    )
  );

  refreshDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PayrollCloseActions.refreshAPayrollCloseDetail),
      switchMap(({ payrollCloseDetail }) =>
        this.payrollCloseDetailService.getById(payrollCloseDetail.id)
      ),
      map((response) =>
        PayrollCloseActions.refreshAPayrollCloseDetailSuccess({
          payrollCloseDetail: response.data,
        })
      ),
      catchError((payload) =>
        of(PayrollCloseActions.catchErrorFromApi({ payload }))
      ),
      repeat()
    )
  );
}
