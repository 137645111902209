import { map } from 'rxjs/operators';
import { ServiceDataStorage } from '@shared/helpers';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UsersService extends ServiceDataStorage {
  constructor() {
    super('users');
  }
  filterByType(type: string) {
    return this.customRequest({ fullPath: `users?filters[type]=${type}` });
  }
  show(userId: number) {
    return this.httpService
      .get(`users/${userId}`)
      .pipe(map((response) => response.data))
      .toPromise();
  }
  showByIds(userIds: Array<number>) {
    return this.httpService
      .get(`users/by-ids?${userIds.map((x) => `ids[]=${x}`).join('&')}`)
      .pipe(map((response) => response.data))
      .toPromise();
  }
}
