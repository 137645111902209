import { ServiceDataStorage } from '@shared/helpers';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CalculationRulesToShowService extends ServiceDataStorage {
  constructor() {
    super('calculation-rules/rules');
  }
  /**
   * get rules that only belongs income
   * @returns Array<any>
   */
  getIncomes(): Array<any> {
    this.refresh({}).catch(() => {});
    return (this.getCurrentData() as Array<any>).filter(
      (x) => x.amount !== undefined
    );
  }
  /**
   * Get rules that is only issues of attendance
   * @returns Array<any>
   */
  getIssues(): Array<any> {
    this.refresh({});
    // TODO: dynamic Or not hardcoded
    return (this.getCurrentData() as Array<any>).filter(
      (x) =>
        !['ordinario', 'doble descanso', 'extra', 'extra doble'].includes(
          x.label.toLowerCase()
        )
    );
  }
}
