import { Observable } from 'rxjs';
import { ServiceDataStorage } from '@shared/helpers';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class EmployeesService extends ServiceDataStorage {
  constructor() {
    super('companies', 'employees');
  }
  /**
   * upload media files to an Employee
   * @param employeeId employee id
   * @param files array of files to upload
   * @returns observable response
   */
  uploadMedia(
    employeeId: number,
    files: Array<{ label: string; value: any }>
  ): Observable<any> {
    // form data to send data
    const form = new FormData();
    files.forEach((file) => {
      form.append(file.label, file.value);
    });
    form.append('_method', 'POST');
    return this.httpService.post(`employees/${employeeId}/media`, form);
  }
  /**
   * delete multiple keys of files for an employee
   * @param employeeId employee id
   * @param files array of keys name of files
   * @returns observavle response
   */
  deleteMedia(employeeId: number, files: Array<string>) {
    let keys = files.map((x) => `keys[]=${x}`).join('&');
    return this.httpService.delete(`employees/${employeeId}/media?${keys}`);
  }
  /**
   * Get settings from an employee
   * @param employeeId employee id
   * @returns promise response
   */
  getSettings(employeeId: number): Promise<any> {
    return this.customRequest({
      method: 'get',
      fullPath: `employees/${employeeId}/settings`,
      updateSource: false,
    });
  }
  /**
   * Update settings from an employee
   * @param employeeId employee id
   * @param settings data of settings
   * @returns promise response
   */
  updateSettings(employeeId: number, settings: any): Promise<any> {
    return this.customRequest({
      method: 'put',
      fullPath: `employees/${employeeId}/settings`,
      data: settings,
      updateSource: false,
    });
  }
  /**
   * Get salary from an employee
   * @param employeeId employee id
   * @returns promise response
   */
  getSalary(employeeId: number): Promise<any> {
    return this.customRequest({
      method: 'get',
      fullPath: `employees/${employeeId}/salary`,
      updateSource: false,
    });
  }
  /**
   * Update salary from an employee
   * @param employeeId employee id
   * @param salary data of salary
   * @returns promise response
   */
  updateSalary(employeeId: number, salary: any): Promise<any> {
    return this.customRequest({
      method: 'put',
      fullPath: `employees/${employeeId}/salary`,
      data: salary,
      updateSource: false,
    });
  }
  destroyMassive(
    companyId: number,
    ids: Array<number>,
    description: string,
    deletedAt: Date
  ) {
    return this.httpService.post(
      `companies/${companyId}/employees/destroy/massive`,
      { ids, description, deletedAt }
    );
  }
  forceDelete(companyId: number, employeeId: number) {
    return this.httpService.delete(
      `companies/${companyId}/employees/${employeeId}/force`
    );
  }
  getPayrolls(employeeId: number) {
    return this.httpService
      .get(`employees/${employeeId}/payrolls`)
      .pipe(map((response) => response.data))
      .toPromise();
  }
}
