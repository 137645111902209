import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-roundend-status-icon',
  templateUrl: './roundend-status-icon.component.html',
  styles: [],
})
export class RoundendStatusIconComponent implements OnInit {
  @Input() item: any = null;
  constructor() {}

  ngOnInit(): void {}
}
