export class Screen {
  public static isMobile(): boolean {
    return window.innerWidth <= 991;
  }
  public static isDesktop(): boolean {
    return window.innerWidth > 991;
  }
  public static useTouchUICalendar(): boolean {
    return (
      window.innerHeight <= 768 && document.documentElement.scrollTop > 100
    );
  }
}
