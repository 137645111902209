import { LocalStorageService } from '@shared/services';
import { Observable, Subscription } from 'rxjs';
import { AuthenticationService, PermissionHelperService } from '@core/services';
import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
  AbstractControl,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  AuthActions,
  AuthState,
  selectAuthError,
  selectAuthIsLoading,
  selectTenantIdFound,
} from '@app/store/auth';
import { SessionState, selectProfile } from '@app/store/session';

@Component({
  selector: 'app-login',
  templateUrl: './app.login.component.html',
})
export class AppLoginComponent implements OnInit, OnDestroy {
  dark: boolean = false;
  loginForm: UntypedFormGroup;
  customerByUrl: boolean = false;

  error$: Observable<any> = this.store.select(selectAuthError);
  loading$: Observable<boolean> = this.store.select(selectAuthIsLoading);

  _subscription: Subscription = new Subscription();

  // convenience getter for easy access to form fields
  get f() {
    return this.loginForm.controls;
  }

  constructor(
    private authService: AuthenticationService,
    private permissionHelperService: PermissionHelperService,
    private route: ActivatedRoute,
    private store: Store<AuthState>,
    private storeSession: Store<SessionState>,
    private localStorageService: LocalStorageService
  ) {
    this.loginForm = new UntypedFormGroup({
      customerId: new UntypedFormControl('', Validators.required),
      username: new UntypedFormControl('', Validators.required),
      password: new UntypedFormControl('', Validators.required),
    });
    // if is authenticated navigate to home
    if (this.authService.isAuthenticated()) {
      this.permissionHelperService.navigateToHome();
    }
    this._subscription
      .add(
        this.store.select(selectTenantIdFound).subscribe((found) => {
          if (found === true) {
            this.login();
          }
        })
      )
      .add(
        this.storeSession.select(selectProfile).subscribe((profile) => {
          if (profile.id !== -1) {
            this.permissionHelperService.navigateToHome();
          }
        })
      );
  }
  ngOnInit(): void {
    // get customerId from localStorage
    this.setInLocalStorageCustomerId(this.localStorageService.getCustomerId());
    // get customerId from route param
    this.route.queryParams.subscribe((params) =>
      this.setInLocalStorageCustomerId(params['customer'])
    );
  }
  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  onSubmit() {
    if (this.f.customerId.value === 'central') {
      this.login();
    } else {
      this.findTenant();
    }
  }

  login() {
    this.store.dispatch(
      AuthActions.login({
        username: this.f.username.value,
        password: this.f.password.value,
        tenant: this.f.customerId.value !== 'central',
      })
    );
  }
  findTenant() {
    this.store.dispatch(
      AuthActions.findTenant({ customerId: this.f.customerId.value })
    );
  }

  setInLocalStorageCustomerId(customerId: string) {
    if (![undefined, null, ''].includes(customerId)) {
      this.f.customerId.setValue(customerId);
      this.customerByUrl = true;
    }
  }
}
