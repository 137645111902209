import { Company, Profile } from '@app/core/models';

export interface SessionState {
  isLoading: boolean;
  selectedCompany: Company;
  companies: ReadonlyArray<Company>;
  profile: Profile;
  profileFetchDone: boolean;
  companiesFetchDone: boolean;
  canChangeBetweenModules: boolean;
  menuItems: any[];
  passwordChanged: boolean;
  error: any;
}
