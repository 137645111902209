import { LocalStorageService } from '@shared/services';
import { AuthenticationService, PasswordService } from '@core/services';
import { Component, OnInit } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
  AbstractControl,
} from '@angular/forms';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styles: [],
})
export class ForgotPasswordComponent implements OnInit {
  pwdForm: UntypedFormGroup;
  error: string = '';
  loading: boolean = false;
  successEmail: boolean = false;

  // convenience getter for easy access to form fields
  get f() {
    return this.pwdForm.controls;
  }

  constructor(
    private authService: AuthenticationService,
    private pwdService: PasswordService,
    private localStorageService: LocalStorageService
  ) {
    this.pwdForm = new UntypedFormGroup({
      customerId: new UntypedFormControl('', Validators.required),
      username: new UntypedFormControl('', Validators.required),
    });
  }

  ngOnInit(): void {}

  onSubmit() {
    this.loading = true;
    if (this.f.customerId.value === 'central') {
      this.sendEmail();
    } else {
      this.findTenant();
    }
  }

  findTenant() {
    this.authService
      .findTenant(this.f.customerId.value)
      .toPromise()
      .then(() => this.sendEmail())
      .catch((error) => {
        this.localStorageService.clear({});
        this.error = error.error.error;
      })
      .finally(() => (this.loading = false));
  }
  sendEmail() {
    this.pwdService
      .create(this.f.username.value)
      .toPromise()
      .then(() => {
        this.successEmail = true;
      })
      .catch((error) => {
        this.localStorageService.clear({});
        this.error = error.error.error;
      })
      .finally(() => (this.loading = false));
  }
}
