import { AppComponent } from './app.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppMainComponent } from '@app/core/mirage/';
import { AuthGuard } from '@core/guards';

const routes: Routes = [
  {
    path: '',
    component: AppComponent,
    loadChildren: () =>
      import('./core/core.module').then((module) => module.CoreModule),
  },
  {
    path: 'central',
    component: AppMainComponent,
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./central/central.module').then((module) => module.CentralModule),
  },
  {
    path: 'customer',
    component: AppMainComponent,
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./tenant/tenant.module').then((module) => module.TenantModule),
  },
  {
    path: 'shared',
    component: AppMainComponent,
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./shared/shared.module').then((module) => module.SharedModule),
  },
  {
    path: 'subscription',
    component: AppComponent,
    loadChildren: () =>
      import('./paddle/paddle.module').then((module) => module.PaddleModule),
  },
  {
    path: 'signup',
    component: AppComponent,
    loadChildren: () =>
      import('./signup/signup.module').then((module) => module.SignupModule),
  },
  {
    path: 'employee',
    component: AppMainComponent,
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./self-management/self-management.module').then(
        (module) => module.SelfManagementModule
      ),
  },
  { path: '**', redirectTo: '/login' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
