export const ItemsMenu = [
  {
    label: 'tenant.menu.home',
    icon: 'pi pi-chart-bar',
    routerLink: ['/customer/home'],
  },
  {
    label: 'tenant.menu.companies',
    icon: 'fas fa-building',
    routerLink: ['/customer/companies'],
    permission: 'view_company',
  },
  {
    label: 'tenant.menu.employees',
    icon: 'fas fa-users',
    routerLink: ['/customer/employees'],
    permission: ['view_employee', 'view_personnel_action', 'view_vacation_log'],
    items: [
      {
        label: 'tenant.menu.list',
        routerLink: ['/customer/employees/list'],
        permission: 'view_employee',
      },
      {
        label: 'tenant.menu.requests',
        routerLink: ['/customer/employees/auth-personnel-actions'],
        permission: ['view_personnel_action'],
      },
      {
        label: 'tenant.menu.vacations',
        routerLink: ['/customer/employees/vacations'],
        permission: ['view_vacation_log'],
      },
    ],
  },
  {
    label: 'tenant.menu.scheduling',
    icon: 'fas fa-calendar-day',
    routerLink: ['/customer/catalogs'],
    permission: ['view_holiday', 'view_schedule', 'view_event'],
    items: [
      {
        label: 'tenant.menu.holidays',
        routerLink: ['/customer/catalogs/holidays'],
        permission: 'view_holiday',
      },
      {
        label: 'tenant.menu.schedules',
        routerLink: ['/customer/catalogs/schedules'],
        permission: 'view_schedule',
      },
      {
        label: 'tenant.menu.calendar',
        routerLink: ['/customer/catalogs/calendar'],
        permission: 'view_event',
      },
    ],
  },
  {
    label: 'tenant.menu.payrolls',
    icon: 'fas fa-file-invoice-dollar',
    routerLink: ['/customer/payrolls'],
    permission: [
      'view_payroll',
      'view_payroll_close',
      'view_deduction',
      'view_extra_month',
    ],
    items: [
      {
        label: 'tenant.menu.deductions',
        routerLink: ['/customer/payrolls/deductions'],
        permission: 'view_deduction',
      },
      {
        label: 'tenant.menu.list',
        routerLink: ['/customer/payrolls/payrolls'],
        permission: 'view_payroll',
      },
      {
        label: 'tenant.menu.payrolls_in_process',
        routerLink: ['/customer/payrolls/payroll-closes-opens'],
        permission: 'view_payroll_close',
      },
      {
        label: 'tenant.menu.closed_payrolls',
        routerLink: ['/customer/payrolls/payroll-closes-history'],
        permission: 'view_payroll_close',
      },
      {
        label: 'tenant.menu.bonuses_in_process',
        routerLink: ['/customer/payrolls/extra-months-opens'],
        permission: 'view_extra_month',
      },
      {
        label: 'tenant.menu.closed_bonuses',
        routerLink: ['/customer/payrolls/extra-months-closed'],
        permission: 'view_extra_month',
      },
    ],
  },
  {
    label: 'tenant.menu.loans',
    icon: 'fa-solid fa-receipt',
    routerLink: ['/customer/loans'],
    permission: ['view_loan', 'create_loan_payment'],
    items: [
      {
        label: 'tenant.menu.open_loans',
        routerLink: ['/customer/loans/open-loans'],
        permission: ['view_loan', 'create_loan_payment'],
      },
      {
        label: 'tenant.menu.closed_loans',
        routerLink: ['/customer/loans/closed-loans'],
        permission: 'view_loan',
      },
    ],
  },
  {
    label: 'tenant.menu.attendance_control',
    icon: 'fas fa-business-time',
    routerLink: ['/customer/attendance'],
    permission: [
      'view_real_time_logs',
      'view_conventional_card',
      'view_calculated_card',
    ],
    items: [
      {
        label: 'tenant.menu.real_time_logs',
        routerLink: ['/customer/attendance/real-time-logs'],
        permission: 'view_real_time_logs',
      },
      {
        label: 'tenant.menu.conventional_card',
        routerLink: ['/customer/attendance/conventional-card'],
        permission: 'view_conventional_card',
      },
      {
        label: 'tenant.menu.analyzed_card',
        routerLink: ['/customer/attendance/calculated-card'],
        permission: 'view_calculated_card',
      },
      {
        label: 'tenant.menu.location_history',
        routerLink: ['/customer/attendance/attendance-timeline'],
        permission: 'view_conventional_card',
      },
    ],
  },
  {
    label: 'tenant.menu.settings',
    icon: 'fas fa-cog',
    routerLink: ['/customer/config'],
    permission: [
      'view_additional_field',
      'view_general_settings_payroll',
      'view_bank_entity_xls_settings',
      'view_vacation_settings',
      'view_subscription',
    ],
    items: [
      {
        label: 'tenant.menu.employee_form',
        routerLink: ['/customer/config/fields-employees'],
        permission: 'view_additional_field',
      },
      {
        label: 'tenant.menu.payroll',
        routerLink: ['/customer/config/payroll-general-settings'],
        permission: 'view_general_settings_payroll',
      },
      {
        label: 'tenant.menu.xls_for_bank',
        routerLink: ['/customer/config/bank-entity-xls'],
        permission: 'view_bank_entity_xls_settings',
      },
      {
        label: 'tenant.menu.vacations',
        routerLink: ['/customer/config/vacation-settings'],
        permission: 'view_vacation_settings',
      },
      {
        label: 'tenant.menu.subscription',
        routerLink: ['/customer/config/subscription'],
        permission: 'view_subscription',
      },
    ],
  },
  {
    label: 'tenant.menu.security',
    icon: 'fas fa-lock',
    routerLink: ['/customer/security'],
    permission: ['view_user', 'view_role', 'view_activity_log'],
    items: [
      {
        label: 'tenant.menu.users',
        routerLink: ['/customer/security/users'],
        permission: 'view_user',
      },
      {
        label: 'tenant.menu.user_roles',
        routerLink: ['/customer/security/roles'],
        permission: 'view_role',
      },
      {
        label: 'tenant.menu.activity_history',
        routerLink: ['/customer/security/activity-logs'],
        permission: 'view_activity_log',
      },
    ],
  },
];
