import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PermissionHelperService } from '@app/core/services';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-access-denied',
  templateUrl: './access-denied.component.html',
  styles: [
    `
      .exception-body
        .exception-content
        .exception-panel
        .exception-panel-content {
        width: 60%;
      }
    `,
  ],
})
export class AccessDeniedComponent implements OnInit {
  code = 403;
  title = this.translate.instant('core.access_denied.title');
  message: string = this.translate.instant('core.access_denied.message');
  constructor(
    private permissionHelperService: PermissionHelperService,
    private router: Router,
    private translate: TranslateService
  ) {
    if (
      ![null, undefined].includes(
        this.router.getCurrentNavigation()?.extras?.state?.message
      )
    ) {
      this.message = this.router.getCurrentNavigation().extras?.state?.message;
    }
    if (
      ![null, undefined].includes(
        this.router.getCurrentNavigation()?.extras?.state?.code
      )
    ) {
      this.code = this.router.getCurrentNavigation().extras?.state?.code;
    }
    if (
      ![null, undefined].includes(
        this.router.getCurrentNavigation()?.extras?.state?.title
      )
    ) {
      this.title = this.router.getCurrentNavigation().extras?.state?.title;
    }
  }

  ngOnInit(): void {}
  return() {
    this.permissionHelperService.navigateToHome();
  }
}
