import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-employee-name',
  templateUrl: './employee-name.component.html',
  styles: [],
})
export class EmployeeNameComponent implements OnInit {
  @Input() employee: any = undefined;
  @Input() showAvatar: boolean = true;
  constructor() {}

  ngOnInit(): void {}

  getLabel(): string {
    return [null, undefined].includes(this.employee?.media?.imagenDePerfil)
      ? this.employee.name.substring(0, 1) +
          this.employee.surname.substring(0, 1)
      : null;
  }
}
