import { ServiceDataStorage } from '@shared/helpers';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DeductionsService extends ServiceDataStorage {
  constructor() {
    super('deductions');
  }
}
