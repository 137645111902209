@if (item?.type !== 'log') {
  <div
    class="border-circle border-2 flex align-items-center justify-content-center"
  [ngClass]="{
    'border-yellow-500': item.status === null,
    'border-green-400': item.status === 'approved',
    'border-pink-400': item.status === 'rejected'
  }"
    style="width: 2.4rem; height: 2.4rem"
    pTooltip="{{ item.status | eventStatusValue }}"
    >
    <i
      class="text-base fas"
    [ngClass]="{
      'fa-paper-plane text-yellow-500 ': item.status === null,
      'fa-check text-green-400': item.status === 'approved',
      'fa-times text-pink-400': item.status === 'rejected'
    }"
    ></i>
  </div>
}
