import { HttpService } from '@shared/services';
import { Injectable } from '@angular/core';
import { CumulativeItem } from '@shared/models';

@Injectable({
  providedIn: 'root',
})
export class CumulativeService {
  constructor(private http: HttpService) {}

  updateMassive(items: Array<CumulativeItem>) {
    return this.http
      .put(`payroll-close-details/update/massive`, {
        details: items,
      })
      .toPromise();
  }
}
