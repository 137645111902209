import { Injectable } from '@angular/core';
import { HttpService } from '@app/shared/services';

@Injectable({
  providedIn: 'root',
})
export class PayrollCloseService {
  constructor(private http: HttpService) {}

  calculate(payrollCloseId: number) {
    return this.http.post(`payroll-closes/${payrollCloseId}/calculations`);
  }

  closePayrollClose(payrollCloseId: number, sendEmailVoucher: boolean) {
    return this.http.put(`payroll-closes/${payrollCloseId}/calculations`, {
      status: 'closed',
      sendEmailVoucher,
    });
  }

  getAPayrollClose(payrollCloseId: number) {
    return this.http.get(`payroll-closes/${payrollCloseId}`);
  }

  getPayrollCloses(
    companyId: number,
    start: string,
    end: string,
    status: string,
    payrollId: number = undefined
  ) {
    return this.http.get(
      `companies/${companyId}/payroll-closes?start=${start}&end=${end}&status=${status}${
        payrollId !== undefined ? `&payroll_id=${payrollId}` : ''
      }`
    );
  }

  delete(PayrollCloseId: number) {
    return this.http.delete(`payroll-closes/${PayrollCloseId}`);
  }

  create(payrollId: number, beginAt: string, endAt: string) {
    return this.http.post(`payrolls/${payrollId}/payroll-closes`, {
      beginAt,
      endAt,
    });
  }
  getPayrollClosesStatusClosed(
    companyId: number,
    start: string,
    end: string,
    payrollId: number
  ) {
    return this.http.get(
      `companies/${companyId}/payroll-closes/closed?start=${start}&end=${end}&payroll_id=${payrollId}`
    );
  }
}
