import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-confirm',
  template: `
    <p-confirmDialog [style]="{ width: '30vw' }" #cd>
      <p-footer>
        <button
          type="button"
          pButton
          pRipple
          icon="pi pi-check"
          label="{{ 'shared.confirm.accept' | translate }}"
          class="p-button-text"
          (click)="cd.accept()"
        ></button>
        <button
          type="button"
          pButton
          pRipple
          icon="pi pi-times"
          label="{{ 'shared.confirm.cancel' | translate }}"
          class="p-button-text p-button-secondary"
          (click)="cd.reject()"
        ></button>
      </p-footer>
    </p-confirmDialog>
  `,
  styles: [],
})
export class ConfirmComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
