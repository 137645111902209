<div class="px-5 grid fluid">
  <div class="col-12">
    <div class="field">
      <label class="is-required" for="firstname1">{{
        "shared.changePassword.new_password" | translate
      }}</label>
      <p-password [(ngModel)]="password" [toggleMask]="true"></p-password>
    </div>
    <div class="field">
      <label class="is-required" for="lastname1">{{
        "shared.changePassword.confirm_password" | translate
      }}</label>
      <p-password
        [(ngModel)]="passwordConfirmation"
        [toggleMask]="true"
        [feedback]="false"
      ></p-password>
    </div>
  </div>
</div>
<div class="p-dialog-footer">
  <button
    pButton
    pRipple
    label="{{ 'shared.changePassword.change_password' | translate }}"
    icon="pi pi-check"
    class="p-button-text"
    [loading]="loading$ | async"
    [disabled]="
      passwordConfirmation === '' ||
      passwordConfirmation === undefined ||
      password === '' ||
      password === undefined
    "
    (click)="changePwd()"
    [appEventTracker]="{ action: 'change_password' }"
  ></button>
  <button
    pButton
    pRipple
    label="{{ 'shared.changePassword.cancel' | translate }}"
    icon="pi pi-times"
    class="p-button-text p-button-secondary"
    [disabled]="loading$ | async"
    (click)="cancelBtn()"
  ></button>
</div>
