@if (!isProd) {
  <div class="notification">
    <p-tag
      severity="warning"
      icon="fas fa-vial"
      [value]="'Environment: ' + env | uppercase"
      >
    </p-tag>
  </div>
}
