export class Payroll {
  constructor(
    public id: number,
    public name: string,
    public paymentType: string,
    public paymentPeriod: string,
    public sourceAttendance: string,
    public policyDisabilityDays: number,
    public policyDisabilityPercentToPay: number,
    public policyDisabilityDaysAfterPercentToPay: number,
    public policyVacationByMonth: number,
    public currency: string,
    public salaryAmount: number,
    public estimateRent: boolean,
    public settings: any,
    public countDeductions: number,
    public countEmployees: number,
    public createdAt: string,
    public updatedAt: string
  ) {}
}
