import { AppConfig } from './../domain/appconfig';
export class Profile {
  constructor(
    public id: number = -1,
    public name: string = '',
    public surname: string = '',
    public lastname: string = '',
    public email: string = '',
    public data: { preferences: AppConfig } = { preferences: null },
    public urlProfileImage: string = null,
    public roles: string[] = [],
    public permissions: string[] = []
  ) {}
}
