import { createReducer, on } from '@ngrx/store';
import { AuthState } from './auth.state';
import { AuthActions } from './auth.actions';

export const initialAuthState: AuthState = {
  isLoading: false,
  error: null,
  tenantIdFound: false,
  loginSuccess: false,
  restorePasswordSuccess: false,
};

const reducer = createReducer<AuthState>(
  initialAuthState,
  on(AuthActions.login, (state) => ({
    ...state,
    isLoading: true,
    error: null,
    loginSuccess: false,
  })),
  on(
    AuthActions.loginSuccess,
    (state, { accessToken, refreshToken, profile }) => ({
      ...state,
      isLoading: false,
      accessToken,
      refreshToken,
      profile,
      error: null,
      loginSuccess: true,
    })
  ),
  on(AuthActions.loginError, (state, { payload }) => ({
    ...state,
    isLoading: false,
    error: payload,
    loginSuccess: false,
  })),
  on(AuthActions.logout, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(AuthActions.logoutSuccess, (state) => ({
    ...state,
    isLoading: false,
    error: null,
    tenantIdFound: false,
    loginSuccess: false,
  })),
  on(AuthActions.refreshToken, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(AuthActions.refreshTokenSuccess, (state) => ({
    ...state,
    isLoading: false,
    error: null,
  })),
  on(AuthActions.refreshTokenError, (state, { payload }) => ({
    ...state,
    isLoading: false,
    error: payload,
  })),
  on(AuthActions.findTenant, (state) => ({
    ...state,
    isLoading: true,
    error: null,
    tenantIdFound: false,
  })),
  on(AuthActions.findTenantSuccess, (state) => ({
    ...state,
    isLoading: false,
    error: null,
    tenantIdFound: true,
  })),
  on(AuthActions.findTenantError, (state, { payload }) => ({
    ...state,
    isLoading: false,
    error: payload,
  })),
  on(AuthActions.restorePassword, (state) => ({
    ...state,
    isLoading: true,
    restorePasswordSuccess: false,
  })),
  on(AuthActions.restorePasswordSuccess, (state) => ({
    ...state,
    isLoading: false,
    restorePasswordSuccess: true,
  })),
  on(AuthActions.catchErrorFromApi, (state, { payload }) => ({
    ...state,
    isLoading: false,
    restorePasswordSuccess: false,
    error: payload,
  }))
);

export function AuthReducer(state = initialAuthState, actions): AuthState {
  return reducer(state, actions);
}
