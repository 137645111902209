export class ToastAlert {
  severity: Severity;
  summary: string;
  detail: string;
  position: Position;
}

export enum Severity {
  Success,
  Error,
  Info,
  Warning
}

export enum Position {
  Default,
  TopLeft,
  TopCenter,
  Bottom
}
