import { HttpService } from '@shared/services';
import { BehaviorSubject } from 'rxjs';
import { AppInjector } from '@shared/app-injector.service';
import { ToastService } from '@shared/services';

export class DataStorage {
  public dataSource = new BehaviorSubject<any>([]);
  public loadingSource = new BehaviorSubject<boolean>(false);

  public httpService: HttpService = AppInjector.injector.get(HttpService);
  public toastService: ToastService = AppInjector.injector.get(ToastService);

  public data = this.dataSource.asObservable();
  public loading = this.loadingSource.asObservable();
}
