<div
  class="flex align-items-center"
  [ngClass]="{
    'justify-content-center': isCumulative === false,
    'justify-content-start': isCumulative === true
  }"
>
  <span [@tada]="valueWasChanged" [@.disabled]="valueWasChanged === false">
    {{ itemDetail.attendance[minutesKey] | minutesToHours : "narrow" }}</span
  >
  @if (isEditableType() === true && notAuto > 0 && allowEditData === true) {
  <small class="mx-2 text-color-secondary"
    >{{ "cumulative.editor.pending_without_authorize" | translate }}:
    {{ getPendingToAuthorize() | minutesToHours : "narrow" }}</small
  >
  } @if ( isEditableType() === true && notAuto > 0 && allowEditData === true &&
  payrollCloseStatus === 'open' ) {
  <button
    pButton
    pRipple
    [appDisableIfUnauthorize]="'update_payroll_close'"
    icon="pi pi-pencil"
    class="p-button-rounded p-button-text"
    [disabled]="!allowEditData"
    [loading]="loading"
    pTooltip="{{ 'cumulative.editor.edit_button_tooltip' | translate }}"
    (click)="op.toggle($event); resetAutoValues()"
  ></button>
  } @if (isEditableType() === true && notAuto === 0 && allowEditData === true) {
  <small class="mx-2 text-color-secondary">{{
    "cumulative.editor.not_time_unauthorize" | translate
  }}</small>
  }
</div>

<p-overlayPanel
  #op
  [dismissable]="true"
  [showCloseIcon]="true"
  (onHide)="onHideOverlay()"
>
  <ng-template pTemplate>
    <div class="flex justify-content-between align-items-center w-25rem p-3">
      <span class="font-bold">0h 0m</span>
      <p-slider
        class="w-15rem"
        [min]="0"
        [max]="notAuto"
        [(ngModel)]="auto"
      ></p-slider>
      <span class="font-bold">
        {{ notAuto | minutesToHours : "narrow" }}
      </span>
    </div>
    <div class="flex justify-content-center p-3">
      <span class="font-bold">
        {{ "commons.total_authorized" | translate }}:
        {{ auto | minutesToHours : "narrow" }}
      </span>
    </div>

    <div class="flex align-items-center justify-content-end">
      <button
        pButton
        pRipple
        label="{{ 'commons.save' | translate }}"
        icon="pi pi-check"
        class="p-button-text"
        (click)="updateOvertime(op)"
        [loading]="loading"
      ></button>
    </div>
  </ng-template>
</p-overlayPanel>
