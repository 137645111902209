import { HttpService } from '@shared/services';
import { Injectable } from '@angular/core';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  constructor(private http: HttpService) {}

  openPayrollCloses(companyId: number) {
    return this.http.get(`dashboard/${companyId}/open-payroll-closes`).pipe(
      filter((x) => x.data !== null || x.data !== undefined),
      map((response) => response.data)
    );
  }

  nextEventPersonnelActions() {
    return this.http.get(`dashboard/next-event-personnel-actions`).pipe(
      filter((x) => x.data !== null || x.data !== undefined),
      map((response) => response.data)
    );
  }

  nextHolidays(start: string, end: string) {
    return this.http
      .get(`dashboard/next-holidays?start=${start}&end=${end}`)
      .pipe(
        filter((x) => x.data !== null || x.data !== undefined),
        map((response) => response.data)
      );
  }
}
