import {
  AfterContentInit,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ConfirmationService, PrimeTemplate } from 'primeng/api';
import { Subscription } from 'rxjs';
import { ServiceDataStorage } from '@shared/helpers';
import { DynamicFormComponent } from '@shared/components';
import { ObjectRepository } from '@shared/repositories';

@Component({
  selector: 'app-data-grid',
  templateUrl: './data-grid.component.html',
  styleUrls: ['./data-grid.component.scss'],
})
export class DataGridComponent implements OnInit, OnDestroy, AfterContentInit {
  itemsParent: Array<any>;
  selectedParent: any;

  isNewItem = false;
  selectedItems: any;
  _config: any;
  dataSource: Array<any>;
  itemDialog = false;
  columns: Array<any> = [];

  sdsMain: ServiceDataStorage = new ServiceDataStorage();
  sdsParent: ServiceDataStorage = new ServiceDataStorage();
  sdsSubscription: Subscription = new Subscription();

  @Output() afterSave = new EventEmitter();
  @Output() beforeSave = new EventEmitter();
  @Output() beforeDelete = new EventEmitter();
  @Output() createItem = new EventEmitter();
  @Output() selectionChanged = new EventEmitter();
  @Output() dialogOpened = new EventEmitter();
  @Output() saveClicked = new EventEmitter();

  @ViewChild('dynamicfrm') dynamicfrm: DynamicFormComponent;

  itemTemplate: TemplateRef<any>;
  afterActionsRow: TemplateRef<any>;
  dialogAfterTemplate: TemplateRef<any>;
  @ContentChildren(PrimeTemplate) templates: QueryList<any>;

  @Input() labelCreate = 'Crear';

  /**
   * loader of de p-table
   */
  @Input() loading = false;
  /**
   * config of control
   */
  @Input()
  set config(value: any) {
    // avoid empty data
    if (value === undefined || value === null) {
      return;
    }
    // get config
    this._config = value;
    // define what is the dataSource Array or SDS
    this.setDataSource();
    // define fields to show in grid
    this.columns = this.getColumnsToShow();
  }
  get config() {
    return this._config;
  }

  _idForm = '';
  /**
   * id of form
   */
  @Input()
  get idForm() {
    return this._idForm;
  }
  set idForm(value: string) {
    this._idForm = value;
  }

  get items() {
    if (this.config?.dataSource?.items) {
      return this.config.dataSource.items;
    }
    return [];
  }

  set items(value: Array<any>) {
    if (this.config.dataSource?.items === undefined) {
      this.config.dataSource = {};
    }
    this.config.dataSource.items = value;
  }

  /**
   * get files to upload
   */
  get files(): Array<{ label: string; value: any }> {
    return this.dynamicfrm.files;
  }

  @Input() hideToolbar: boolean = false;

  @Input() item: any;

  @Input() manualSave: boolean = false;

  @Input() createPermissionName: string = undefined;
  @Input() updatePermissionName: string = undefined;
  @Input() deletePermissionName: string = undefined;

  constructor(private confirmationService: ConfirmationService) {}

  ngAfterContentInit(): void {
    this.templates.forEach((item) => {
      switch (item.getType()) {
        case 'item':
          this.itemTemplate = item.template;
          break;
        case 'afterActionsRow':
          this.afterActionsRow = item.template;
          break;
        case 'dialogAfterTemplate':
          this.dialogAfterTemplate = item.template;
          break;

        default:
          this.itemTemplate = item.template;
          break;
      }
    });
  }

  ngOnDestroy(): void {
    this.sdsSubscription.unsubscribe();
  }

  ngOnInit(): void {}
  /**
   * get column to apply global
   */
  getGlobalFilterFields() {
    if (this.config === undefined) {
      return [];
    }
    return this.config.fields
      .filter((x) => x.isFilterGlobal === true)
      .map((y) => y.field);
  }

  getColumnsToShow() {
    if (this.config === undefined) {
      return [];
    }
    return this.config.fields
      .filter((x) => x.hideOnGrid === undefined || x.hideOnGrid === false)
      .map((y) => {
        return y;
      });
  }

  private saveItem() {
    this.loading = true;
    this.dynamicfrm.copyOnItem();
    this.saveClicked.emit({ item: this.item });
    if (!this.manualSave) {
      this.executeSaveStorage();
    }
  }
  /**
   * internal execution of save in storage (array | backend)
   */
  executeSaveStorage() {
    // save by type of source
    if (this.config.dataSource?.type === 'array') {
      if (this.isNewItem) {
        // before save
        this.beforeSave.emit({ isNewItem: this.isNewItem, item: this.item });
        // new item
        this.items.push({ ...this.item });
        // after save
        this.afterSave.emit({ isNewItem: this.isNewItem, item: this.item });
      } else {
        // before save
        this.beforeSave.emit({ isNewItem: this.isNewItem, item: this.item });
        // after save
        this.afterSave.emit({ isNewItem: this.isNewItem, item: this.item });
      }
      this.hideDialog();
    } else {
      // before save
      this.beforeSave.emit({ isNewItem: this.isNewItem, item: this.item });
      // exec save SDS
      this.sdsMain
        .save({
          data: this.item,
          ids: this.selectedParent?.id ? [this.selectedParent.id] : [],
        })
        .then(() => {
          // after save
          this.afterSave.emit({ isNewItem: this.isNewItem, item: this.item });
          this.hideDialog();
        })
        .catch(() => {});
    }
  }

  openNew() {
    this.isNewItem = true;
    this.item = {};
    this.createItem.emit(this.item);
    this.itemDialog = true;
    this.dialogOpened.emit(this.item);
  }

  editItem(item) {
    this.isNewItem = false;
    this.item = item;
    this.itemDialog = true;
    this.dialogOpened.emit(this.item);
  }

  deleteItem(item) {
    this.confirmationService.confirm({
      message: '¿Deseas borrar el registro seleccionado?',
      header: 'Confirmar',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        if (this.config.dataSource?.type === 'array') {
          // delete from dataSource
          this.items.splice(this.items.indexOf(item), 1);
          // before delete
          this.beforeDelete.emit({ item: this.item });
        } else {
          // delete from SDS
          this.sdsMain
            .destroy({
              id: item.id,
              ids: this.selectedParent?.id ? [this.selectedParent.id] : null,
            })
            .finally(() => {
              // before delete
              this.beforeDelete.emit({ item: this.item });
            });
        }
      },
    });
  }

  deleteSelectedProducts() {
    this.confirmationService.confirm({
      message: '¿Deseas borrar todos los registros seleccionados?',
      header: 'Confirmar',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        // massive delete
      },
    });
  }

  hideDialog() {
    this.itemDialog = false;
  }

  onChangeParent(event) {
    this.sdsMain
      .refresh({ force: true, ids: this.selectedParent.id })
      .catch(() => {});
  }

  setDataSource() {
    // set models on SDS
    if (this.config.idsModels !== undefined) {
      this.sdsMain.idsModels = this.config.idsModels;
    }

    // set models
    if (this.config.parent?.hasParent === true) {
      this.initSds();
      // set the parent SDS
      this.sdsParent.setModels(this.config.parent.model);
      this.sdsParent.refresh({ force: true }).catch(() => {});
      // set the child SDS
      this.sdsMain.setModelsArray(this._config.models);
    } else if (this.config.models !== undefined) {
      this.initSds();
      this.sdsMain.mapData = this._config.mapChildData;
      this.sdsMain.setModelsArray(this._config.models);
      this.sdsMain.refresh({ force: true }).catch(() => {});
    }

    // set array references items
    if (this.config?.dataSource.type === 'array') {
      this.items = this.config.dataSource.items;
    }
  }

  initSds() {
    // subscription to SDS
    this.sdsSubscription
      .add(
        this.sdsMain.data.subscribe((data) => {
          this.items = data;
        })
      )
      .add(
        this.sdsParent.data.subscribe((data) => {
          this.itemsParent = data;
          // if we have a parent select the first
          if (this.itemsParent.length > 0) {
            this.selectedParent = this.itemsParent[0];

            // get child by the first parent
            this.sdsMain
              .refresh({ force: true, ids: this.selectedParent.id })
              .catch(() => {});
          }
        })
      )
      .add(
        this.sdsMain.loading.subscribe((loading) => (this.loading = loading))
      );
  }
  deleteSelectedItems() {}

  onRowSelectChanged(e) {
    this.selectionChanged.emit({
      originalEvent: e.originalEvent,
      data: e.data,
      type: e.type,
      index: e.index,
    });
  }
  onRowUnSelectChanged(e) {
    this.selectionChanged.emit({
      originalEvent: e.originalEvent,
      data: null,
      type: e.type,
      index: e.index,
    });
  }
  /**
   * helper to show value when is inside a path
   * @param item item to show
   * @param column column to get
   * @returns string | any | null
   */
  displayValue(item, column) {
    return ObjectRepository.getValueByPath(item, column);
  }
}
