import { map } from 'rxjs/operators';
import { HttpService } from '@shared/services';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class RealTimeLogsService {
  constructor(private http: HttpService) {}

  getEmployees() {
    return this.http
      .get('real-time-logs/employees')
      .pipe(map((response) => response.data))
      .toPromise();
  }

  getLogs() {
    return this.http
      .get('real-time-logs/logs')
      .pipe(map((response) => response.data))
      .toPromise();
  }
}
