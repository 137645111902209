import { Pipe, PipeTransform } from '@angular/core';
import { DaysOut } from '@app/tenant/data';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'scheduleValue',
})
export class ScheduleValuePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}
  transform(value: unknown, ...args: unknown[]): unknown {
    // if (!value) {
    //   return;
    // }
    if (!args) {
      return;
    }
    switch (args[0]) {
      case 'typeShift':
        return this.translateService.instant('tenant.schedulesTypes.' + value); //TypesShift.find((x) => x.value === value).label;
      case 'daysOut':
        return this.translateService.instant(
          DaysOut.find((x) => x.value === value).label
        );
      default:
        break;
    }
    return 'unknowed value';
  }
}
