import { Pipe, PipeTransform } from '@angular/core';
import { Currencies } from '@tenant/data';
import {
  PaymentTypes,
  SourcesAttendance,
  PaymentPeriods,
  PayrollCloseStatus,
  DaysOfWeek,
} from '@shared/data';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'payrollValue',
})
export class PayrollValuePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}
  transform(value: unknown, ...args: unknown[]): unknown {
    if (!value) {
      return;
    }
    if (!args) {
      return;
    }
    switch (args[0]) {
      case 'payment':
        return this.translateService.instant(
          PaymentTypes.find((x) => x.value === value).label
        );
      case 'currency':
        return this.translateService.instant(
          Currencies.find((x) => x.value === value).label
        );
      case 'source-attendance':
        return this.translateService.instant(
          SourcesAttendance.find((x) => x.value === value).label
        );
      case 'periods':
        return this.translateService.instant(
          PaymentPeriods.find((x) => x.value === value).label
        );
      case 'payroll-close':
        return this.translateService.instant(
          PayrollCloseStatus.find((x) => x.value === value).label
        );
      case 'daysOfWeek':
        return this.translateService.instant(
          DaysOfWeek.find((x) => x.value === value).label
        );
      default:
        break;
    }
    return 'unknowed value';
  }
}
