export const fieldTypes = [
  {
    label: 'shared.typeFields.text',
    value: 'text',
    validators: ['sometimes', 'nullable', 'max:191'],
  },
  {
    label: 'shared.typeFields.textarea',
    value: 'textarea',
    validators: ['sometimes', 'nullable', 'max:191'],
  },
  {
    label: 'shared.typeFields.number',
    value: 'number',
    validators: ['sometimes', 'numeric'],
  },
  {
    label: 'shared.typeFields.date',
    value: 'date',
    validators: ['sometimes', 'date'],
  },
  {
    label: 'shared.typeFields.datetime',
    value: 'datetime',
    validators: ['sometimes', 'date'],
  },
  {
    label: 'shared.typeFields.time',
    value: 'time',
    validators: ['sometimes', 'datetime'],
  },
  {
    label: 'shared.typeFields.dateyearmonth',
    value: 'dateyearmonth',
    validators: ['sometimes', 'date'],
  },
  {
    label: 'shared.typeFields.dropdown',
    value: 'dropdown',
    validators: ['sometimes', 'nullable', 'max:191'],
  },
  {
    label: 'shared.typeFields.checkbox',
    value: 'checkbox',
    validators: ['sometimes', 'boolean'],
  },
  {
    label: 'shared.typeFields.image',
    value: 'image',
    validators: ['sometimes', 'image', 'nullable'],
  },
];
