import { Pipe, PipeTransform } from '@angular/core';
import { FormatType, TimeCalculationRepository } from '@shared/repositories';

@Pipe({
  name: 'minutesToHours',
})
export class MinutesToHoursPipe implements PipeTransform {
  transform(value: number, ...args: string[]): unknown {
    const format: FormatType =
      args[0] === undefined ? FormatType.colon : (args[0] as FormatType);
    return TimeCalculationRepository.minutesToHours(value, format);
  }
}
