import { ServiceDataStorage } from '@shared/helpers';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EditorManualItemService extends ServiceDataStorage {
  private payrollCloseUpdatedSubject = new BehaviorSubject<{
    id: number;
  }>(undefined);
  /**
   * Emmit events in object
   */
  public payrollCloseUpdates = this.payrollCloseUpdatedSubject.asObservable();
  constructor() {
    super('payroll-closes', 'employees', 'manual-items');
  }

  payrollCloseWasUpdated(id: number) {
    this.payrollCloseUpdatedSubject.next({ id: id });
  }
}
