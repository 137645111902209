import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-document-viewer',
  templateUrl: './document-viewer.component.html',
  styles: [],
})
export class DocumentViewerComponent implements OnInit {
  @Input() url: string = '';
  @Input() mimeType: string = '';

  documentViewer: any = {
    // Media
    image: 'url',
    audio: 'url',
    video: 'url',
    // Documents
    'application/pdf': 'google',
    'application/msword': 'google',
    'application/vnd.ms-word': 'google',
    'application/vnd.oasis.opendocument.text': 'google',
    'application/vnd.openxmlformats-officedocument.wordprocessingml': 'google',
    'application/vnd.ms-excel': 'google',
    'application/vnd.openxmlformats-officedocument.spreadsheetml': 'google',
    'application/vnd.oasis.opendocument.spreadsheet': 'google',
    'application/vnd.ms-powerpoint': 'google',
    'application/vnd.openxmlformats-officedocument.presentationml': 'google',
    'application/vnd.oasis.opendocument.presentation': 'google',
    'text/plain': 'google',
    'text/html': 'google',
    'application/json': 'google',
    // Archives
    'application/gzip': 'google',
    'application/zip': 'google',
  };
  constructor() {}

  ngOnInit(): void {}

  getViewer(): string {
    const candidate: any = Object.entries(this.documentViewer).find(([k]) =>
      this.mimeType.startsWith(k)
    );
    if (candidate === undefined) {
      return 'url';
    } else {
      return candidate[1];
    }
  }
}
