import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  FormBuilder,
  UntypedFormGroup,
  FormArray,
  Validators,
  UntypedFormControl,
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { ObjectRepository } from '@shared/repositories';
import { DynamicFormService } from './dynamic-form.service';
import * as lodash from 'lodash';
import * as moment from 'moment';
import { Screen } from '@shared/helpers';

@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
})
export class DynamicFormComponent implements OnInit, OnDestroy {
  _fields: Array<any>;
  _item: any;
  dynamicForm: UntypedFormGroup;
  _subscriptor: Subscription = new Subscription();
  groups: any;
  _valid = false;
  _idForm = '';
  currencies: any = {};
  yearRange = '1950:' + moment().year().toString();

  /**
   * id of form
   */
  @Input()
  get idForm() {
    return this._idForm;
  }
  set idForm(value: string) {
    this._idForm = value;
  }

  /**
   * fields to create the control
   */
  @Input()
  get fields() {
    return this._fields;
  }
  set fields(fields: Array<any>) {
    // avoid empty data
    if (fields === undefined || fields.length === 0) {
      return;
    }
    this._fields = fields;

    // group & sort by group
    this.groups = lodash(this.getFieldsToEdit())
      .groupBy((item) => item.groupName)
      .orderBy((item) => item[0].groupOrder)
      .value();

    this.buildDynamicForm();
  }
  /**
   * True if the control has passed all of its validation tests, false otherwise.
   */
  @Input()
  get valid() {
    return this._valid;
  }
  /**
   * Item to set data
   */
  @Input()
  get item() {
    return this._item;
  }
  set item(value: any) {
    this._item = value;
  }

  files: Array<{ label: string; value: any }> = [];

  @Output() requestDeleteImage = new EventEmitter();

  constructor(public dynamicFormService: DynamicFormService) {
    // fill empty dynamic form
    this.dynamicForm = new UntypedFormGroup({});
    // TO avoid ExpressionChangedAfterItHasBeenCheckedError
    this.dynamicForm.statusChanges.subscribe((form) => {
      // avoid emmit many changes, only emmit true changes
      if (this._valid !== this.dynamicForm.valid) {
        setTimeout(() => {
          this._valid = this.dynamicForm.valid;
        }, 100);
      }
    });
  }

  ngOnInit(): void {
    // https://angular.io/errors/NG0100
    Promise.resolve().then(() => this.copyOnForm());
  }

  ngOnDestroy() {
    this._subscriptor.unsubscribe();
  }

  /**
   * build a dynamic form from fields
   */
  buildDynamicForm() {
    this.getFieldsToEdit().forEach((field) => {
      const validators: Array<any> = [];
      // find the validatos rules
      if (field.validators) {
        if (field.validators.required === true) {
          validators.push(Validators.required);
        }
        if (field.validators.minLength) {
          validators.push(Validators.minLength(field.validators.minLength));
        }
        if (field.validators.maxLength) {
          validators.push(Validators.maxLength(field.validators.maxLength));
        }
        if (field.validators.email === true) {
          validators.push(Validators.email);
        }
      }
      // get data for automplete input if not have data
      if (
        field.type === 'autocomplete' &&
        !this.dynamicFormService.hasAutoCompletData(field.field, this.idForm)
      ) {
        this.dynamicFormService.createDataDependenciesAutocomplet(
          field,
          this.idForm
        );
      }
      // get data for dropdown input if not have data
      if (
        field.type === 'dropdown' &&
        field.dropdown.type !== 'array' &&
        !this.dynamicFormService.hasDropdownData(field.field, this.idForm)
      ) {
        this.dynamicFormService.createDataDependenciesDropdown(
          field,
          this.idForm
        );
      }
      if (field.type === 'currency') {
        this.currencies[field.number.binding] = 'USD';
      }

      // add new control
      this.dynamicForm.addControl(
        field.field,
        new UntypedFormControl({ value: '', disabled: field.readonly }, validators)
      );
      // before add the controls subcribe to value changes currencies
      if (field.type === 'currency') {
        this.dynamicForm.controls[field.number.binding].valueChanges.subscribe(
          (value) => {
            this.currencies[field.number.binding] = value;
          }
        );
      }
    });
  }
  /**
   * get fields available to edit in form
   */
  getFieldsToEdit() {
    return this.fields.filter((x) => x.isEditable === true);
  }

  countGroups(): number {
    return Object.keys(this.groups).length;
  }
  /**
   * copy data of item on Form
   */
  copyOnForm() {
    this.getFieldsToEdit().forEach((x) => {
      switch (x.type) {
        case 'date':
        case 'datetime':
        case 'time':
        case 'dateyearmonth':
          const valueDate = ObjectRepository.getValueByPath(this.item, x.field);

          if (valueDate !== null && valueDate !== undefined) {
            // transform to date object
            this.dynamicForm.controls[x.field].setValue(
              moment(valueDate).toDate()
            );
          }
          break;
        case 'dropdown':
          let valuev = ObjectRepository.getValueByPath(this.item, x.field);

          // if is array | object, only support the first value, dropdown only support an item
          if (Array.isArray(valuev)) {
            valuev = valuev[0];
            if (typeof valuev === 'object') {
              valuev =
                valuev[x.dropdown?.valueMap ? x.dropdown?.valueMap : 'value'];
            }
          }
          // validate if has value
          if (valuev === null || valuev === undefined) {
            // for dropdown array avoid empty value
            if (
              x.dropdown.items !== undefined &&
              (x.dropdown.items as Array<any>).length > 0
            ) {
              this.dynamicForm.controls[x.field].setValue(
                x.dropdown.items[0].value
              );
            }
          } else {
            // value exists
            this.dynamicForm.controls[x.field].setValue(valuev);
          }
          break;
        case 'checkbox':
          let value = ObjectRepository.getValueByPath(this.item, x.field);
          // avoid empty value
          this.dynamicForm.controls[x.field].setValue(
            value === undefined || value === null ? false : value
          );
          break;
        default:
          // set value
          this.dynamicForm.controls[x.field].setValue(
            ObjectRepository.getValueByPath(this.item, x.field)
          );
          break;
      }
    });
  }
  /**
   * copy values of Form on Item
   */
  copyOnItem() {
    this.getFieldsToEdit().forEach((x) => {
      let field: string = this.getOnlyFieldName(x.field);
      let value = this.dynamicForm.controls[x.field].value;
      switch (x.type) {
        case 'date':
        case 'datetime':
        case 'time':
        case 'dateyearmonth':
          // for date the value is empty, remove from object to avoid 422
          if ([null, undefined, ''].includes(value)) {
            delete this.item[field];
          } else {
            value = moment(value).format('YYYY-MM-DD HH:mm'); // to avoid timezone problems
            ObjectRepository.setValueByPath(this.item, field, value);
          }
          break;
        default:
          if ([null, undefined, ''].includes(value)) {
            delete this.item[field];
          } else {
            ObjectRepository.setValueByPath(this.item, field, value);
          }
          break;
      }
    });
  }

  uploadMedia(e, field) {
    let key = this.getOnlyFieldName(field.field);
    if (this.files.findIndex((x) => x.label === key) === -1) {
      // add file to local array to later upload
      this.files.push({ label: key, value: e.files[0] });
    }
  }

  getImageUrl(fieldProperty: string): string | null {
    if ([null, undefined].includes(this.item?.media)) {
      return null;
    }
    return this.item.media[this.getOnlyFieldName(fieldProperty)];
  }

  existsImageToShow(fieldProperty: string) {
    return ![null, undefined].includes(this.getImageUrl(fieldProperty));
  }

  onDeleteImage(item) {
    let field: string = this.getOnlyFieldName(item.field);
    delete this.item.media[field];
    this.requestDeleteImage.next(field);
  }
  /**
   * helper to get image name
   * @param property name of property
   * @returns name of field image
   */
  private getOnlyFieldName(property: string): string {
    if (property.indexOf('.')) {
      return property.split('.').reverse()[0];
    }
    return property;
  }
  useTouchUI(): boolean {
    return Screen.useTouchUICalendar();
  }

  getFilesByLabel(label: string) {
    return this.files.filter((x) => x.label === this.getOnlyFieldName(label));
  }
}
