<div class="layout-wrapper" (click)="onLayoutClick()"
     [ngClass]="{'layout-horizontal': app.horizontalMenu,
                'layout-wrapper-static': staticMenuActive,
                'layout-rightpanel-active': rightPanelActive,
                'layout-topbar-mobile-active': topbarMobileMenuActive,
                'layout-megamenu-mobile-active': megaMenuMobileActive,
                'layout-sidebar-mobile-active': menuMobileActive,
                'p-input-filled': app.inputStyle === 'filled',
                'p-ripple-disabled': !app.ripple}" [class]="app.menuColor">

  <app-menu></app-menu>

  <div class="layout-main">
    <app-topbar></app-topbar>

    <app-rightpanel></app-rightpanel>

    <app-breadcrumb></app-breadcrumb>

    <div class="layout-content">
      <router-outlet></router-outlet>
    </div>

    <app-footer></app-footer>

    <app-config></app-config>

    <div class="layout-main-mask"></div>

    @if (configActive) {
      <div class="layout-config-mask" [@mask-anim]="'visible'"></div>
    }
  </div>
</div>
