import { Component, Input, OnInit } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';
import { ToastService } from '@app/shared/services';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService } from 'primeng/api';
import { ExtraMonthDetailService } from './extra-month-detail.service';

@Component({
  selector: 'app-extra-month-employee-detail',
  templateUrl: './extra-month-employee-detail.component.html',
  styles: [],
})
export class ExtraMonthEmployeeDetailComponent implements OnInit {
  @Input() item: any = undefined;
  @Input() payroll: any = undefined;
  @Input() editable: boolean = true;

  loading: boolean = false;
  additionalsBackup: Array<any> = [];

  get additionals(): Array<any> {
    return this.item.additionals;
  }
  set additionals(value: any) {
    this.item.additionals = value;
  }

  constructor(
    private toastService: ToastService,
    private confirmationService: ConfirmationService,
    private translateService: TranslateService,
    private extraMonthDetailService: ExtraMonthDetailService
  ) {}

  ngOnInit(): void {}

  deleteItem(item: any) {
    this.confirmationService.confirm({
      message: this.translateService.instant(
        'shared.extraMonthEmployeeDetail.confirmDeleteMessage',
        { value: item.description }
      ),
      header: this.translateService.instant(
        'shared.extraMonthEmployeeDetail.confirmDeleteHeader'
      ),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.loading = true;
        this.extraMonthDetailService
          .delete(this.item.id, item.id)
          .then(() => {
            this.additionals.splice(
              this.additionals.findIndex((x) => x.id === item.id),
              1
            );
            this.item.isDirty = true;
            this.refreshExtraMonthDetail();
          })
          .finally(() => (this.loading = false))
          .catch((error) =>
            this.toastService.showHttpErrorResponse(error, true)
          );
      },
    });
  }

  createItem(dt: any) {
    const additional = {
      description: '',
      amount: 0,
    };
    this.additionals.push(additional);
    dt.initRowEdit(this.additionals[this.additionals.length - 1]);
  }

  onRowEditInit(item: any) {
    const index = this.additionalsBackup.findIndex((x) => x.uuid === item.uuid);
    if (index === -1) {
      item.uuid = uuidv4();
      this.additionalsBackup.push({ ...item });
    } else {
      this.additionalsBackup[index] = { ...item };
    }
  }
  onRowEditSave(item: any, index: number, dt: any) {
    item.loading = true;
    this.extraMonthDetailService
      .save(this.item.id, item)
      .then((data) => {
        this.additionals[index] = data;
        this.item.isDirty = true;
        this.refreshExtraMonthDetail();
      })
      .finally(() => (item.loading = false))
      .catch((error) => {
        this.toastService.showHttpErrorResponse(error, true);
        dt.initRowEdit(index);
      });
  }
  onRowEditCancel(item: any, index: number) {
    if (item?.id === undefined) {
      this.additionals.splice(index, 1);
    } else {
      // restore
      this.additionals[index] = this.additionalsBackup.find(
        (x) => x.uuid === item.uuid
      );
    }
  }

  refreshExtraMonthDetail() {
    this.extraMonthDetailService.show(this.item.id).then((data) => {
      this.item.totalAmount = data.totalAmount;
    });
  }
}
