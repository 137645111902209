import { HttpService } from '@shared/services';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PayrollSalaryService {
  constructor(private http: HttpService) {}
  getPayrollSalaries(payrollId: number) {
    return this.http
      .get(`payrolls/${payrollId}/salaries`)
      .pipe(map((res) => res.data))
      .toPromise();
  }
  save(payrollId: number, data: any) {
    if (data.id !== null) {
      return this.update(payrollId, data);
    } else {
      return this.create(payrollId, data);
    }
  }
  create(payrollId: number, data: any) {
    return this.http
      .post(`payrolls/${payrollId}/salaries`, data)
      .pipe(map((res) => res.data))
      .toPromise();
  }
  update(payrollId: number, data: any) {
    return this.http
      .put(`payrolls/${payrollId}/salaries/${data.id}`, data)
      .pipe(map((res) => res.data))
      .toPromise();
  }
  delete(payrollId: number, id: number) {
    return this.http
      .delete(`payrolls/${payrollId}/salaries/${id}`)
      .pipe(map((res) => res.data))
      .toPromise();
  }
}
