import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AuthState } from './auth.state';

export const selectAuthState = createFeatureSelector<AuthState>('auth');

export const selectAuthIsLoading = createSelector(
  selectAuthState,
  (state) => state.isLoading
);

export const selectAuthError = createSelector(
  selectAuthState,
  (state) => state.error
);

export const selectTenantIdFound = createSelector(
  selectAuthState,
  (state) => state.tenantIdFound
);
export const selectLogginSuccess = createSelector(
  selectAuthState,
  (state) => state.loginSuccess
);
export const selectRestorePasswordSuccess = createSelector(
  selectAuthState,
  (state) => state.restorePasswordSuccess
);
