import { ServiceDataStorage } from '@shared/helpers';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PayrollDeductionSyncService extends ServiceDataStorage {
  constructor() {
    super('payrolls', 'deductions-sync');
  }
  attach(payrollId: number, ids: Array<number>): Promise<any> {
    return this.customRequest({
      method: 'post',
      fullPath: `payrolls/${payrollId}/deductions-sync`,
      data: { deductionsIds: ids },
      updateSource: false,
      useLoader: false,
    });
  }
  detach(payrollId: number, ids: Array<number>): Promise<any> {
    return this.customRequest({
      method: 'post',
      fullPath: `payrolls/${payrollId}/deductions-detach`,
      data: { deductionsIds: ids },
      updateSource: false,
      useLoader: false,
    });
  }
}
