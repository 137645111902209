import { Directive, ElementRef, Input } from '@angular/core';
import { PermissionHelperService } from '@core/services';

@Directive({
  selector: '[appHideIfUnauthorize]',
})
export class HideIfUnauthorizeDirective {
  @Input('appHideIfUnauthorize') permission: string | string[]; // Required permission passed in

  constructor(
    private el: ElementRef,
    private permissionHelperService: PermissionHelperService
  ) {}

  ngOnInit() {
    if (!this.permissionHelperService.hasPermission(this.permission)) {
      this.el.nativeElement.style.display = 'none';
    }
  }
}
