import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  FormBuilder,
  UntypedFormGroup,
  FormArray,
  Validators,
  UntypedFormControl,
} from '@angular/forms';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { Subscription } from 'rxjs';
import {
  SessionActions,
  SessionState,
  selectProfile,
  selectSessionIsLoading,
} from '@app/store/session';
@Component({
  selector: 'app-frm-anonymous-user',
  templateUrl: './frm-anonymous-user.component.html',
  styles: [],
})
export class FrmAnonymousUserComponent implements OnInit, OnDestroy {
  frm: UntypedFormGroup;
  loading$ = this.store.select(selectSessionIsLoading);
  subscription: Subscription = new Subscription();
  profile: any;

  constructor(
    public ref: DynamicDialogRef,
    private store: Store<SessionState>
  ) {
    this.frm = this.getFormGroup();
  }

  ngOnInit(): void {
    this.subscription.add(
      this.store.select(selectProfile).subscribe((profile) => {
        this.profile = profile;
        if (profile.id !== -1 && profile.name !== '' && profile.surname !== '')
          this.ref.close();
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  getFormGroup(): UntypedFormGroup {
    return new UntypedFormGroup({
      name: new UntypedFormControl('', Validators.required),
      surname: new UntypedFormControl('', Validators.required),
      lastname: new UntypedFormControl('', Validators.required),
    });
  }
  save() {
    this.store.dispatch(
      SessionActions.updateUserIdentity(
        Object.assign({ id: this.profile.id }, this.frm.getRawValue())
      )
    );
  }
}
