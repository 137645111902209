import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const AuthActions = createActionGroup({
  source: 'Auth',
  events: {
    Login: props<{ username: string; password: string; tenant: boolean }>(),
    'Login Success': props<{
      accessToken: string;
      refreshToken: string;
      profile: any;
    }>(),
    'Login Error': props<{ payload: any }>(),
    'Login Success Ok': emptyProps(),
    Logout: emptyProps(),
    'Logout Success': emptyProps(),
    'Refresh Token': emptyProps(),
    'Refresh Token Success': emptyProps(),
    'Refresh Token Error': props<{ payload: any }>(),
    'Find Tenant': props<{ customerId: string }>(),
    'Find Tenant Success': props<{ customerId: string }>(),
    'Find Tenant Error': props<{ payload: any }>(),
    'Restore password': props<{
      email: string;
      password: string;
      passwordConfirmation: string;
      token: string;
    }>(),
    'Restore password success': props<{ customerId: string }>(),
    'Catch error from api': props<{ payload: any }>(),
  },
});
