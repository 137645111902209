import { HttpService } from '@shared/services';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import camelCase from 'camelcase';

@Injectable({
  providedIn: 'root',
})
export class EmployeeFieldsService {
  constructor(private http: HttpService) {}
  /**
   * get required & custom fields
   */
  getFields() {
    return this.http.get('additional-fields?type=employees').pipe(
      map((response) => {
        let items: Array<any> = this.normalFields();
        if (response.data) {
          items = [
            ...items,
            ...response.data.map((x) => this.transformToField(x)),
          ];
        }
        return items;
      })
    );
  }

  private isRequired(additionalField: any) {
    if (additionalField.type === 'image') {
      return false;
    }
    if ([null, undefined].includes(additionalField.validators)) {
      return false; // TODO: review why the validators are null
    }
    return additionalField.validators.includes('required');
  }

  private normalFields() {
    return [
      {
        field: 'name',
        label: 'Nombre',
        isFilterGlobal: true,
        autofocus: true,
        isSortable: false,
        isEditable: true,
        placeholder: 'Nombre',
        validators: { required: true, maxLength: 191, minLength: 3 },
        type: 'text',
        groupName: '',
      },
      {
        field: 'surname',
        label: 'Primer apellido',
        isFilterGlobal: true,
        isSortable: false,
        isEditable: true,
        placeholder: 'Primer apellido',
        validators: { required: true, maxLength: 191, minLength: 3 },
        type: 'text',
        groupName: '',
      },
      {
        field: 'lastname',
        label: 'Segundo apellido',
        isFilterGlobal: true,
        isSortable: false,
        isEditable: true,
        placeholder: 'Segundo apellido',
        validators: { required: true, maxLength: 191, minLength: 3 },
        type: 'text',
        groupName: '',
      },
      {
        field: 'code',
        label: 'Identificación',
        isFilterGlobal: true,
        isSortable: false,
        isEditable: true,
        placeholder: 'Identificación',
        validators: { required: true, maxLength: 15, minLength: 5 },
        type: 'text',
        groupName: '',
      },
      {
        field: 'machineId',
        label: 'ID Asistencia',
        isSortable: false,
        isEditable: true,
        placeholder: 'ID reloj marcador. Ej: 1',
        validators: { required: true, maxLength: 9 },
        number: { min: 1, max: 999999999, useGrouping: false },
        type: 'number',
        groupName: '',
      },
      {
        field: 'email',
        label: 'Correo eléctronico',
        isSortable: false,
        isEditable: true,
        placeholder: 'Correo electrónico',
        validators: { required: false, maxLength: 191, minLength: 5 },
        type: 'text',
        groupName: '',
      },
      {
        field: 'admissionAt',
        label: 'Fecha de ingreso',
        isSortable: false,
        isEditable: true,
        validators: { required: false },
        type: 'dateyearmonth',
        groupName: 'Datos generales',
      },
    ];
  }

  private transformToField(item: any) {
    let field = Object.assign(item, {
      field: `data.${camelCase(item.name, {
        preserveConsecutiveUppercase: false,
      })}`,
      label: item.name,
      isEditable: true,
      validators: {
        required: this.isRequired(item),
        maxLength: 191,
        minLength: 3,
      },
    });

    // items is a source of array
    if (item.type === 'dropdown') {
      field.dropdown = {
        items: item.source.map((x) => {
          return { label: x, value: x };
        }),
        type: 'array',
      };
    }
    return field;
  }
}
