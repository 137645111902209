export const PaymentPeriods = [
  { label: 'shared.payrollTypes.paymentPeriods.weekly', value: 'weekly' },
  {
    label: 'shared.payrollTypes.paymentPeriods.fortnightly',
    value: 'fortnightly',
  },
  { label: 'shared.payrollTypes.paymentPeriods.monthly', value: 'monthly' },
];
export const PaymentTypes = [
  { label: 'shared.payrollTypes.paymentTypes.monthly', value: 'monthly' },
  { label: 'shared.payrollTypes.paymentTypes.hourly', value: 'hourly' },
];
export const SourcesAttendance = [
  {
    label: 'shared.payrollTypes.sourcesAttendance.noRequired',
    value: 'no_required',
  },
  {
    label: 'shared.payrollTypes.sourcesAttendance.byEmployee',
    value: 'by_employee',
  },
];

export const PayrollCloseStatus = [
  { label: 'shared.payrollTypes.payrollCloseStatus.open', value: 'open' },
  { label: 'shared.payrollTypes.payrollCloseStatus.closed', value: 'closed' },
  // { label: 'shared.payrollTypes.payrollCloseStatus.canceled', value: 'canceled' },
];

export const DaysOfWeek = [
  { label: 'shared.payrollTypes.daysOfWeek.monday', value: 1 },
  { label: 'shared.payrollTypes.daysOfWeek.tuesday', value: 2 },
  { label: 'shared.payrollTypes.daysOfWeek.wednesday', value: 3 },
  { label: 'shared.payrollTypes.daysOfWeek.thursday', value: 4 },
  { label: 'shared.payrollTypes.daysOfWeek.friday', value: 5 },
  { label: 'shared.payrollTypes.daysOfWeek.saturday', value: 6 },
  { label: 'shared.payrollTypes.daysOfWeek.sunday', value: 7 },
];

export const PayrollManualItemType = [
  {
    label: 'shared.payrollTypes.payrollManualItemType.income',
    value: 'income',
  },
  {
    label: 'shared.payrollTypes.payrollManualItemType.deduction',
    value: 'deduction',
  },
];
