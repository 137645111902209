import { AppConfig } from './../domain/appconfig';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  lightColors = [
    { name: 'Bit', file: 'bit', image: 'bit.svg' },
    { name: 'Blue', file: 'blue', image: 'blue.svg' },
    { name: 'Green', file: 'green', image: 'green.svg' },
    { name: 'Yellow', file: 'yellow', image: 'yellow.svg' },
    { name: 'Cyan', file: 'cyan', image: 'cyan.svg' },
    { name: 'Purple', file: 'purple', image: 'purple.svg' },
    { name: 'Orange', file: 'orange', image: 'orange.svg' },
    { name: 'Teal', file: 'teal', image: 'teal.svg' },
    { name: 'Magenta', file: 'magenta', image: 'magenta.svg' },
    { name: 'Lime', file: 'lime', image: 'lime.svg' },
    { name: 'Brown', file: 'brown', image: 'brown.svg' },
    { name: 'Red', file: 'red', image: 'red.svg' },
    { name: 'Indigo', file: 'indigo', image: 'indigo.svg' },
  ];
  darkColors = [
    { name: 'Bit', file: 'bit', image: 'bit.svg' },
    { name: 'Blue', file: 'blue', image: 'blue.svg' },
    { name: 'Green', file: 'green', image: 'green.svg' },
    { name: 'Yellow', file: 'yellow', image: 'yellow.svg' },
    { name: 'Cyan', file: 'cyan', image: 'cyan.svg' },
    { name: 'Purple', file: 'purple', image: 'purple.svg' },
    { name: 'Orange', file: 'orange', image: 'orange.svg' },
    { name: 'Teal', file: 'teal', image: 'teal.svg' },
    { name: 'Magenta', file: 'magenta', image: 'magenta.svg' },
    { name: 'Lime', file: 'lime', image: 'lime.svg' },
    { name: 'Brown', file: 'brown', image: 'brown.svg' },
    { name: 'Red', file: 'red', image: 'red.svg' },
    { name: 'Indigo', file: 'indigo', image: 'indigo.svg' },
  ];
  customColors = [
    { name: 'Chile', file: 'chile', image: 'chile.png' },
    { name: 'Naples', file: 'naples', image: 'naples.png' },
    { name: 'Georgia', file: 'georgia', image: 'georgia.png' },
    { name: 'Infinity', file: 'infinity', image: 'infinity.png' },
    { name: 'Chicago', file: 'chicago', image: 'chicago.png' },
    { name: 'Majesty', file: 'majesty', image: 'majesty.png' },
    { name: 'Fish', file: 'fish', image: 'fish.png' },
    { name: 'Dawn', file: 'dawn', image: 'dawn.png' },
    { name: 'Record', file: 'record', image: 'record.png' },
    { name: 'Pool', file: 'pool', image: 'pool.png' },
    { name: 'Metal', file: 'metal', image: 'metal.png' },
    { name: 'China', file: 'china', image: 'china.png' },
    { name: 'Table', file: 'table', image: 'table.png' },
    { name: 'Panorama', file: 'panorama', image: 'panorama.png' },
    { name: 'Barcelona', file: 'barcelona', image: 'barcelona.png' },
    { name: 'Underwater', file: 'underwater', image: 'underwater.png' },
    { name: 'Symmetry', file: 'symmetry', image: 'symmetry.png' },
    { name: 'Rain', file: 'rain', image: 'rain.png' },
    { name: 'Utah', file: 'utah', image: 'utah.png' },
    { name: 'Wave', file: 'wave', image: 'wave.png' },
    { name: 'Flora', file: 'flora', image: 'flora.png' },
    { name: 'Speed', file: 'speed', image: 'speed.png' },
    { name: 'Canopy', file: 'canopy', image: 'canopy.png' },
    { name: 'SanPaolo', file: 'sanpaolo', image: 'sanpaolo.png' },
    { name: 'Basketball', file: 'basketball', image: 'basketball.png' },
    { name: 'Misty', file: 'misty', image: 'misty.png' },
    { name: 'Summit', file: 'summit', image: 'summit.png' },
    { name: 'Wall', file: 'wall', image: 'wall.png' },
    { name: 'Ferris', file: 'ferris', image: 'ferris.png' },
    { name: 'Ship', file: 'ship', image: 'ship.png' },
    { name: 'NY', file: 'ny', image: 'ny.png' },
    { name: 'Cyan', file: 'cyan', image: 'cyan.png' },
    { name: 'Violet', file: 'violet', image: 'violet.png' },
    { name: 'Red', file: 'red', image: 'red.png' },
    { name: 'Blue', file: 'blue', image: 'blue.png' },
    { name: 'Porsuk', file: 'porsuk', image: 'porsuk.png' },
    { name: 'Pink', file: 'pink', image: 'pink.png' },
    { name: 'Purple', file: 'purple', image: 'purple.png' },
    { name: 'Orange', file: 'orange', image: 'orange.png' },
  ];
  menuColors = [{ name: 'light' }, { name: 'custom' }, { name: 'dark' }];
  selectedColorOptions = this.lightColors;
  componentThemes = [
    { name: 'Bit', file: 'bit', image: 'bit.svg' },
    { name: 'Blue', file: 'blue', image: 'blue.svg' },
    { name: 'Green', file: 'green', image: 'green.svg' },
    { name: 'Yellow', file: 'yellow', image: 'yellow.svg' },
    { name: 'Cyan', file: 'cyan', image: 'cyan.svg' },
    { name: 'Purple', file: 'purple', image: 'purple.svg' },
    { name: 'Orange', file: 'orange', image: 'orange.svg' },
    { name: 'Teal', file: 'teal', image: 'teal.svg' },
    { name: 'Magenta', file: 'magenta', image: 'magenta.svg' },
    { name: 'Lime', file: 'lime', image: 'lime.svg' },
    { name: 'Brown', file: 'brown', image: 'brown.svg' },
    { name: 'Red', file: 'red', image: 'red.svg' },
    { name: 'Indigo', file: 'indigo', image: 'indigo.svg' },
  ];

  applyAppConfig(config: AppConfig) {
    if (config.darkMode === true) {
      this.selectedColorOptions = this.darkColors;
    }
    this.changeStyleSheetsColor('theme-css', this.pathTheme(config));
    this.changeStyleSheetsColor('layout-css', this.pathLayout(config));
  }

  pathLayout(config: AppConfig) {
    return `assets/layout/css/${config.layoutColor}/layout-${
      config.darkMode === true ? 'dark' : 'light'
    }.css`;
  }
  pathTheme(config: AppConfig) {
    return `assets/theme/${config.themeColor}/theme-${
      config.darkMode === true ? 'dark' : 'light'
    }.css`;
  }

  changeStyleSheetsColor(id, value) {
    const element = document.getElementById(id);
    // const urlTokens = element.getAttribute('href').split('/');
    // urlTokens[urlTokens.length - 2] = value;
    // const newURL = urlTokens.join('/');
    this.replaceLink(element, value);
  }

  replaceLink(linkElement, href) {
    if (this.isIE()) {
      linkElement.setAttribute('href', href);
    } else {
      const id = linkElement.getAttribute('id');
      const cloneLinkElement = linkElement.cloneNode(true);
      cloneLinkElement.setAttribute('href', href);
      cloneLinkElement.setAttribute('id', id + '-clone');
      linkElement.parentNode.insertBefore(
        cloneLinkElement,
        linkElement.nextSibling
      );
      cloneLinkElement.addEventListener('load', () => {
        linkElement.remove();
        cloneLinkElement.setAttribute('id', id);
      });
    }
  }

  isIE() {
    return /(MSIE|Trident\/|Edge\/)/i.test(window.navigator.userAgent);
  }
}
