export const InterestTypes = [
  { label: 'tenant.loanTypes.interest.simple', value: 'simple' },
  { label: 'tenant.loanTypes.interest.fixed', value: 'fixed' },
];
export const TermTypes = [
  { label: 'tenant.loanTypes.term.days', value: 'days' },
  { label: 'tenant.loanTypes.term.months', value: 'months' },
  { label: 'tenant.loanTypes.term.years', value: 'years' },
];
export const LoanStatus = [
  { label: 'commons.in_progress', value: 'in_progress' },
  { label: 'commons.pending', value: 'pending' },
  { label: 'commons.approved', value: 'approved' },
  { label: 'commons.rejected', value: 'rejected' },
  { label: 'commons.cancelled', value: 'cancelled' },
  { label: 'commons.closed', value: 'closed' },
];
