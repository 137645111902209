<div class="flex align-items-center">
  <label class="pr-2">{{ "core.companySelector.label" | translate }}</label>
  <p-dropdown
    [options]="companies$ | async"
    [(ngModel)]="selectedCompany"
    optionLabel="name"
    dataKey="id"
    (onChange)="onChangeCompany($event)"
    [dropdownIcon]="
      (loading$ | async) ? 'pi pi-spin pi-spinner' : 'pi pi-chevron-down'
    "
  ></p-dropdown>
</div>
