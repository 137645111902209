import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SessionState } from './session.state';

export const selectSessionState =
  createFeatureSelector<SessionState>('session');
export const selectSessionCompaniesList = createSelector(
  selectSessionState,
  (state) => state.companies
);
export const selectProfile = createSelector(
  selectSessionState,
  (state) => state.profile
);
export const selectSelectedCompany = createSelector(
  selectSessionState,
  (state) => state.selectedCompany
);
export const selectSessionIsLoading = createSelector(
  selectSessionState,
  (state) => state.isLoading
);
export const selectCanChangeBetweenModules = createSelector(
  selectSessionState,
  (state) => state.canChangeBetweenModules
);

export const selectUiPreferences = createSelector(
  selectSessionState,
  (state) => state.profile?.data?.preferences
);
export const selectSessionMenuItems = createSelector(
  selectSessionState,
  (state) => state.menuItems
);
export const selectPasswordChanged = createSelector(
  selectSessionState,
  (state) => state.passwordChanged
);
export const selectSessionError = createSelector(
  selectSessionState,
  (state) => state.error
);
