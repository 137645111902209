import { Payroll } from './payroll.model';
import { PayrollCloseDetail } from './payrollCloseDetail';

export class PayrollClose {
  constructor(
    public id: number,
    public beginAt: string,
    public endAt: string,
    public status: string,
    public loading: boolean,
    public lastRunAt: string,
    public createdAt: string,
    public updateAt: string,
    public payroll: Payroll,
    public details: PayrollCloseDetail[],
    public statisticsSalary: any,
    public statisticsAttendance: any
  ) {}
}
