<!-- <a href="#" class="layout-config-button" (click)="onConfigButtonClick($event)">
<i class="pi pi-cog"></i>
</a> -->
<div
  id="layout-config"
  class="layout-config"
  [ngClass]="{
    'layout-config-exit-done': !appMain.configActive,
    'layout-config-enter-done': appMain.configActive
  }"
  [@children]="appMain.configActive ? 'visibleAnimated' : 'hiddenAnimated'"
>
  <div class="layout-config-content">
    <a
      href="#"
      class="layout-config-close"
      (click)="onConfigCloseClick($event)"
    >
      <i class="pi pi-times"></i>
    </a>
    <p-tabView id="config-form-tabs">
      <p-tabPanel
        header="{{ 'core.mirage.config.colorSchemeTab' | translate }}"
      >
        <h1>{{ "core.mirage.config.appearanceTitle" | translate }}</h1>

        <div class="grid justify-content-center align-items-center">
          <div class="col col-fixed">
            <a
              href="#"
              class="layout-config-option layout-config-option-image"
              (click)="changeLayout($event, false)"
            >
              <img
                src="assets/layout/images/configurator/choice-light.png"
                alt="mirage-layout"
                style="width: 100%"
              />
              <span class="layout-config-option-text">{{
                "core.mirage.config.light" | translate
              }}</span>
              @if (!app.darkMode) {
              <i class="pi pi-check"></i>
              }
            </a>
          </div>
          <div class="col col-fixed md:col-offset-1">
            <a
              href="#"
              class="layout-config-option layout-config-option-image"
              (click)="changeLayout($event, true)"
            >
              <img
                src="assets/layout/images/configurator/choice-dark.png"
                alt="mirage-layout"
                style="width: 100%"
              />
              <span class="layout-config-option-text">{{
                "core.mirage.config.dark" | translate
              }}</span>
              @if (app.darkMode) {
              <i class="pi pi-check"></i>
              }
            </a>
          </div>
        </div>
      </p-tabPanel>
      <p-tabPanel header="{{ 'core.mirage.config.menuTab' | translate }}">
        <div class="layout-config-subtitle">
          {{ "core.mirage.config.mode" | translate }}
        </div>
        <div class="grid">
          <div class="col col-fixed">
            <a
              href="#"
              class="layout-config-option layout-config-option-image"
              (click)="changeMenuToHorizontal($event, true)"
            >
              <img
                src="assets/layout/images/configurator/menu/horizontal.png"
                alt="mirage-layout"
                style="width: 100%"
              />
              <span class="layout-config-option-text">{{
                "core.mirage.config.horizontal" | translate
              }}</span>
              @if (app.horizontalMenu) {
              <i class="pi pi-check"></i>
              }
            </a>
          </div>
          <div class="col col-fixed">
            <a
              href="#"
              class="layout-config-option layout-config-option-image"
              (click)="changeMenuToHorizontal($event, false)"
            >
              <img
                src="assets/layout/images/configurator/menu/overlay.png"
                alt="mirage-layout"
                style="width: 100%"
              />
              <span class="layout-config-option-text">{{
                "core.mirage.config.vertical" | translate
              }}</span>
              @if (!app.horizontalMenu) {
              <i class="pi pi-check"></i>
              }
            </a>
          </div>
        </div>
        <div class="layout-config-subtitle">
          {{ "core.mirage.config.color" | translate }}
        </div>
        <div class="grid">
          @for (menuColor of menuColors; track menuColor) {
          <div class="col col-fixed">
            <a
              href="#"
              class="layout-config-option layout-config-option-image"
              (click)="changeMenuColor($event, menuColor.name)"
            >
              <img
                src="assets/layout/images/configurator/menu/{{
                  menuColor.name
                }}.png"
                alt="{{ menuColor.name }}"
              />
              <span class="layout-config-option-text">{{
                menuColor.name
              }}</span>
              @if (menuColor.name === app.menuColorMode) {
              <i class="pi pi-check"></i>
              }
            </a>
          </div>
          }
        </div>
        <div class="layout-config-subtitle">
          {{ "core.mirage.config.theme" | translate }}
        </div>
        <div class="grid">
          @for (menuTheme of selectedColorOptions; track menuTheme) {
          <div class="col col-fixed">
            <a
              href="#"
              class="layout-config-option layout-config-option-image layout-config-option-theme"
              (click)="changeMenuTheme($event, menuTheme.file)"
            >
              <img
                src="assets/layout/images/configurator/menu/theme/{{
                  menuTheme.image
                }}"
                alt="{{ menuTheme.name }}"
              />
              @if (app.menuColorMode === 'custom' && 'layout-menu-' +
              menuTheme.file === app.menuColor) {
              <i class="pi pi-check"></i>
              } @if (app.menuColorMode !== 'custom' && menuTheme.file ===
              app.layoutColor) {
              <i class="pi pi-check"></i>
              }
            </a>
          </div>
          }
        </div>
      </p-tabPanel>
      <p-tabPanel header="{{ 'core.mirage.config.formsTab' | translate }}">
        <div class="grid">
          <div class="col-12 md:col-6">
            <div class="layout-config-subtitle">
              {{ "core.mirage.config.formStyle" | translate }}
            </div>
            <div class="formgroup-inline">
              <div class="field-checkbox">
                <p-radioButton
                  name="inputStyle"
                  value="outlined"
                  [(ngModel)]="app.inputStyle"
                  inputId="inputStyle1"
                ></p-radioButton>
                <label for="inputStyle1">{{
                  "core.mirage.config.outlined" | translate
                }}</label>
              </div>
              <div class="field-checkbox">
                <p-radioButton
                  name="inputStyle"
                  value="filled"
                  [(ngModel)]="app.inputStyle"
                  inputId="inputStyle2"
                ></p-radioButton>
                <label for="inputStyle2">{{
                  "core.mirage.config.filled" | translate
                }}</label>
              </div>
            </div>
          </div>
          <div class="col-12 md:col-6">
            <div class="layout-config-subtitle">
              {{ "core.mirage.config.buttonEffects" | translate }}
            </div>
            <p-inputSwitch
              [ngModel]="app.ripple"
              (onChange)="appMain.onRippleChange($event)"
            ></p-inputSwitch>
          </div>
        </div>

        <div class="layout-config-subtitle">
          {{ "core.mirage.config.componentTheme" | translate }}
        </div>
        <div class="grid">
          @for (componentTheme of componentThemes; track componentTheme) {
          <div class="col col-fixed">
            <a
              href="#"
              class="layout-config-option layout-config-option-image layout-config-option-theme"
              (click)="changeComponentTheme($event, componentTheme.file)"
            >
              <img
                src="assets/layout/images/configurator/theme/{{
                  componentTheme.image
                }}"
                alt="{{ componentTheme.name }}"
              />
              @if (componentTheme.file === app.themeColor) {
              <i class="pi pi-check"></i>
              }
            </a>
          </div>
          }
        </div>
      </p-tabPanel>
      <p-tabPanel header="{{ 'core.mirage.config.langTabHeader' | translate }}">
        <div class="grid p-fluid">
          <div class="field col-12 md:col-6">
            <label for="lang" class="layout-config-subtitle">{{
              "core.mirage.config.langTabHeader" | translate
            }}</label>
            <p-dropdown
              [options]="languages"
              [(ngModel)]="selectedLang"
              (onChange)="onLangChanged($event)"
            ></p-dropdown>
          </div>
        </div>
      </p-tabPanel>
    </p-tabView>
  </div>
</div>
