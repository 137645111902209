import { createReducer, on } from '@ngrx/store';
import { CompanyState } from './company.state';
import { CompanyActions } from './company.actions';

export const initialCompaniesState: CompanyState = {
  companies: [],
  isLoading: false,
  error: null,
  isEditing: false,
};

const reducer = createReducer<CompanyState>(
  initialCompaniesState,
  on(CompanyActions.getCompanies, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(CompanyActions.getCompaniesSuccess, (state, { companies }) => ({
    ...state,
    isLoading: false,
    error: null,
    companies,
  })),
  on(CompanyActions.saveCompany, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(CompanyActions.saveCompanySuccess, (state, { company }) => ({
    ...state,
    companies:
      state.companies.findIndex((x) => x.id === company.id) === -1
        ? [...state.companies, company]
        : state.companies.map((b) => (b.id === company.id ? company : b)),
    isLoading: false,
    isEditing: false,
    error: null,
  })),
  on(CompanyActions.deleteCompany, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(CompanyActions.deleteCompanySuccess, (state, { company }) => ({
    ...state,
    isLoading: false,
    error: null,
    companies: state.companies.filter((b) => b.id !== company.id),
  })),
  on(CompanyActions.addEmployeesQuantity, (state, { companyId, quantity }) => {
    let companyToUpdate = {
      ...state.companies.find((x) => x.id === companyId),
    };
    companyToUpdate.countEmployees += quantity;
    return {
      ...state,
      isLoading: true,
      error: null,
      companies: state.companies.map((b) =>
        b.id === companyToUpdate.id ? companyToUpdate : b
      ),
    };
  }),
  on(CompanyActions.addEmployeesQuantitySuccess, (state) => ({
    ...state,
    isLoading: false,
    error: null,
  })),
  on(CompanyActions.catchErrorFromApi, (state, { payload }) => ({
    ...state,
    isLoading: false,
    error: payload,
  })),
  on(CompanyActions.startEdition, (state) => ({
    ...state,
    isEditing: true,
  })),
  on(CompanyActions.endEdition, (state) => ({
    ...state,
    isEditing: false,
  })),
  on(CompanyActions.uploadLogo, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(CompanyActions.uploadLogoSuccess, (state, { company }) => ({
    ...state,
    isLoading: false,
    companies: state.companies.map((b) => (b.id === company.id ? company : b)),
  })),
  on(CompanyActions.deleteLogo, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(CompanyActions.deleteLogoSuccess, (state, { company }) => ({
    ...state,
    isLoading: false,
    companies: state.companies.map((b) => (b.id === company.id ? company : b)),
  }))
);

export function CompaniesReducer(
  state = initialCompaniesState,
  actions
): CompanyState {
  return reducer(state, actions);
}
