export enum TypeOfCalendar {
  Payroll = 'payroll',
  Employee = 'employee',
}

export enum TypeOfEvent {
  Scheduling = 'scheduling',
  PersonnelAction = 'personnel_action',
}

export enum TypeOfEventStatus {
  Rejected = 'rejected',
  Approved = 'approved',
}

// export const EventStatus = [
//   { label: 'Solicitud enviada', value: null },
//   { label: 'Rechazado', value: TypeOfEventStatus.Rejected },
//   { label: 'Aprobado', value: TypeOfEventStatus.Approved },
// ];
