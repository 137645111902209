import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { LocalStorageService } from '@app/shared/services';

@Injectable({
  providedIn: 'root',
})
export class LanguageInterceptorService implements HttpInterceptor {
  constructor(private localStorageService: LocalStorageService) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    request = request.clone({
      setHeaders: {
        'X-Localization': this.localStorageService.getLang(),
      },
    });
    return next.handle(request);
  }
}
