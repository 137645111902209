import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-icon-event-type',
  templateUrl: './icon-event-type.component.html',
  styles: [],
})
export class IconEventTypeComponent implements OnInit {
  @Input() event: any = null;
  @Input() iconClass: string = '';

  constructor() {}

  ngOnInit(): void {}

  getIconByType() {
    if (this.event === null) {
      return '';
    }
    if (this.event.isVacation && this.event.isVacation === true) {
      return 'assets/layout/images/icon_smile.svg';
    }
    if (this.event.isDisability && this.event.isDisability === true) {
      return 'assets/layout/images/icon_heart.svg';
    }
    if (this.event.isLeave && this.event.isLeave === true) {
      return 'assets/layout/images/icon_calendar.svg';
    }
  }
}
