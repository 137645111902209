import { Injectable } from '@angular/core';
import { HttpService } from '@app/shared/services';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ExtraMonthDetailService {
  constructor(private http: HttpService) {}

  show(extraMonthDetailId: number) {
    return this.http
      .get(`extra-month-details/${extraMonthDetailId}`)
      .pipe(map((response) => response.data))
      .toPromise();
  }

  save(extraMonthDetailId: number, data: any) {
    return this.http[data.id ? 'put' : 'post'](
      `extra-month-details/${extraMonthDetailId}/additionals${
        data.id ? `/${data.id}` : ''
      }`,
      data
    )
      .pipe(map((response) => response.data))
      .toPromise();
  }

  delete(extraMonthDetailId: number, additionalId: number) {
    return this.http
      .delete(
        `extra-month-details/${extraMonthDetailId}/additionals/${additionalId}`
      )
      .pipe(map((response) => response.data))
      .toPromise();
  }
}
