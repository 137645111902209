import { ThemeService } from '@core/services';
import { BreadcrumbService } from './breadcrumb.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivationEnd, Router } from '@angular/router';
import { PrimeNGConfig } from 'primeng/api';
import { filter, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SessionState, selectUiPreferences } from '@app/store/session';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { LangService } from '@app/shared/services';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {
  horizontalMenu: boolean;

  darkMode = false;

  menuColorMode = 'light';

  menuColor = 'layout-menu-light';

  themeColor = 'bit';

  layoutColor = 'bit';

  ripple = true;

  inputStyle = 'outlined';

  subscription: Subscription = new Subscription();

  constructor(
    private primengConfig: PrimeNGConfig,
    private router: Router,
    public title: Title,
    public meta: Meta,
    private breadCrumbService: BreadcrumbService,
    private store: Store<SessionState>,
    private themeService: ThemeService,
    private langService: LangService
  ) {
    this.getDataRoute().subscribe((response) => {
      // custom tittle
      this.setTittle(response);
      // custom breadCrumb
      this.setBreadCrumb(response);
    });
  }

  ngOnInit() {
    this.primengConfig.ripple = true;
    this.subscription.add(
      this.store.select(selectUiPreferences).subscribe((preferences) => {
        if (preferences) {
          this.horizontalMenu = preferences.horizontalMenu;
          this.darkMode = preferences.darkMode;
          this.menuColorMode = preferences.menuColorMode;
          this.menuColor = preferences.menuColor;
          this.themeColor = preferences.themeColor;
          this.layoutColor = preferences.layoutColor;
          this.ripple = preferences.ripple;
          this.inputStyle = preferences.inputStyle;
          this.themeService.applyAppConfig(preferences);
          this.langService.setLang(preferences.lang);
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  private getDataRoute() {
    return this.router.events.pipe(
      filter((event) => event instanceof ActivationEnd),
      filter((event: ActivationEnd) => event.snapshot.firstChild === null),
      map((event: ActivationEnd) => event.snapshot.data)
    );
  }
  /**
   * set a stantar tittle
   * @param data data that contais label to tittle
   */
  private setTittle(data) {
    // create a standar tittle
    let label = `${environment.appName} - ${environment.companyName}`;
    if (data.title) {
      label = `${data.title} - ${environment.appName} - ${environment.companyName}`;
    }
    // set tittle
    this.title.setTitle(label);
  }

  /**
   * set a breadcrumb Mirage template
   * @param data data that contains breadCrumb data
   */
  private setBreadCrumb(data: any) {
    if (data.breadCrumb) {
      this.breadCrumbService.setItems(data.breadCrumb);
    } else {
      this.breadCrumbService.setItems([
        { label: 'HI', routerLink: ['/'] },
        { label: 'in routing.ts module file', routerLink: ['/'] },
        { label: 'remember add breadCrumb object', routerLink: ['/'] },
      ]);
    }
  }
}
