export const Langs = [
  {
    label: 'Español',
    value: 'es',
  },
  {
    label: 'English US',
    value: 'en',
  },
];
