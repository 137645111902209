import { filter } from 'rxjs/operators';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpService, ToastService } from '@shared/services';
import { AppInjector } from '@shared/app-injector.service';
import { ObjectRepository } from '@shared/repositories';

export class ServiceDataStorage {
  private _mapData = 'data';
  private dataSource = new BehaviorSubject<any>([]);
  /**
   * Http Service helper
   */
  public httpService: HttpService = AppInjector.injector.get(HttpService);
  /**
   * Toast Service helper
   */
  public toastService: ToastService = AppInjector.injector.get(ToastService);

  /**
   * Is a Observable that need suscribe to get the data
   * IMPORTANT: remember unsuscribe on Init
   */
  public data = this.dataSource.asObservable();
  private models: any;
  private loadingSource = new BehaviorSubject<boolean>(false);
  /**
   * Is a Observavle that need suscribe to get loading status
   */
  public loading = this.loadingSource.asObservable();
  /**
   * Array of ids model to use
   */
  public idsModels: Array<number>;
  /**
   * when get the data configure the map of data
   */
  public get mapData() {
    return this._mapData;
  }
  public set mapData(value: string) {
    // Our Api always have encapsulaed in data
    if (value !== undefined) {
      this._mapData = `data.${value}`;
    }
  }

  private totalRecordsSubject: BehaviorSubject<number> =
    new BehaviorSubject<number>(-1);
  /**
   * count total of pagination items
   */
  public totalRecords: Observable<number> =
    this.totalRecordsSubject.asObservable();
  private eventsSubject = new BehaviorSubject<{ event: string; data: any }>(
    undefined
  );
  /**
   * Emmit events in object
   */
  public events = this.eventsSubject.asObservable();
  /**
   * Helper Service Data Storage Pattern
   * @param models string of models name patther REST Ful
   */
  constructor(...models: any) {
    this.models = models;
  }
  /**
   * set models
   * @param models models to set
   */
  setModels(...models: any) {
    this.models = models;
  }
  setModelsArray(models: Array<any>) {
    this.models = models;
  }
  /**
   * Get current data from dataSource
   * @returns any data
   */
  getCurrentData(): any {
    return this.dataSource.value;
  }
  /**
   * index method of Api
   * @param ids array of id if have multiple models
   */
  private index({ ids = [], urlParams = '' }): Observable<any> {
    return this.httpService
      .get(`${this.factoryUrl(ids, urlParams)}`)
      .pipe(
        filter(
          (x) =>
            ObjectRepository.getValueByPath(x, this.mapData) !== null ||
            ObjectRepository.getValueByPath(x, this.mapData) !== undefined
        )
      );
  }
  /**
   * Store data
   * @param data Object data to send
   * @param ids array of id if have multiple models
   */
  store({ data, ids = [], genericSavedMessage = true }): Promise<any> {
    return new Promise((resolve, reject) => {
      this.loadingSource.next(true);
      this.httpService.post(`${this.factoryUrl(ids)}`, data).subscribe(
        (response) => {
          let newData = ObjectRepository.getValueByPath(response, this.mapData);
          if (
            this.dataSource.value === null ||
            this.dataSource.value === undefined
          ) {
            this.dataSource.next([]);
          }
          (this.dataSource.value as Array<any>).push(newData);
          if (genericSavedMessage) {
            this.toastService.showGenericSaved();
          }
          this.dataSource.next(this.dataSource.value);
          this.loadingSource.next(false);
          this.eventsSubject.next({ event: 'store', data: newData });

          resolve(newData);
        },
        (error) => {
          this.toastService.showHttpErrorResponse(error, true);
          this.loadingSource.next(false);
          reject(error);
        }
      );
    });
  }
  /**
   * Update data
   * @param data data to update | required ID
   * @param ids array of id if have multiple models
   */
  update({ data, ids = [], genericSavedMessage = true }): Promise<any> {
    return new Promise((resolve, reject) => {
      this.loadingSource.next(true);
      this.httpService
        .put(`${this.factoryUrl(ids)}/${data.id}`, data)
        .subscribe(
          (response) => {
            let newData = ObjectRepository.getValueByPath(
              response,
              this.mapData
            );
            if (Array.isArray(this.dataSource.value)) {
              (this.dataSource.value as Array<any>)[
                (this.dataSource.value as Array<any>).findIndex(
                  (item) => item.id === newData.id
                )
              ] = newData;
            } else {
              newData.method = 'update';
              this.dataSource.next(newData);
            }
            if (genericSavedMessage) {
              this.toastService.showGenericSaved();
            }
            this.loadingSource.next(false);
            this.eventsSubject.next({ event: 'update', data: newData });

            resolve(newData);
          },
          (error) => {
            this.toastService.showHttpErrorResponse(error, true);
            this.loadingSource.next(false);
            reject(error);
          }
        );
    });
  }
  /**
   * destroy id
   * @param id id of Objecto to destroy
   * @param ids array of id if have multiple models
   */
  destroy({ id, ids = null, genericDeletedMessage = true }): Promise<any> {
    return new Promise((resolve, reject) => {
      this.loadingSource.next(true);
      this.httpService.delete(`${this.factoryUrl(ids)}/${id}`).subscribe(
        (response) => {
          let deletedItem = ObjectRepository.getValueByPath(
            response,
            this.mapData
          );
          // splice item
          this.dataSource.value.splice(
            this.dataSource.value.findIndex((x) => x.id === deletedItem.id),
            1
          );
          // publish the data updated
          this.dataSource.next(this.dataSource.value);
          if (genericDeletedMessage) {
            this.toastService.showGenericDeleted();
          }
          this.loadingSource.next(false);
          this.eventsSubject.next({ event: 'destroy', data: deletedItem });

          resolve(deletedItem);
        },
        (error) => {
          this.toastService.showHttpErrorResponse(error, true);
          this.loadingSource.next(false);
          reject(error);
        }
      );
    });
  }
  /**
   * Reload data
   * @param force true to force reload
   * @param ids array of id if have multiple models
   */
  refresh({ force = false, ids = [], urlParams = '' }): Promise<any> {
    return new Promise((resolve, reject) => {
      if (
        force ||
        this.dataSource.value === null ||
        (this.dataSource.value as Array<any>).length === 0
      ) {
        this.loadingSource.next(true);
        this.index({ ids, urlParams }).subscribe(
          (response) => {
            let newData = ObjectRepository.getValueByPath(
              response,
              this.mapData
            );
            this.dataSource.next(newData);
            // pagination if exists
            if (response?.pagination?.total !== undefined) {
              this.totalRecordsSubject.next(response.pagination.total);
            }
            this.loadingSource.next(false);
            this.eventsSubject.next({ event: 'refresh', data: newData });
            resolve(newData);
          },
          (error) => {
            this.toastService.showHttpErrorResponse(error, true);
            this.loadingSource.next(false);
            reject(error);
          }
        );
      }
    });
  }
  /**
   * try to create a url to use dynamic
   * @param ids array of id if have multiple models
   */
  private factoryUrl(ids: any, params: string = ''): string {
    let url = '';
    // priorize if send ids or take in the object
    const _ids =
      ids !== undefined && ids !== null && ids.length > 0
        ? ids
        : this.idsModels !== undefined
        ? this.idsModels
        : [];
    this.models.forEach((value, index) => {
      url += `${index === 0 ? '' : '/'}${value}${
        _ids[index] > 0 ? `/${_ids[index]}` : ''
      }`;
    });
    // if end with / remove
    url = url.endsWith('/') ? url.substr(0, url.length - 1) : url;
    return url + (params.length > 0 ? `?${params}` : '');
  }
  /**
   * Helper to storage or update data, only work if the Object has ID property
   * @param data data to send
   * @param ids array of id if have multiple models
   */
  save({ data, ids = [], genericSavedMessage = true }): Promise<any> {
    if (data.id === undefined || data.id === 0) {
      return this.store({ data, ids, genericSavedMessage });
    }
    return this.update({ data, ids, genericSavedMessage });
  }
  /**
   * custom request, response promise on data
   * @param method http method GET POST PUT DELETE
   * @param fullPath full url
   * @param showError show error from default
   * @param data optional data to send
   */
  customRequest({
    method = 'get',
    fullPath = '',
    showError = true,
    pluckData = true,
    data = undefined,
    updateSource = true,
    useLoader = true,
  }): Promise<any> {
    return new Promise((resolve, reject) => {
      if (useLoader) {
        this.loadingSource.next(true);
      }
      switch (method.toLowerCase()) {
        case 'post':
          // POST
          this.httpService.post(fullPath, data).subscribe(
            (response) => {
              let newData = ObjectRepository.getValueByPath(
                response,
                this.mapData
              );
              if (updateSource) {
                this.dataSource.next(pluckData ? newData : response);
              }
              if (useLoader) {
                this.loadingSource.next(false);
              }
              this.eventsSubject.next({ event: 'post', data: newData });
              resolve(pluckData ? newData : response);
            },
            (error) => {
              if (showError) {
                this.toastService.showHttpErrorResponse(error, true);
              }
              if (useLoader) {
                this.loadingSource.next(false);
              }
              reject(error);
            }
          );
          break;
        case 'put':
          // PUT
          this.httpService.put(fullPath, data).subscribe(
            (response) => {
              let newData = ObjectRepository.getValueByPath(
                response,
                this.mapData
              );
              if (updateSource) {
                this.dataSource.next(pluckData ? newData : response);
              }
              if (useLoader) {
                this.loadingSource.next(false);
              }
              this.eventsSubject.next({ event: 'put', data: newData });
              resolve(pluckData ? newData : response);
            },
            (error) => {
              if (showError) {
                this.toastService.showHttpErrorResponse(error, true);
              }
              if (useLoader) {
                this.loadingSource.next(false);
              }
              reject(error);
            }
          );
          break;
        case 'delete':
          // DELETE
          this.httpService.delete(fullPath, data).subscribe(
            (response) => {
              let newData = ObjectRepository.getValueByPath(
                response,
                this.mapData
              );
              if (updateSource) {
                this.dataSource.next(pluckData ? newData : response);
              }
              if (useLoader) {
                this.loadingSource.next(false);
              }
              this.eventsSubject.next({ event: 'delete', data: newData });
              resolve(pluckData ? newData : response);
            },
            (error) => {
              if (showError) {
                this.toastService.showHttpErrorResponse(error, true);
              }
              if (useLoader) {
                this.loadingSource.next(false);
              }
              reject(error);
            }
          );
          break;

        default:
          // GET
          this.httpService
            .get(fullPath)
            .pipe(
              filter(
                (x) =>
                  ObjectRepository.getValueByPath(x, this.mapData) !== null ||
                  ObjectRepository.getValueByPath(x, this.mapData) !== undefined
              )
            )
            .subscribe(
              (response) => {
                let newData = ObjectRepository.getValueByPath(
                  response,
                  this.mapData
                );
                if (updateSource) {
                  this.dataSource.next(newData);
                }
                // pagination if exists
                if (response?.pagination?.total !== undefined) {
                  this.totalRecordsSubject.next(response.pagination.total);
                }
                if (useLoader) {
                  this.loadingSource.next(false);
                }
                this.eventsSubject.next({ event: 'get', data: newData });
                resolve(
                  ObjectRepository.getValueByPath(response, this.mapData)
                );
              },
              (error) => {
                if (showError) {
                  this.toastService.showHttpErrorResponse(error, true);
                }
                if (useLoader) {
                  this.loadingSource.next(false);
                }
                reject(error);
              }
            );
          break;
      }
    });
  }
  /**
   * count total of items on datasource
   */
  countItems(): number {
    return (this.dataSource.value as Array<any>).length;
  }
  /**
   * Clean data source value
   */
  clearDataSource() {
    this.dataSource.next([]);
  }
}
