import { ServiceDataStorage } from '@shared/helpers';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PayrollEmployeeSyncService extends ServiceDataStorage {
  constructor() {
    super('payrolls', 'employees-sync');
  }
  attach(payrollId: number, ids: Array<number>): Promise<any> {
    return this.customRequest({
      method: 'post',
      fullPath: `payrolls/${payrollId}/employees-sync`,
      data: { employeesIds: ids },
      useLoader: false,
      updateSource: false,
    });
  }
  detach(payrollId: number, ids: Array<number>): Promise<any> {
    return this.customRequest({
      method: 'post',
      fullPath: `payrolls/${payrollId}/employees-detach`,
      data: { employeesIds: ids },
      useLoader: false,
      updateSource: false,
    });
  }
}
