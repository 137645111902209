export class ObjectRepository {
  /**
   * access data object dynamic separated by points
   * @param obj object multi level
   * @param path path separated by points
   */
  public static getValueByPath(obj: any, path: string) {
    if (obj === null || obj === undefined) {
      return obj;
    }
    if (!path) {
      return obj;
    }
    const properties = path.split('.');
    return this.getValueByPath(
      obj[this.findKeyCaseInsensitive(obj, properties.shift())],
      properties.join('.')
    );
  }
  public static findKeyCaseInsensitive(object: any, keyToFind: string): string {
    return Object.keys(object).find(
      (key) => key.toLowerCase() === keyToFind.toLowerCase()
    );
  }

  /**
   * access data object dynamic seperated by points
   * @param obj object multi level
   * @param path path separated by points
   * @param value value to set
   */
  public static setValueByPath(obj: any, path: string, value: any) {
    const properties = path.split('.');
    if (properties.length === 1) {
      obj[properties.shift()] = value;
      return;
    }
    return this.setValueByPath(
      obj[properties.shift()],
      properties.join('.'),
      value
    );
  }
  /**
   * compare two object properties
   * @param a object a
   * @param b object b
   * @returns boolean true is equivalent
   */
  public static isEquivalent(a, b) {
    // Create arrays of property names
    var aProps = Object.getOwnPropertyNames(a);
    var bProps = Object.getOwnPropertyNames(b);

    // If number of properties is different,
    // objects are not equivalent
    if (aProps.length != bProps.length) {
      return false;
    }

    for (var i = 0; i < aProps.length; i++) {
      var propName = aProps[i];

      // If values of same property are not equal,
      // objects are not equivalent
      if (a[propName] !== b[propName]) {
        return false;
      }
    }

    // If we made it this far, objects
    // are considered equivalent
    return true;
  }
}
