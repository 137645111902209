import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dgDropdownValue',
})
export class DgDropdownValuePipe implements PipeTransform {
  transform(value: unknown, ...args: string[]): unknown {
    // if dropdown has array value, join in separated by comma
    let result = value;
    let mapLabel = args[0] !== undefined ? args[0] : 'label';
    if (Array.isArray(result)) {
      if (result[0] === null) {
        // * Wear error, Not understand this error, to reproduce
        // 1. user with role remove
        // 2. save
        return null;
      }
      result = result.map((x) => x[mapLabel]).join();
    }
    return result;
  }
}
