import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { LangService } from '../services';

@Pipe({
  name: 'humanize',
})
export class HumanizePipe implements PipeTransform {
  constructor(private langService: LangService) {}
  transform(value: unknown, ...args: unknown[]): unknown {
    if (!value) {
      return;
    }
    return moment(value).locale(this.langService.getLang()).fromNow();
  }
}
