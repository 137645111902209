import { HttpService } from '@shared/services';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PasswordService {
  constructor(private http: HttpService) {}
  /**
   * Create a password reset
   * @param email email of user
   * @returns Observable
   */
  create(email: string) {
    return this.http.post('password/create', { email });
  }
  /**
   * Validate a password reset token
   * @param token token
   * @returns Observable
   */
  findToken(token: string) {
    return this.http.get(`password/find/${token}`);
  }
  /**
   * Reset an password by token
   * @param email email user
   * @param password new password
   * @param passwordConfirmation password confirmation
   * @param token token
   * @returns Observable
   */
  reset(email, password, passwordConfirmation, token) {
    return this.http.post('password/reset', {
      email,
      password,
      passwordConfirmation,
      token,
    });
  }
}
