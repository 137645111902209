import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { EditorManualItemService } from '@shared/services';
import { Str } from '@shared/helpers';
import { CalculationRulesToShowService } from '@tenant/services';
import {
  PayrollCloseActions,
  selectPayrollClosesIsUpdatingDetailNote,
  selectSelectedPayrollCloseDetail,
} from '@app/store/payrollClose';
import { Payroll, PayrollClose, PayrollCloseDetail } from '@app/core/models';
@Component({
  selector: 'app-employee-payroll-detail',
  templateUrl: './employee-payroll-detail.component.html',
  styles: [
    `
      .net-pay {
        background: #efefef;
        border-radius: 5px;
        padding: 20px;
      }
    `,
  ],
})
export class EmployeePayrollDetailComponent implements OnInit, OnDestroy {
  payroll: Payroll;
  payrollClose: PayrollClose;
  payrollCloseDetail: PayrollCloseDetail;
  isUpdatingNote$ = this.store.select(selectPayrollClosesIsUpdatingDetailNote);
  rules: Array<any> = undefined;
  incomes: Array<any> = undefined;
  deductions: Array<any> = undefined;
  allowEditData: boolean = true;
  note: string = null;
  subscription: Subscription = new Subscription();

  constructor(
    private rulesToShowService: CalculationRulesToShowService,
    private editorManualItemService: EditorManualItemService,
    private store: Store<{}>
  ) {
    this.rules = this.rulesToShowService.getIncomes();
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    this.subscription.add(
      this.store
        .select(selectSelectedPayrollCloseDetail)
        .subscribe((selected) => {
          if (selected === null) {
            return;
          }

          this.payroll = selected.payroll;
          this.payrollClose = selected.payrollClose;
          this.payrollCloseDetail = selected.payrollCloseDetail;
          this.allowEditData = selected.allowedEdit;

          if (![null, undefined].includes(this.payrollCloseDetail)) {
            this.editorManualItemService.refresh({
              force: true,
              ids: [
                this.payrollCloseDetail.payrollCloseId,
                this.payrollCloseDetail.employee.id,
              ],
            });

            this.extractIncomes();
            this.extractDeductions();
            this.note = this.payrollCloseDetail?.note?.message;
          }
        })
    );
  }

  onChangedManual(e) {
    this.store.dispatch(
      PayrollCloseActions.refreshAPayrollCloseDetail({
        payrollCloseDetail: this.payrollCloseDetail,
      })
    );
  }

  extractIncomes() {
    if (this.payrollCloseDetail.incomes.items && this.rules) {
      this.incomes = this.payrollCloseDetail.incomes.items
        .filter((x) => x.isManual === undefined)
        .map((y) => {
          // add the rule to object
          let index = this.rules.findIndex(
            (z) => Str.toSnake(z.label) === Str.toSnake(y.name)
          );
          return index !== -1 ? { ...y, rule: this.rules[index] } : y;
        });
    }
  }

  extractDeductions() {
    if (this.payrollCloseDetail.deductions.items) {
      this.deductions = this.payrollCloseDetail.deductions.items.filter(
        (x) => x.isManual === undefined
      );
    }
  }

  showRow(income: any): boolean {
    return (
      income.total != 0 ||
      (this.isCumulative() === true &&
        ['Extra', 'Doble descanso', 'Feriado','Doble feriado'].includes(income.name))
    );
  }

  saveNote() {
    this.store.dispatch(
      PayrollCloseActions.updateAPayrollCloseDetailNote({
        payrollCloseDetailId: this.payrollCloseDetail.id,
        note: this.note,
      })
    );
  }

  isCumulative(): boolean {
    return this.payroll.settings?.cumulative?.enabled ?? false;
  }
}
