import { Company } from '@app/core/models';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const CompanyActions = createActionGroup({
  source: 'Company',
  events: {
    'Get companies': emptyProps(),
    'Get companies success': props<{ companies: Company[] }>(),
    'Save company': props<{ company: Company }>(),
    'Save company success': props<{ company: Company }>(),
    'Delete company': props<{ company: Company }>(),
    'Delete company success': props<{ company: Company }>(),
    'Add employees quantity': props<{ companyId: number; quantity: number }>(),
    'Add employees quantity success': emptyProps(),
    'Upload logo': props<{ companyId: number; logo: any }>(),
    'Upload logo success': props<{ company: Company }>(),
    'Delete logo': props<{ companyId: number }>(),
    'Delete logo success': props<{ company: Company }>(),
    'Catch error from api': props<{ payload: any }>(),
    'Start edition': emptyProps(),
    'End edition': emptyProps(),
  },
});
