import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'eventTypeValue',
})
export class EventTypeValuePipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(value: any, ...args: unknown[]): unknown {
    if (!value) {
      return '';
    }
    return this.translate.instant(`shared.evenTypeValue.${value.type}`);
  }
}
