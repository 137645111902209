import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PayrollCloseState } from './payroll-close.state';

export const selectPayrollCloseState =
  createFeatureSelector<PayrollCloseState>('payrollCloses');

export const selectPayrollClosesList = createSelector(
  selectPayrollCloseState,
  (state) => state.payrollCloses
);

export const selectPayrollClosesIsLoading = createSelector(
  selectPayrollCloseState,
  (state) => state.isLoading
);
export const selectPayrollClosesError = createSelector(
  selectPayrollCloseState,
  (state) => state.error
);
export const selectPayrollClosesIsClosing = createSelector(
  selectPayrollCloseState,
  (state) => state.isClosingPayrollClose
);
export const selectPayrollClosesIsCreating = createSelector(
  selectPayrollCloseState,
  (state) => state.isCreatingPayrollClose
);
export const selectPayrollClosesIsUpdatingDetailNote = createSelector(
  selectPayrollCloseState,
  (state) => state.isUpdatingPayrollCloseDetailNote
);
export const selectSelectedPayrollCloseDetail = createSelector(
  selectPayrollCloseState,
  (state) => state.selectedDetail
);
