import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  SessionState,
  selectCanChangeBetweenModules,
  selectProfile,
} from '@app/store/session';
import { AuthenticationService } from './authentication.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class PermissionHelperService {
  currentUserPermissions: string[] = [];
  canChangeBetweenModules: boolean = false;
  constructor(
    private store: Store<SessionState>,
    private authService: AuthenticationService,
    private router: Router
  ) {
    this.store
      .select(selectProfile)
      .subscribe(
        (profile) => (this.currentUserPermissions = profile.permissions)
      );
    this.store
      .select(selectCanChangeBetweenModules)
      .subscribe((value) => (this.canChangeBetweenModules = value));
  }
  hasPermission(value: string | string[]): boolean {
    if (value === undefined) {
      return true;
    }
    if (this.currentUserPermissions === undefined) {
      return false;
    }
    if (Array.isArray(value)) {
      return value.some(
        (x) => (this.currentUserPermissions as string[]).indexOf(x) !== -1
      );
    }
    return this.currentUserPermissions.indexOf(value) !== -1;
  }
  isSelfManagement() {
    return this.hasPermission([
      'view_self_request',
      'create_self_request',
      'update_self_request',
      'delete_self_request',
      'view_self_payroll_close',
      'view_self_extra_month',
      'view_self_employee_log',
      'create_self_employee_log',
    ]);
  }

  navigateToHome() {
    // no auth
    if (!this.authService.isAuthenticated()) {
      this.router.navigate(['/login']);
      return;
    }
    // central
    if (!this.authService.isTenant()) {
      this.router.navigate(['/central/dashboard']);
      return;
    }
    // can change modules
    if (this.canChangeBetweenModules) {
      this.router.navigate(['/customer/home']);
      return;
    }
    // is employee
    if (this.isSelfManagement()) {
      this.router.navigate(['/employee/home']);
      return;
    }
    // navigation default to avoid error
    this.router.navigate(['/customer/home']);
  }
}
