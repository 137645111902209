import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RoleGuard  {
  // constructor(private auth: AuthService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    // TODO: validate that has expected Role
    return true;

    //  // this will be passed from the route config
    // // on the data property
    // const expectedRole = route.data.expectedRole;
    // if (this.auth.hasRoles(['admin'])) {
    //   return true;
    // } else if (this.auth.hasRoles([expectedRole])) {
    //   return true;
    // } else {
    //   this.router.navigate(['/auth/login']);
    //   return false;
    // }
  }
}
