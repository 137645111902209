import { AuthStoreModule } from './auth';
import { CompanyStoreModule } from './company';
import { PayrollCloseStoreModule } from './payrollClose';
import { SessionStoreModule } from './session';

export const AppStoreModules = [
  SessionStoreModule,
  AuthStoreModule,
  CompanyStoreModule,
  PayrollCloseStoreModule,
];
