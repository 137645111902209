import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, repeat, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Company, Profile } from '@app/core/models';
import {
  AuthenticationService,
  PasswordService,
  PermissionHelperService,
  ProfileService,
} from '@app/core/services';
import { SessionActions } from './session.actions';
import { ItemsMenu as CentralItemsMenu } from '@app/central/data';
import { ItemsMenu as TenantItemsMenu } from '@app/tenant/data';
import { ItemsMenu as SelfItemsMenu } from '@app/self-management/data';
import { ErrorRepository } from '@app/shared/repositories';
import { LocalStorageService } from '@app/shared/services';

@Injectable()
export class SessionEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly profileService: ProfileService,
    private readonly authService: AuthenticationService,
    private readonly permissionHelperService: PermissionHelperService,
    private readonly localStorageService: LocalStorageService
  ) {}

  getProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SessionActions.getProfile.type),
      switchMap(() => {
        if (
          [null, undefined].includes(this.localStorageService.getCurrentUser())
        ) {
          return of(new Profile());
        }
        return this.profileService.getProfile();
      }),
      map((profile: Profile) => SessionActions.getProfileSuccess({ profile }))
    )
  );

  getCompaniesToHandle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SessionActions.getUserCompaniesToHandle.type),
      switchMap(() => {
        if (
          [null, undefined].includes(
            this.localStorageService.getCurrentUser()
          ) ||
          !this.authService.isTenant()
        ) {
          return of([]);
        }
        return this.profileService.getCompaniesToHandle(
          this.localStorageService.getCurrentUser().id
        );
      }),
      map((companies: Company[]) =>
        SessionActions.getUserCompaniesToHandleSuccess({ companies })
      )
    )
  );
  updatePreferences$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SessionActions.updatePreferences.type),
      switchMap(({ preferences }) =>
        this.profileService.updateUserPreferences(
          this.localStorageService.getCurrentUser().id,
          preferences
        )
      ),
      map((preferences) =>
        SessionActions.updatePreferencesSuccess({ preferences })
      )
    )
  );
  changeOfCompany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SessionActions.selectACompany.type),
      map((selectedCompany: Company) =>
        SessionActions.selectACompanySuccess({ selectedCompany })
      )
    )
  );

  getItemsMenu$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SessionActions.getMenuItems.type),
      switchMap(({ module }) => of({ menuItems: this.getMenuItems(module) })),
      map((menuItems) => SessionActions.getMenuItemsSuccess(menuItems))
    )
  );

  updateUserIdentity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SessionActions.updateUserIdentity.type),
      switchMap(({ id, name, surname, lastname }) =>
        this.profileService.updateUser(id, { name, surname, lastname })
      ),
      map((response) => response.data),
      tap(({ name, surname, lastname }) => {
        const user = this.localStorageService.getCurrentUser();
        user.name = name;
        user.surname = surname;
        user.lastname = lastname;
        this.localStorageService.setCurrentUser(user);
      }),
      map(({ name, surname, lastname }) =>
        SessionActions.updateUserIdentitySuccess({ name, surname, lastname })
      )
    )
  );
  updatePassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SessionActions.updatePassword.type),
      switchMap(({ id, password, passwordConfirmation }) =>
        this.profileService.updatePassword(id, password, passwordConfirmation)
      ),
      map(() => SessionActions.updatePasswordSuccess()),
      catchError((payload) =>
        of(
          SessionActions.catchErrorFromApi({
            payload:
              ErrorRepository.extractErrorMessagesFromErrorResponse(
                payload
              ).join('\n'),
          })
        )
      ),
      repeat()
    )
  );

  getMenuItems(module: string): any[] {
    let items;
    switch (module) {
      case 'tenant':
        items = [...TenantItemsMenu];
        break;
      case 'self-management':
        items = [...SelfItemsMenu];
        break;
      default:
        items = [...CentralItemsMenu];
        break;
    }
    return this.removeItemsThatNotHavePermission()(items);
  }

  removeItemsThatNotHavePermission = () =>
    this.filterDeep((x) => {
      if (x.permission) {
        return this.permissionHelperService.hasPermission(x.permission);
      } else {
        return true;
      }
    });

  // https://stackoverflow.com/a/61659808
  filterDeep = (pred) => (xs) =>
    xs.flatMap((x) =>
      pred(x)
        ? [
            {
              ...x,
              ...(x.items
                ? { items: this.filterDeep(pred)(x.items || []) }
                : {}),
            },
          ]
        : []
    );
}
