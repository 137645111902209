import { ScheduleValuePipe } from './pipes/schedule-value.pipe';
import { IssuesValuePipe } from './pipes/issues-value.pipe';
import { EventTypeValuePipe } from './pipes/event-type-value.pipe';
import { TranslateModule } from '@ngx-translate/core';
import {
  LazyImgDirective,
  DisableIfUnauthorizeDirective,
  HideIfUnauthorizeDirective,
} from '@shared/directives';
import { CoreModule } from '@core/core.module';
import { AppInjector } from './app-injector.service';
import { Injector, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedRoutingModule } from './shared-routing.module';

import {
  DataGridComponent,
  DynamicFormComponent,
  ToastComponent,
  EditorManualItemComponent,
  EmployeePayrollDetailComponent,
  EmployeeNameComponent,
  MimeIconComponent,
  RoundendStatusIconComponent,
  IconEventTypeComponent,
  DocumentViewerComponent,
  EventItemsAttachedComponent,
  ExtraMonthEmployeeDetailComponent,
  PricingFaqsComponent,
  CumulativeTimeEditorComponent,
  CommonStatusComponent,
} from '@shared/components';
import { ConfirmComponent } from './components/confirm/confirm.component';
import { HumanizePipe } from './pipes/humanize.pipe';
import { MinutesToHoursPipe } from './pipes/minutes-to-hours.pipe';
import { DgDropdownValuePipe } from './pipes/dg-dropdown-value.pipe';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { ViewDateRangesPipe } from './pipes/view-date-ranges.pipe';
import { PayrollValuePipe } from './pipes/payroll-value.pipe';
import { EventStatusValuePipe } from './pipes/event-status-value.pipe';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { CustomDatePipe } from './pipes/custom-date.pipe';
import { IssueFulltextPipe } from './pipes/issue-fulltext.pipe';

@NgModule({
  declarations: [
    DataGridComponent,
    DynamicFormComponent,
    ToastComponent,
    LazyImgDirective,
    ConfirmComponent,
    HumanizePipe,
    MinutesToHoursPipe,
    DgDropdownValuePipe,
    DisableIfUnauthorizeDirective,
    HideIfUnauthorizeDirective,
    ChangePasswordComponent,
    ViewDateRangesPipe,
    PayrollValuePipe,
    EditorManualItemComponent,
    EmployeePayrollDetailComponent,
    EmployeeNameComponent,
    EventStatusValuePipe,
    EventTypeValuePipe,
    IssuesValuePipe,
    ScheduleValuePipe,
    MimeIconComponent,
    DocumentViewerComponent,
    RoundendStatusIconComponent,
    IconEventTypeComponent,
    CustomDatePipe,
    EventItemsAttachedComponent,
    ExtraMonthEmployeeDetailComponent,
    PricingFaqsComponent,
    CumulativeTimeEditorComponent,
    IssueFulltextPipe,
    CommonStatusComponent,
  ],
  imports: [
    CommonModule,
    SharedRoutingModule,
    CoreModule,

    NgxDocViewerModule,
    TranslateModule.forChild({ extend: true }),
  ],
  providers: [AppInjector],
  exports: [
    DataGridComponent,
    DynamicFormComponent,
    ToastComponent,
    LazyImgDirective,
    ConfirmComponent,
    HumanizePipe,
    MinutesToHoursPipe,
    DgDropdownValuePipe,
    DisableIfUnauthorizeDirective,
    HideIfUnauthorizeDirective,
    ChangePasswordComponent,
    ViewDateRangesPipe,
    PayrollValuePipe,
    EditorManualItemComponent,
    EmployeePayrollDetailComponent,
    EmployeeNameComponent,
    EventStatusValuePipe,
    EventTypeValuePipe,
    IssuesValuePipe,
    ScheduleValuePipe,
    MimeIconComponent,
    DocumentViewerComponent,
    RoundendStatusIconComponent,
    IconEventTypeComponent,
    CustomDatePipe,
    EventItemsAttachedComponent,
    ExtraMonthEmployeeDetailComponent,
    PricingFaqsComponent,
    CumulativeTimeEditorComponent,
    IssueFulltextPipe,
    CommonStatusComponent,
  ],
})
export class SharedModule {
  constructor(injector: Injector) {
    AppInjector.injector = injector;
  }
}
