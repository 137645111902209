import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { LangService } from '../services';

@Pipe({
  name: 'customDate',
})
export class CustomDatePipe implements PipeTransform {
  constructor(private langService: LangService) {}
  transform(value: string, ...args: string[]): string {
    if ([null, undefined, ''].includes(value)) {
      return '';
    }
    return moment(value)
      .locale(this.langService.getLang())
      .format(
        [null, undefined, ''].includes(args[0])
          ? 'YYYY-MM-DD HH:mm:ss'
          : args[0]
      );
  }
}
