import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map, repeat, switchMap, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { Router } from '@angular/router';
import { AuthenticationService, PasswordService } from '@app/core/services';
import { environment } from '@environments/environment';
import { ErrorRepository } from '@app/shared/repositories';
import { AuthActions } from './auth.actions';
import { SessionActions, SessionState } from '../session';
import { LocalStorageService } from '@app/shared/services';

@Injectable()
export class AuthEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly http: HttpClient,
    private readonly authService: AuthenticationService,
    private readonly passwordService: PasswordService,
    private readonly localStorageService: LocalStorageService,
    private store: Store<SessionState>,
    private router: Router
  ) {}

  doLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.login.type),

      switchMap(({ username, password, tenant }) => {
        this.localStorageService.clear({ except: ['tenantId'] });
        return this.http
          .post<any>(
            `${this.buildBaseUrl(tenant)}/auth/login`,
            { username, password, platform: 'web' },
            {
              headers: new HttpHeaders({
                'X-Localization': navigator.language,
              }),
            }
          )
          .pipe(
            map((response) => response.data),
            tap((data) =>
              this.updateAuthVarsLocalStorage(
                data.accessToken,
                data.refreshToken,
                data.profile
              )
            )
          );
      }),
      map(({ accessToken, refreshToken, profile }) => {
        this.store.dispatch(SessionActions.getProfile());
        this.store.dispatch(SessionActions.getUserCompaniesToHandle());
        return AuthActions.loginSuccess({
          accessToken,
          refreshToken,
          profile,
        });
      }),
      catchError((payload) => {
        this.localStorageService.clear({});
        return of(
          AuthActions.loginError({
            payload:
              ErrorRepository.extractErrorMessagesFromErrorResponse(
                payload
              ).join('\n'),
          })
        );
      }),
      repeat()
    )
  );

  doLogout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.logout.type),
      switchMap(() =>
        this.http
          .post<any>(
            `${this.buildBaseUrl(this.authService.isTenant())}/auth/logout`,
            null
          )
          .pipe(
            tap(() => {
              this.localStorageService.clear({});
              this.store.dispatch(SessionActions.cleanSessionStore());
              this.router.navigate(['/login']);
            })
          )
      ),
      map(() => AuthActions.logoutSuccess()),
      catchError((payload) => {
        this.localStorageService.clear({});
        this.store.dispatch(SessionActions.cleanSessionStore());
        this.router.navigate(['/login']);
        return of(SessionActions.catchErrorFromApi({ payload }));
      }),
      repeat()
    )
  );

  doRefreshToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.refreshToken.type),
      switchMap(() =>
        this.http
          .post<any>(
            `${this.buildBaseUrl(this.authService.isTenant())}/auth/refresh`,
            { refresh_token: this.localStorageService.getRefreshToken() },
            {
              headers: new HttpHeaders({
                'X-Localization': navigator.language,
              }),
            }
          )
          .pipe(
            map((response) => response.data),
            tap((data) =>
              this.updateAuthVarsLocalStorage(
                data.accessToken,
                data.refreshToken,
                data.profile
              )
            )
          )
      ),
      map(() => AuthActions.refreshTokenSuccess()),
      catchError((payload) =>
        of(
          AuthActions.refreshTokenError({
            payload:
              ErrorRepository.extractErrorMessagesFromErrorResponse(
                payload
              ).join('\n'),
          })
        )
      ),
      repeat()
    )
  );

  findTenant$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.findTenant.type),
      switchMap(({ customerId }) =>
        this.http
          .post<any>(
            `${environment.apiUrl}/tenant/auth/search-customer-id`,
            {
              customerId,
            },
            {
              headers: new HttpHeaders({
                'X-Localization': navigator.language,
              }),
            }
          )
          .pipe(
            tap((response) => {
              this.localStorageService.setCustomerId(customerId);
              this.localStorageService.setTenantId(response.data.tenantId);
            }),
            map((response) => response.data.tenantId)
          )
      ),
      map((customerId) => AuthActions.findTenantSuccess(customerId)),
      catchError((payload) => {
        this.localStorageService.clear({});
        return of(
          AuthActions.findTenantError({
            payload:
              ErrorRepository.extractErrorMessagesFromErrorResponse(
                payload
              ).join('\n'),
          })
        );
      }),
      repeat()
    )
  );

  restorePasswordWithToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.restorePassword.type),
      switchMap(({ email, password, passwordConfirmation, token }) =>
        this.passwordService.reset(email, password, passwordConfirmation, token)
      ),
      map((response) => response.data.customerId),
      map((customerId) => {
        this.localStorageService.setCustomerId(customerId);
        return AuthActions.restorePasswordSuccess({ customerId });
      }),
      catchError((payload) =>
        of(
          AuthActions.catchErrorFromApi({
            payload:
              ErrorRepository.extractErrorMessagesFromErrorResponse(
                payload
              ).join('\n'),
          })
        )
      ),
      repeat()
    )
  );

  private buildBaseUrl(tenant: boolean = false): string {
    return `${environment.apiUrl}/${tenant === true ? 'tenant' : 'central'}`;
  }

  private updateAuthVarsLocalStorage(
    accessToken: string,
    refreshToken: string,
    profile: any = undefined
  ) {
    this.localStorageService.setAccessToken(accessToken);
    this.localStorageService.setRefreshToken(refreshToken);
    this.localStorageService.setCurrentUser(profile);
  }
}
