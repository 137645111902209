import { ServiceDataStorage } from '@shared/helpers';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class RolesService extends ServiceDataStorage {
  constructor() {
    super('roles');
  }
}
