import { HttpService } from '@shared/services';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class EmployeeCalculationsService {
  public calculationRowUpdated: Observable<any>;
  private calculationRowUpdatedSubject: BehaviorSubject<number>;

  constructor(private http: HttpService) {
    this.calculationRowUpdatedSubject = new BehaviorSubject<number>(0);
    this.calculationRowUpdated = this.calculationRowUpdatedSubject;
  }
  /**
   * Get employee's calculations by payrollClose
   * @param employeeId employee id
   * @param payrollCloseId payrollClose id
   * @returns observable
   */
  byPayrollClose(employeeId: number, payrollCloseId: number) {
    return this.http.get(
      `employees/${employeeId}/payroll-closes/${payrollCloseId}/calculations`
    );
  }

  getAttendance(
    payrollId: number,
    employeeId: number,
    start: string,
    end: string
  ) {
    return this.http.get(
      `attendance?payroll_id=${payrollId}&employee_id=${employeeId}&start=${start}&end=${end}`
    );
  }
  /**
   * Update a calculation row only in autorization
   * @param employeeId employee id
   * @param rowId row id
   * @param location in | out | row
   * @param minutesToAutorize minutes to autorize
   * @param note note of autorization
   * @returns observable
   */
  updateCalculationRow(
    employeeId: number,
    rowId: number,
    location: string,
    minutesToAutorize: number,
    note: string,
    detailId: number = 0
  ) {
    return this.http
      .put(`employees/${employeeId}/calculations/${rowId}`, {
        location,
        minutesToAutorize,
        note,
      })
      .pipe(
        map((response) => {
          // notify that row updated was finished
          this.calculationRowUpdatedSubject.next(detailId);
          return response.data;
        })
      );
  }
}
