import { SharedModule } from '@shared/shared.module';
import { CoreModule } from '@core/core.module';
import { RouterModule, Router } from '@angular/router';
import { CommonModule, registerLocaleData } from '@angular/common';
import {
  NgModule,
  LOCALE_ID,
  ErrorHandler,
  APP_INITIALIZER,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {
  AppComponent as MirageAppComponent,
  AppMainComponent,
  AppMenuComponent,
  AppMenuitemComponent,
  AppTopBarComponent,
  AppFooterComponent,
  AppRightPanelComponent,
  AppConfigComponent,
  AppBreadcrumbComponent,
} from '@app/core/mirage';

import localeEsCR from '@angular/common/locales/es-CR';
registerLocaleData(localeEsCR, 'es-CR');

import { ExportAsModule } from 'ngx-export-as';

import {
  AngularFireAnalyticsModule,
  ScreenTrackingService,
  UserTrackingService,
} from '@angular/fire/compat/analytics';
import { AngularFireModule } from '@angular/fire/compat';
import { environment } from '@environments/environment';

import {
  MissingTranslationHandler,
  TranslateLoader,
  TranslateModule,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MyMissingTranslationHandler } from '@shared/handlers';
import * as Sentry from '@sentry/angular';
import { MonacoEditorModule } from 'ngx-monaco-editor';
import { GoogleMapsModule } from '@angular/google-maps';

// ngrx
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { AppStoreModules } from './store';

@NgModule({
  declarations: [
    AppComponent,
    MirageAppComponent,
    AppMainComponent,
    AppMenuComponent,
    AppMenuitemComponent,
    AppTopBarComponent,
    AppFooterComponent,
    AppRightPanelComponent,
    AppConfigComponent,
    AppBreadcrumbComponent,
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    RouterModule,
    AppRoutingModule,
    CoreModule,
    SharedModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAnalyticsModule,
    TranslateModule.forRoot({
      defaultLanguage: 'es',
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient],
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: MyMissingTranslationHandler,
      },
    }),
    MonacoEditorModule.forRoot(),
    ExportAsModule,
    GoogleMapsModule,
    StoreModule.forRoot({}, {}),
    EffectsModule.forRoot([]),
    environment.imports,
    AppStoreModules,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'es-CR' },
    ScreenTrackingService,
    UserTrackingService,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: true,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [MirageAppComponent],
})
export class AppModule {}
// AOT compilation support
export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
