import { SessionState } from '@app/store/session/session.state';
import { ThemeService } from './../services/theme.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { AppComponent } from './app.component';
import { AppMainComponent } from './app.main.component';
import { AppConfig } from '../domain/appconfig';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { SessionActions, selectUiPreferences } from '@app/store/session';
import { Langs } from '@app/shared/data';
import { LangService } from '@app/shared/services';
@Component({
  selector: 'app-config',
  templateUrl: 'app.config.component.html',
  animations: [
    trigger('children', [
      state(
        'hiddenAnimated',
        style({
          opacity: 0,
          transform: ' translateX(-50%) translateY(-50%)',
        })
      ),
      state(
        'visibleAnimated',
        style({
          opacity: 1,
          transform: 'translateX(-50%) translateY(-50%) scale(1)',
        })
      ),
      transition(
        'visibleAnimated => hiddenAnimated',
        animate('150ms cubic-bezier(0, 0, 0.2, 1)')
      ),
      transition(
        'hiddenAnimated => visibleAnimated',
        animate('150ms cubic-bezier(0, 0, 0.2, 1)')
      ),
    ]),
  ],
})
export class AppConfigComponent implements OnInit, OnDestroy {
  darkColors: any;

  lightColors: any;

  customColors: any;

  menuColors: any;

  selectedColorOptions: any;

  componentThemes: any;

  subscription: Subscription = new Subscription();

  languages = Langs;

  selectedLang: string = 'es';

  constructor(
    public app: AppComponent,
    public appMain: AppMainComponent,
    private themeService: ThemeService,
    private store: Store<SessionState>,
    private langService: LangService
  ) {
    this.darkColors = this.themeService.darkColors;
    this.lightColors = this.themeService.lightColors;
    this.customColors = this.themeService.customColors;
    this.menuColors = this.themeService.menuColors;
    this.selectedColorOptions = this.themeService.selectedColorOptions;
    this.componentThemes = this.themeService.componentThemes;
    this.selectedLang = this.langService.getLang();
  }

  ngOnInit() {}
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  changeLayout(event, mode) {
    this.app.darkMode = mode;

    if (mode === true) {
      this.app.menuColorMode = 'dark';
      this.app.menuColor = 'layout-menu-dark';
      this.selectedColorOptions = this.darkColors;
      this.app.layoutColor = this.selectedColorOptions[0].file;
      this.changeLightDarkLayout(
        'layout-css',
        this.selectedColorOptions[0].file,
        'layout-dark'
      );
      this.changeLightDarkTheme('theme-css', 'theme-dark');
    } else {
      this.app.menuColorMode = 'light';
      this.app.menuColor = 'layout-menu-light';
      this.selectedColorOptions = this.lightColors;
      this.app.layoutColor = this.selectedColorOptions[0].file;
      this.changeLightDarkLayout(
        'layout-css',
        this.selectedColorOptions[0].file,
        'layout-light'
      );
      this.changeLightDarkTheme('theme-css', 'theme-light');
    }
    event.preventDefault();
  }

  changeLightDarkTheme(id, value) {
    const element = document.getElementById(id);
    const urlTokens = element.getAttribute('href').split('/');
    urlTokens[urlTokens.length - 1] = value + '.css';
    const newURL = urlTokens.join('/');
    this.replaceLink(element, newURL);
  }

  changeLightDarkLayout(id, color, mode) {
    const element = document.getElementById(id);
    const urlTokens = element.getAttribute('href').split('/');
    urlTokens[urlTokens.length - 2] = color;
    urlTokens[urlTokens.length - 1] = mode + '.css';
    const newURL = urlTokens.join('/');
    this.replaceLink(element, newURL);
  }

  changeMenuToHorizontal(event, mode) {
    this.app.horizontalMenu = mode;
    event.preventDefault();
  }

  changeMenuColor(event, mode) {
    this.app.menuColorMode = mode;
    if (mode !== 'custom') {
      this.app.menuColor = 'layout-menu-' + mode;
      if (mode === 'dark') {
        this.selectedColorOptions = this.darkColors;
        this.app.layoutColor = this.selectedColorOptions[0].file;
        this.changeStyleSheetsColor(
          'layout-css',
          this.selectedColorOptions[0].file
        );
      } else {
        this.selectedColorOptions = this.lightColors;
        this.app.layoutColor = this.selectedColorOptions[0].file;
        this.changeStyleSheetsColor(
          'layout-css',
          this.selectedColorOptions[0].file
        );
      }
    } else {
      this.app.menuColor = 'layout-menu-' + this.customColors[0].file;
      this.selectedColorOptions = this.customColors;
    }
    event.preventDefault();
  }

  changeMenuTheme(event, color) {
    if (this.app.menuColorMode !== 'custom') {
      this.changeStyleSheetsColor('layout-css', color);
      this.app.layoutColor = color;
    } else {
      this.app.menuColor = 'layout-menu-' + color;
    }
    event.preventDefault();
  }

  changeComponentTheme(event, color) {
    this.app.themeColor = color;
    this.changeStyleSheetsColor('theme-css', color);
    event.preventDefault();
  }

  changeStyleSheetsColor(id, value) {
    const element = document.getElementById(id);
    const urlTokens = element.getAttribute('href').split('/');
    urlTokens[urlTokens.length - 2] = value;
    const newURL = urlTokens.join('/');
    this.replaceLink(element, newURL);
  }

  onConfigButtonClick(event) {
    this.appMain.configActive = true;
    event.preventDefault();
  }

  onConfigCloseClick(event) {
    const preferences: AppConfig = {
      inputStyle: this.app.inputStyle,
      darkMode: this.app.darkMode,
      themeColor: this.app.themeColor,
      ripple: this.app.ripple,
      horizontalMenu: this.app.horizontalMenu,
      menuColorMode: this.app.menuColorMode,
      menuColor: this.app.menuColor,
      layoutColor: this.app.layoutColor,
      lang: this.selectedLang,
    };

    this.store.dispatch(SessionActions.updatePreferences({ preferences }));
    this.appMain.configActive = false;
    event.preventDefault();
  }

  replaceLink(linkElement, href) {
    if (this.isIE()) {
      linkElement.setAttribute('href', href);
    } else {
      const id = linkElement.getAttribute('id');
      const cloneLinkElement = linkElement.cloneNode(true);
      cloneLinkElement.setAttribute('href', href);
      cloneLinkElement.setAttribute('id', id + '-clone');
      linkElement.parentNode.insertBefore(
        cloneLinkElement,
        linkElement.nextSibling
      );
      cloneLinkElement.addEventListener('load', () => {
        linkElement.remove();
        cloneLinkElement.setAttribute('id', id);
      });
    }
  }

  isIE() {
    return /(MSIE|Trident\/|Edge\/)/i.test(window.navigator.userAgent);
  }

  onLangChanged(e: any) {
    this.langService.setLang(this.selectedLang);
  }
}
