<div class="login-body layout-light">
  <div class="login-content">
    <div class="login-panel">
      <div class="login-panel-content">
        <div class="logo">
          <img
            src="assets/layout/images/logo-mirage@2x.png"
            alt="mirage-layout"
          />
        </div>
        <h1>{{ "core.restorePassword.title" | translate }}</h1>
        <p>{{ "core.restorePassword.instruction" | translate }}</p>
        <form class="forms" [formGroup]="frm" (ngSubmit)="onSubmit()">
          <!-- email -->
          <div class="login-input-wrapper">
            <label class="is-required" for="email">{{
              "core.restorePassword.emailLabel" | translate
            }}</label>
            <input
              pInputText
              id="email"
              type="email"
              class="form-control"
              [ngClass]="{
                'is-invalid':
                  !frm.get('email').valid && frm.get('email').touched
              }"
              formControlName="email"
              placeholder="{{
                'core.restorePassword.emailPlaceholder' | translate
              }}"
            />
            <i class="pi pi-user"></i>
          </div>
          <!-- password -->
          <div class="login-input-wrapper">
            <label class="is-required" for="password">{{
              "core.restorePassword.passwordLabel" | translate
            }}</label>
            <input
              pInputText
              id="password"
              type="password"
              class="form-control"
              [ngClass]="{
                'is-invalid':
                  !frm.get('password').valid && frm.get('password').touched
              }"
              formControlName="password"
              placeholder="{{
                'core.restorePassword.passwordPlaceholder' | translate
              }}"
            />
            <i class="pi pi-key"></i>
          </div>
          <!-- password confirmation -->
          <div class="login-input-wrapper">
            <label class="is-required" for="passwordConfirmation">{{
              "core.restorePassword.passwordConfirmationLabel" | translate
            }}</label>
            <input
              pInputText
              id="passwordConfirmation"
              type="password"
              class="form-control"
              [ngClass]="{
                'is-invalid':
                  !frm.get('passwordConfirmation').valid &&
                  frm.get('passwordConfirmation').touched
              }"
              formControlName="passwordConfirmation"
              placeholder="{{
                'core.restorePassword.passwordConfirmationPlaceholder'
                  | translate
              }}"
            />
            <i class="pi pi-key"></i>
          </div>
          @if (error$ | async) {
          <p-message severity="error" text="{{ error$ | async }}"></p-message>
          }
          <p-tag severity="info">
            <ul>
              <li>
                {{ "core.restorePassword.passwordRequirement1" | translate }}
              </li>
              <li>
                {{ "core.restorePassword.passwordRequirement2" | translate }}
              </li>
              <li>
                {{ "core.restorePassword.passwordRequirement3" | translate }}
              </li>
              <li>
                {{ "core.restorePassword.passwordRequirement4" | translate }}
              </li>
            </ul>
          </p-tag>

          <div class="buttons">
            <!-- login button -->
            <button
              pButton
              type="submit"
              [disabled]="!frm.valid"
              [loading]="loading$ | async"
              icon="pi pi-check"
              label="{{ 'core.restorePassword.submitButton' | translate }}"
              [appEventTracker]="{ action: 'restore_password' }"
            ></button>
          </div>
        </form>
      </div>
    </div>

    <!-- <div class="desert"></div> -->
  </div>
</div>
