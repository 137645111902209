export const TypesShift = [
  { label: 'tenant.schedulesTypes.diurnal', value: 'diurnal' },
  { label: 'tenant.schedulesTypes.mixed', value: 'mixed' },
  { label: 'tenant.schedulesTypes.nocturnal', value: 'nocturnal' },
];
export const TypesSchedule = [
  { label: 'tenant.schedulesTypes.normal', value: 'normal' },
  { label: 'tenant.schedulesTypes.open', value: 'open' },
];
export const DaysOut = [
  { label: 'tenant.schedulesTypes.sameDay', value: 0 },
  { label: 'tenant.schedulesTypes.nextDay', value: 1 },
  { label: 'tenant.schedulesTypes.thirdDay', value: 2 },
];
