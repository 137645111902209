import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { ErrorInterceptorService } from './error-interceptor.service';
import { JwtInterceptorService } from './jwt-interceptor.service';
import { LanguageInterceptorService } from './language-interceptor.service';
import { TenantInterceptorService } from './tenant-interceptor.service';
// import { RequestTimeStampService } from './request-time-stamp.service';

export const InterceptorProviders = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: ErrorInterceptorService,
    multi: true,
  },
  { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptorService, multi: true },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: TenantInterceptorService,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: LanguageInterceptorService,
    multi: true,
  },
  // {
  //   provide: HTTP_INTERCEPTORS,
  //   useClass: RequestTimeStampService,
  //   multi: true,
  // },
];
