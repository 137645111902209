import { HttpService } from '@shared/services';
import { Injectable } from '@angular/core';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AttendanceService {
  constructor(private http: HttpService) {}
  /**
   * get logs of employees raw
   * @param companyId company id
   * @param start to date
   * @param end from date
   * @returns observable
   */
  getConventionalCardByCompany(companyId: number, start: string, end: string) {
    return this.http
      .get(`companies/${companyId}/conventional-card?start=${start}&end=${end}`)
      .pipe(
        filter((x) => x.data !== null || x.data !== undefined),
        map((response) => response.data)
      );
  }

  calculatePayroll(payrollId: number, start: string, end: string) {
    return this.http.post(`attendance/calculation`, {
      payrollId,
      start,
      end,
    });
  }
  calculatePayrollEmployee(
    payrollId: number,
    employeeId: number,
    start: string,
    end: string
  ) {
    return this.http.post(`attendance/calculation`, {
      payrollId,
      employeeId,
      start,
      end,
    });
  }
  logsWithPosition(employeeId: number, start: string, end: string) {
    return this.http
      .get(
        `employees/${employeeId}/logs-with-position?start=${start}&end=${end}`
      )
      .pipe(
        filter((x) => x.data !== null || x.data !== undefined),
        map((response) => response.data)
      )
      .toPromise();
  }
}
