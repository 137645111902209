import { Observable } from 'rxjs';
import { HttpService } from '@shared/services';
import { Injectable } from '@angular/core';
import { map, filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ExtraMonthService {
  constructor(private http: HttpService) {}

  private transform(data: Observable<any>) {
    return data
      .pipe(
        filter((res) => [null, undefined].indexOf(res) === -1),
        map((res) => res.data)
      )
      .toPromise();
  }

  getExtraMonthImports(start: string, end: string) {
    return this.transform(
      this.http.get(`extra-month-imports?start=${start}&end=${end}`)
    );
  }

  uploadExtraMonthImport(payrollId: number, file: any) {
    const form = new FormData();
    form.append('file', file);
    form.append('_method', 'POST');
    return this.http.post('imports/extra-month-imports', form).toPromise();
  }

  deleteExtraMonthImport(id: number) {
    return this.transform(this.http.delete(`extra-month-imports/${id}`));
  }

  getExtraMonths(status: string, year: number = null) {
    return this.transform(
      this.http.get(
        `extra-months?status=${status}${year !== null ? `&year=${year}` : ''} `
      )
    );
  }

  createExtraMonth(data: any) {
    return this.transform(this.http.post(`extra-months`, data));
  }
  updateExtraMonth(data: any) {
    return this.transform(this.http.put(`extra-months/${data.id}`, data));
  }

  saveExtraMonth(data: any) {
    return [null, undefined].includes(data.id)
      ? this.createExtraMonth(data)
      : this.updateExtraMonth(data);
  }

  deleteExtraMonth(id: number) {
    return this.transform(this.http.delete(`extra-months/${id}`));
  }

  getExtraMonthSources(payrolls: Array<any>) {
    return this.transform(
      this.http.get(
        `extra-months/sources?${payrolls
          .map(function (el, idx) {
            return 'payrolls[' + idx + ']=' + el;
          })
          .join('&')}`
      )
    );
  }

  resendVoucher(extraMonthId: number, employeeId: number) {
    return this.transform(
      this.http.post(
        `extra-months/${extraMonthId}/employees/${employeeId}/resend-voucher`,
        null
      )
    );
  }
}
