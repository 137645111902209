import { AppConfig } from '@app/core/domain/appconfig';
import { Company, Profile } from '@app/core/models';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const SessionActions = createActionGroup({
  source: 'Session',
  events: {
    'Get profile': emptyProps(),
    'Get profile success': props<{ profile: Profile }>(),
    'Get user companies to handle': emptyProps(),
    'Get user companies to handle success': props<{ companies: Company[] }>(),
    'Select a company': props<{ company: Company }>(),
    'Select a company success': props<{ selectedCompany: Company }>(),
    'Update preferences': props<{ preferences: AppConfig }>(),
    'Update preferences success': props<{ preferences: AppConfig }>(),
    'Clean session store': emptyProps(),
    'Update password': props<{
      id: string;
      password: string;
      passwordConfirmation: string;
    }>(),
    'Update password success': emptyProps(),
    'Catch error from api': props<{ payload: any }>(),
    'Get menu items': props<{ module: string }>(),
    'Get menu items success': props<{ menuItems: any[] }>(),
    'Update user identity': props<{
      id: number;
      name: string;
      surname: string;
      lastname: string;
    }>(),
    'Update user identity success': props<{
      name: string;
      surname: string;
      lastname: string;
    }>(),
  },
});
