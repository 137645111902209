<h2>{{ "shared.pricingFaqs.title" | translate }}</h2>
<p-accordion>
  <p-accordionTab header="{{ 'shared.pricingFaqs.q1.header' | translate }}">
    <p class="text-justify">
      {{ "shared.pricingFaqs.q1.content1" | translate }}
    </p>
    <p class="text-justify">
      {{ "shared.pricingFaqs.q1.content2" | translate }}
    </p>
  </p-accordionTab>
  <p-accordionTab header="{{ 'shared.pricingFaqs.q2.header' | translate }}">
    <p class="text-justify">
      {{ "shared.pricingFaqs.q2.content1" | translate }}
    </p>
    <p class="text-justify">
      {{ "shared.pricingFaqs.q2.content2" | translate }}
    </p>
  </p-accordionTab>
  <p-accordionTab header="{{ 'shared.pricingFaqs.q3.header' | translate }}">
    <p class="text-justify">
      {{ "shared.pricingFaqs.q3.content" | translate }}
    </p>
  </p-accordionTab>
  <p-accordionTab header="{{ 'shared.pricingFaqs.q4.header' | translate }}">
    <p class="text-justify">
      {{ "shared.pricingFaqs.q4.content" | translate }}
    </p>
  </p-accordionTab>
  <p-accordionTab header="{{ 'shared.pricingFaqs.q5.header' | translate }}">
    <p class="text-justify">
      {{ "shared.pricingFaqs.q5.content" | translate }}
    </p>
  </p-accordionTab>
</p-accordion>
