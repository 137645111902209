export class TimeCalculationRepository {
  /**
   * convert minutes to hours and minutes
   * @param value minutes
   * @returns string like 00:00
   */
  public static minutesToHours(
    value: number,
    format: FormatType = FormatType.colon
  ): string {
    if (!value) {
      return this.formatTemplate(0, 0, format);
    }

    let hours = value / 60;
    let rhours = Math.floor(hours);
    let minutes = (hours - rhours) * 60;
    let rminutes = Math.round(minutes);

    return this.formatTemplate(rhours, rminutes, format);
  }

  public static onlyHours(minutes: number): number {
    return Math.floor(minutes / 60);
  }
  public static onlyMinutes(value: number): number {
    let hours = value / 60;
    let rhours = Math.floor(hours);
    return Math.round((hours - rhours) * 60);
  }

  private static formatTemplate(
    hours: number,
    minutes: number,
    format: FormatType
  ): string {
    switch (format) {
      case FormatType.narrow:
        if (hours + minutes === 0) {
          return '0h';
        }
        return `${hours > 0 ? `${hours}h ` : ''}${
          minutes > 0 ? `${minutes}m` : ''
        }`;
      default:
        return `${hours.toString().padStart(2, '0')}:${minutes
          .toString()
          .padStart(2, '0')}`;
    }
  }
}

export enum FormatType {
  colon = 'colon',
  narrow = 'narrow',
}
