export class Company {
  constructor(
    public id: number,
    public name: string,
    public email: string,
    public phone: string,
    public address: string,
    public countEmployees: number,
    public logoUrl: string,
    public createdAt: Date,
    public updatedAt: Date
  ) {}
}
