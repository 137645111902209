import { Injectable } from '@angular/core';
import { LocalStorageService } from './local-storage.service';
import { TranslateService } from '@ngx-translate/core';
import { Langs } from '../data';

@Injectable({
  providedIn: 'root',
})
export class LangService {
  constructor(
    private localStorageService: LocalStorageService,
    private translateService: TranslateService
  ) {}
  init() {
    this.translateService.addLangs(Langs.map((x) => x.value));
    this.translateService.use(this.localStorageService.getLang());
  }
  getLang(): string {
    return this.localStorageService.getLang();
  }
  setLang(lang: string) {
    if ([null, undefined, ''].includes(lang)) {
      return;
    }
    this.localStorageService.setLang(lang);
    this.translateService.use(lang);
  }
}
