import { ServiceDataStorage } from '@shared/helpers/ServiceDataStorage';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ScheduleBreaksService extends ServiceDataStorage {
  constructor() {
    super('schedules', 'schedule-breaks');
  }
}
