import { Directive, HostListener, Input } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';

@Directive({
  selector: '[appEventTracker]',
})
export class EventTrackerDirective {
  @Input('appEventTracker') option: any;

  @HostListener('click', ['$event']) onClick($event) {
    this.analytics.logEvent(this.option.action);
  }

  constructor(private analytics: AngularFireAnalytics) {}
}
