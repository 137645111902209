import { CumulativeService } from '@shared/services';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CumulativeItem, CumulativeType } from '@app/shared/models';
import { tada } from 'ng-animate';
import { transition, trigger, useAnimation } from '@angular/animations';
import { PayrollCloseDetail } from '@app/core/models';

@Component({
  selector: 'app-cumulative-time-editor',
  templateUrl: './cumulative-time-editor.component.html',
  styles: [],
  animations: [trigger('tada', [transition('* => *', useAnimation(tada))])],
})
export class CumulativeTimeEditorComponent implements OnInit {
  // App/Models/PayrollCloseDetail
  private _itemDetail: PayrollCloseDetail = undefined;
  @Input() get itemDetail(): PayrollCloseDetail {
    return this._itemDetail;
  }
  set itemDetail(value: PayrollCloseDetail) {
    this._itemDetail = { ...value };
  }
  @Input() isCumulative: boolean = false;
  @Input() rowName: string = '';
  @Input() minutesKey: string = '';
  @Input() allowEditData: boolean = true;
  @Input() autoSave: boolean = true;
  @Input() payrollCloseStatus: string = 'open';
  @Output() changed = new EventEmitter();
  loading: boolean = false;
  notAuto: number = 0;
  auto: number = 0;
  valueWasChanged: boolean = false;

  constructor(private cumulativeService: CumulativeService) {}

  ngOnInit(): void {
    this.resetAutoValues();
  }

  updateOvertime(op: any) {
    this.valueWasChanged = false;
    if (this.autoSave === true) {
      this.loading = true;
      this.cumulativeService
        .updateMassive([
          new CumulativeItem(
            this.itemDetail.id,
            this.auto,
            this.getTypeToEdit()
          ),
        ])
        .then(() => this.applyChanges(op))
        .finally(() => (this.loading = false));
    } else {
      this.applyChanges(op);
    }
  }

  applyChanges(op: any) {
    this.itemDetail.attendance = {
      ...this.itemDetail.attendance,
      [`sum${this.getPropertyToEdit()}Minutes`]: this.auto,
    };
    this.changed.next(this.itemDetail);
    op.hide();
    this.valueWasChanged = true;
  }

  resetAutoValues() {
    this.valueWasChanged = false;
    this.notAuto =
      this.itemDetail.attendance[
        `sumNotAutoCumulative${this.getPropertyToEdit()}Minutes`
      ] ?? 0;
    this.auto =
      this.itemDetail.attendance[`sum${this.getPropertyToEdit()}Minutes`] ?? 0;
  }
  onHideOverlay() {
    this.resetAutoValues();
  }

  isEditableType(): boolean {
    if (this.isCumulative === false) {
      return false;
    }
    return ['Extra', 'Doble descanso', 'Feriado', 'Doble feriado'].includes(
      this.rowName
    );
  }

  getPropertyToEdit(): string {
    switch (this.rowName) {
      case 'Extra':
        return 'Overtime';
      case 'Feriado':
        return 'Holiday';
      case 'Doble descanso':
        return 'Double';
      case 'Doble feriado':
        return 'DoubleHoliday';
    }
  }

  getTypeToEdit(): CumulativeType {
    switch (this.rowName) {
      case 'Extra':
        return CumulativeType.Overtime;
      case 'Feriado':
        return CumulativeType.Holiday;
      case 'Doble descanso':
        return CumulativeType.Double;
      case 'Doble feriado':
        return CumulativeType.DoubleHoliday;
    }
  }

  getPendingToAuthorize(): number {
    return (
      this.itemDetail.attendance[
        `sumNotAutoCumulative${this.getPropertyToEdit()}Minutes`
      ] - this.itemDetail.attendance[`sum${this.getPropertyToEdit()}Minutes`]
    );
  }
}
