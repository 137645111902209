import { Payroll, PayrollClose, PayrollCloseDetail } from '@app/core/models';

export interface PayrollCloseState {
  payrollCloses: PayrollClose[];
  isLoading: boolean;
  error: any;
  isClosingPayrollClose: boolean;
  isCreatingPayrollClose: boolean;
  isUpdatingPayrollCloseDetailNote: boolean;
  selectedDetail: {
    payroll: Payroll;
    payrollClose: PayrollClose;
    payrollCloseDetail: PayrollCloseDetail;
    allowedEdit: boolean;
  };
}
