import { Payroll, PayrollClose, PayrollCloseDetail } from '@app/core/models';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const PayrollCloseActions = createActionGroup({
  source: 'PayrollClose',
  events: {
    'Get payroll closes': props<{
      beginAt: string;
      endAt: string;
      status: string;
    }>(),
    'Get payroll closes success': props<{ payrollCloses: PayrollClose[] }>(),
    'Create payroll close': props<{
      payrollId: number;
      beginAt: string;
      endAt: string;
    }>(),
    'Create payroll close success': props<{ payrollClose: PayrollClose }>(),
    'Delete payroll close': props<{ payrollCloseId: number }>(),
    'Delete payroll close success': props<{ payrollClose: PayrollClose }>(),
    'Close payroll close': props<{
      payrollCloseId: number;
      sendEmailVoucher: boolean;
    }>(),
    'Close payroll close success': props<{ payrollClose: PayrollClose }>(),
    'Catch error from api': props<{ payload: any }>(),
    'Get a payroll close': props<{ payrollCloseId: number }>(),
    'Get a payroll close success': props<{ payrollClose: PayrollClose }>(),
    'Update a payroll close detail note': props<{
      payrollCloseDetailId: number;
      note: string;
    }>(),
    'Update a payroll close detail note success': props<{
      payrollCloseDetail: PayrollCloseDetail;
    }>(),
    'Update a payroll close detail loading status': props<{
      payrollCloseId: number;
      loading: boolean;
    }>(),
    'Update store loading': props<{ loading: boolean }>(),
    'Calculate payroll close': props<{ payrollCloseId: number }>(),
    'Calculate payroll close success': emptyProps(),
    'Calculate payroll close fail': props<{ payrollCloseId: number }>(),
    'Start to close a payroll close': emptyProps(),
    'End to close a payroll close': emptyProps(),
    'Start create payroll close': emptyProps(),
    'End create payroll close': emptyProps(),
    'Download payroll close csv': props<{ payrollClose: PayrollClose }>(),
    'Download payroll close csv success': emptyProps(),
    'Download payroll close full csv': props<{ payrollClose: PayrollClose }>(),
    'Download payroll close full csv success': emptyProps(),
    'Set selected payroll close detail': props<{
      payroll: Payroll;
      payrollClose: PayrollClose;
      payrollCloseDetail: PayrollCloseDetail;
      allowedEdit: boolean;
    }>(),
    'Clear selection of payroll close detail': emptyProps(),
    'Refresh a payroll close detail': props<{
      payrollCloseDetail: PayrollCloseDetail;
    }>(),
    'Refresh a payroll close detail success': props<{
      payrollCloseDetail: PayrollCloseDetail;
    }>(),
  },
});
