import { filter, map } from 'rxjs/operators';
import { HttpService } from '@shared/services';
import { Injectable } from '@angular/core';
import { EmployeeLog } from '../models';

@Injectable({
  providedIn: 'root',
})
export class EmployeeLogService {
  constructor(private http: HttpService) {}

  get(employeeId: number, start: string, end: string) {
    return this.http
      .get(`employees/${employeeId}/employee-logs?start=${start}&end=${end}`)
      .pipe(
        filter((x) => ![null, undefined].includes(x.data)),
        map((x) => x.data.map((y) => new EmployeeLog(y)))
      )
      .toPromise();
  }
  create(employeeId: number, datetime: string) {
    return this.http
      .post(`employees/${employeeId}/employee-logs`, {
        datetime,
      })
      .pipe(map((response) => response.data))
      .toPromise();
  }
  update(employeeLogId: number, active: boolean) {
    return this.http
      .put(`employee-logs/${employeeLogId}`, {
        active,
      })
      .pipe(map((response) => response.data))
      .toPromise();
  }
  delete(employeeId: number, logId: number) {
    return this.http
      .delete(`employees/${employeeId}/employee-logs/${logId}`)
      .pipe(map((response) => response.data))
      .toPromise();
  }
}
