import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, repeat, switchMap, tap } from 'rxjs/operators';
import { CompaniesService } from '@app/tenant/services';
import { CompanyActions } from './company.actions';
import { SessionActions } from '../session';

@Injectable()
export class CompaniesEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly companyService: CompaniesService,
    private store: Store<{}>
  ) {}

  getCompanies$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyActions.getCompanies.type),
      switchMap(() => this.companyService.get()),
      map((response) =>
        CompanyActions.getCompaniesSuccess({ companies: response.data })
      ),
      catchError((payload) =>
        of(CompanyActions.catchErrorFromApi({ payload }))
      ),
      repeat()
    )
  );

  saveCompany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyActions.saveCompany.type),
      switchMap(({ company }) =>
        this.companyService.save(company).pipe(
          map((response) => response.data),
          switchMap((savedCompany) => {
            // if original data has logo, then send to upload
            if ((company as any).logo) {
              this.store.dispatch(
                CompanyActions.uploadLogo({
                  companyId: savedCompany.id,
                  logo: (company as any).logo,
                })
              );
            }
            return of({ company: savedCompany });
          })
        )
      ),
      tap(() => this.store.dispatch(SessionActions.getUserCompaniesToHandle())),
      map(({ company }) => CompanyActions.saveCompanySuccess({ company })),
      catchError((payload) =>
        of(CompanyActions.catchErrorFromApi({ payload }))
      ),
      repeat()
    )
  );

  deleteCompany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyActions.deleteCompany.type),
      switchMap(({ company }) => this.companyService.delete(company)),
      tap(() => this.store.dispatch(SessionActions.getUserCompaniesToHandle())),
      map((response) =>
        CompanyActions.deleteCompanySuccess({ company: response.data })
      ),
      catchError((payload) =>
        of(CompanyActions.catchErrorFromApi({ payload }))
      ),
      repeat()
    )
  );

  uploadLogo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyActions.uploadLogo.type),
      switchMap(({ companyId, logo }) =>
        this.companyService.setLogo(companyId, logo)
      ),
      map((response) =>
        CompanyActions.uploadLogoSuccess({ company: response.data })
      ),
      tap(() => this.store.dispatch(SessionActions.getUserCompaniesToHandle())),
      catchError((payload) =>
        of(CompanyActions.catchErrorFromApi({ payload }))
      ),
      repeat()
    )
  );

  deleteLogo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyActions.deleteLogo.type),
      switchMap(({ companyId }) => this.companyService.deleteLogo(companyId)),
      map((response) =>
        CompanyActions.uploadLogoSuccess({ company: response.data })
      ),
      tap(() => this.store.dispatch(SessionActions.getUserCompaniesToHandle())),
      catchError((payload) =>
        of(CompanyActions.catchErrorFromApi({ payload }))
      ),
      repeat()
    )
  );
}
