<div class="grid p-5">
  <div class="p-5 surface-200 border-round w-full col-12 text-center mb-3">
    ¡Hola! antes de iniciar completa los siguientes datos de tu usuario.
  </div>
  <form class="p-fluid col-12" [formGroup]="frm">
    <div class="field">
      <label class="is-required" for="name">Nombre</label>
      <input type="text" pInputText formControlName="name" autofocus />
    </div>
    <div class="field">
      <label class="is-required" for="name">Primer apellido</label>
      <input type="text" pInputText formControlName="surname" />
    </div>
    <div class="field">
      <label class="is-required" for="name">Segundo apellido</label>
      <input type="text" pInputText formControlName="lastname" />
    </div>
    <!-- <div class="field">
        <label class="is-required" for="name">Correo</label>
        <input type="email" pInputText formControlName="email" />
      </div> -->
  </form>
  <div class="col-12">
    <button
      pButton
      pRipple
      label="Guardar"
      icon="pi pi-check"
      class="p-button-text"
      [disabled]="!frm.valid"
      (click)="save()"
      [loading]="loading$ | async"
    ></button>
  </div>
</div>
