export const ItemsMenu = [
  {
    label: 'selfManagement.menu.home',
    icon: 'pi pi-chart-bar',
    routerLink: ['/employee/home'],
  },
  {
    label: 'selfManagement.menu.requests',
    icon: 'fas fa-paper-plane',
    routerLink: ['/employee/requests'],
    permission: 'view_self_request',
  },
  {
    label: 'selfManagement.menu.payrolls',
    icon: 'fas fa-file-invoice-dollar',
    routerLink: ['/employee/payroll-closes'],
    permission: 'view_self_payroll_close',
  },
  {
    label: 'selfManagement.menu.attendance',
    icon: 'fas fa-user-clock',
    routerLink: ['/employee/attendance'],
    permission: 'view_self_employee_log',
  },
  {
    label: 'selfManagement.menu.bonuses',
    icon: 'fas fa-calendar-alt',
    routerLink: ['/employee/extra-months'],
    permission: 'view_self_extra_month',
  },
];
