<div class="layout-breadcrumb">
  <div class="breadcrumb">
    <div class="route-bar-breadcrumb">
      @for (item of items; track item; let last = $last) {
        <li>
          @if (item.routerLink) {
            <a [routerLink]="item.routerLink">{{item.label}}</a>
          }
          @if (!item.routerLink) {
            {{item.label}}
          }
        </li>
        @if (!last) {
          <li><i class="pi pi-angle-right"></i></li>
        }
      }
    </div>
  </div>
  <app-env-tag></app-env-tag>
</div>
