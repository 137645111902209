import { Injectable } from '@angular/core';
import { HttpService } from '@shared/services';

@Injectable({
  providedIn: 'root',
})
export class PayrollCloseDetailService {
  constructor(private http: HttpService) {}

  getById(payrollCloseDetailId: number) {
    return this.http.get(`payroll-close-details/${payrollCloseDetailId}`);
  }

  updateNote(payrollCloseDetailId: number, note: string = null) {
    return this.http.put(`payroll-close-details/${payrollCloseDetailId}/note`, {
      note,
    });
  }
}
