<div
  class="layout-sidebar"
  [ngClass]="{ 'layout-sidebar-active': appMain.sidebarActive }"
  (click)="appMain.onSidebarClick($event)"
  (mouseover)="appMain.sidebarActive = true"
  (mouseleave)="appMain.sidebarActive = false"
  >
  <div class="sidebar-logo">
    <!-- <a href="#" class="sidebar-pin" title="Toggle Menu" (click)="appMain.onToggleMenuClick($event)">
    <i class="pi pi-lock" *ngIf="appMain.staticMenuActive"></i>
    <i class="pi pi-lock-open" *ngIf="!appMain.staticMenuActive"></i>
  </a> -->
  <a routerLink="/customer/home">
    <img alt="logo" src="assets/layout/images/logo-mirage@2x.png" />
    <!-- <span class="app-name">Bit Cloud</span> -->
  </a>
</div>

<div class="layout-menu-container" (click)="onMenuClick()">
  <ul class="layout-menu">
    @for (item of model$ | async; track item; let i = $index) {
      <li
        app-menuitem
        [item]="item"
        [index]="i"
        [root]="true"
      ></li>
    }
  </ul>
</div>
</div>
