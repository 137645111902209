<div class="flex align-items-center">
  @if (showAvatar) {
    <p-avatar
      [label]="getLabel()"
      [image]="employee?.media?.imagenDePerfil"
      styleClass="mr-3"
      size="large"
      shape="circle"
    ></p-avatar>
  }
  <span class="image-text"
    >{{ employee?.name }} {{ employee?.surname }} {{ employee?.lastname }}</span
    >
  </div>
