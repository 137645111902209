<p-toast key="dt" [style]="{ marginTop: '80px' }"></p-toast>

<p-toast [style]="{ marginTop: '80px' }" position="top-left" key="tl"></p-toast>

<p-toast
  [style]="{ marginTop: '80px' }"
  position="top-center"
  key="tc"
></p-toast>

<p-toast
  [style]="{ marginTop: '80px' }"
  styleClass="custom-toast"
  key="bt"
  position="bottom-center"
></p-toast>
