import { Injectable, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ServiceDataStorage } from '@shared/helpers';

@Injectable({
  providedIn: 'root',
})
export class DynamicFormService implements OnDestroy {
  autocompletData: Array<any> = [];
  dropdownData: Array<any> = [];
  private _subscriptor: Subscription = new Subscription();
  constructor() {}

  ngOnDestroy(): void {
    this._subscriptor.unsubscribe();
  }
  /**
   * validate if has data of a model
   * @param fieldName field of name to search
   */
  hasAutoCompletData(fieldName, idForm: string) {
    return this.autocompletData[`${idForm}.${fieldName}`] !== undefined;
  }
  /**
   * validate if has data of a model
   * @param fieldName field of name to search
   */
  hasDropdownData(fieldName, idForm: string) {
    return this.dropdownData[`${idForm}.${fieldName}`] !== undefined;
  }

  createDataDependenciesAutocomplet(field: any, idForm: string) {
    // create SDS
    const sds = new ServiceDataStorage();
    sds.setModelsArray(field.autocomplete.models);
    sds.idsModels = field.autocomplete.idsModels;

    // subscribed to suggestions
    let dataService: Array<any> = [];
    this._subscriptor.add(
      sds.data.subscribe((data) => {
        if ((data as Array<any>).length > 0) {
          dataService = data.map(
            (x) =>
              x[
                field.autocomplete?.pk !== undefined
                  ? field.autocomplete.pk
                  : 'name'
              ]
          );
          // link the obtained data
          this.autocompletData[`${idForm}.${field.field}`] = {
            data: dataService,
            filtered: [],
          };
        }
      })
    );
    sds.refresh({});
  }

  createDataDependenciesDropdown(field: any, idForm: string) {
    // create SDS
    const sds = new ServiceDataStorage();
    sds.setModelsArray(field.dropdown.models);
    sds.idsModels = field.dropdown?.idsModels;

    // subscribed to suggestions
    let dataService: Array<any> = [];
    this._subscriptor.add(
      sds.data.subscribe((data) => {
        if ((data as Array<any>).length > 0) {
          dataService = data.map((x) => {
            return {
              label:
                x[
                  field.dropdown?.labelMap !== undefined
                    ? field.dropdown.labelMap
                    : 'name'
                ],
              value:
                x[
                  field.dropdown?.valueMap !== undefined
                    ? field.dropdown.valueMap
                    : 'name'
                ],
            };
          });
          // link the obtained data
          this.dropdownData[`${idForm}.${field.field}`] = {
            data: dataService,
          };
        }
      })
    );
    sds.refresh({});
  }

  public filterSingle(e, field, idForm) {
    this.autocompletData[`${idForm}.${field}`].filtered = this.filterData(
      e.query,
      this.autocompletData[`${idForm}.${field}`].data
    );
  }

  private filterData(query, data: any[]): any[] {
    const filtered: any[] = [];
    for (let i = 0; i < data.length; i++) {
      const item = data[i];
      if (item.toLowerCase().indexOf(query.toLowerCase()) === 0) {
        filtered.push(item);
      }
    }
    return filtered;
  }
}
