import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import { ToastAlert, Position, Severity } from '../models';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorRepository } from '../repositories';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor(private messageService: MessageService) {}
  /**
   * show toas alert
   * @param toast toast alert model
   */
  show(toast: ToastAlert) {
    this.messageService.add({
      key: this.getPosition(toast.position),
      severity: this.getSeverity(toast.severity),
      summary: toast.summary,
      detail: toast.detail,
    });
  }

  private getPosition(position: Position): string {
    switch (position) {
      case Position.TopLeft:
        return 'tl';
      case Position.TopCenter:
        return 'tc';
      case Position.Bottom:
        return 'bt';
      default:
        return 'dt';
    }
  }

  private getSeverity(severity: Severity): string {
    switch (severity) {
      case Severity.Error:
        return 'error';
      case Severity.Warning:
        return 'warn';
      case Severity.Info:
        return 'info';
      default:
        return 'success';
    }
  }

  /**
   * Standar error to HttpErrorResponse
   * @param error HttpErrorResponse Object
   * @param isFriendly the error is from backend normalized
   */
  showHttpErrorResponse(error: HttpErrorResponse, isFriendly = false) {
    if ([null, undefined].includes(error)) {
      return;
    }
    this.show({
      summary: isFriendly ? 'Error obtenido' : `${error.name} inesperado`,
      detail: isFriendly
        ? ErrorRepository.extractErrorMessagesFromErrorResponse(error).join(
            '\n'
          )
        : error.message,
      severity: Severity.Error,
      position: Position.TopCenter,
    } as ToastAlert);
  }
  /**
   * Stangar error to error message
   * @param message message
   * @param summary summary
   */
  showErrorString(message: string, summary: string = 'Error inesperado') {
    this.show({
      summary: summary,
      detail: message,
      severity: Severity.Error,
      position: Position.TopCenter,
    } as ToastAlert);
  }
  /**
   * standar message to entity saved ok
   */
  showGenericSaved() {
    this.show({
      summary: 'Registro guardado',
      detail: 'Registro guardado con éxito!',
      severity: Severity.Success,
      position: Position.TopCenter,
    } as ToastAlert);
  }
  /**
   * standar message to entity deleted ok
   */
  showGenericDeleted() {
    this.show({
      summary: 'Registro eliminado',
      detail: 'El registro fue eliminado con éxito!',
      severity: Severity.Info,
      position: Position.TopCenter,
    } as ToastAlert);
  }
  showInfo(message: string, summary: string = 'Acción finalizada') {
    this.show({
      summary: summary,
      detail: message,
      severity: Severity.Info,
      position: Position.TopCenter,
    } as ToastAlert);
  }
}
