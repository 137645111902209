import { Injectable } from '@angular/core';
import { HttpService } from '@app/shared/services';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LoanService {
  constructor(private http: HttpService) {}
  get(
    companyId: number,
    status: Array<string>,
    begin: string = null,
    end: string = null
  ) {
    return this.http
      .get(
        `loans?companyId=${companyId}&${this.urlParamDates(begin, end)}` +
          status
            .map(function (el, idx) {
              return 'status[' + idx + ']=' + el;
            })
            .join('&')
      )
      .pipe(map((response) => response.data))
      .toPromise();
  }
  private urlParamDates(begin: string = null, end: string = null): string {
    if (begin === null) {
      return '';
    }
    return `begin=${begin}&end=${end}&`;
  }
  save(data: any) {
    return this.http[data.id ? 'put' : 'post'](
      `loans${data.id ? `/${data.id}` : ''}`,
      data
    )
      .pipe(map((response) => response.data))
      .toPromise();
  }
  delete(loanId: number) {
    return this.http
      .delete(`loans/${loanId}`)
      .pipe(map((response) => response.data))
      .toPromise();
  }
}
