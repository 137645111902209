import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'eventTypeValue',
})
export class EventTypeValuePipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(value: any, ...args: unknown[]): unknown {
    if (!value) {
      return '';
    }
    if (value.isVacation === true) {
      return this.translate.instant('shared.evenTypeValue.vacation');
    }
    if (value.isDisability === true) {
      return this.translate.instant('shared.evenTypeValue.disability');
    }
    if (value.isLeave === true) {
      return this.translate.instant('shared.evenTypeValue.leave');
    }

    return '';
  }
}
