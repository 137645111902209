import { Component, OnInit } from '@angular/core';
import { environment } from '@environments/environment';
@Component({
  selector: 'app-env-tag',
  templateUrl: './env-tag.component.html',
  styles: [
  ]
})
export class EnvTagComponent implements OnInit {
  env: string = environment.env;
  isProd: boolean = environment.production;
  constructor() { }

  ngOnInit(): void {
  }

}
