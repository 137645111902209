export class Str {
  /**
   * Convert text to snake
   * @param str text to convert
   * @param join by defalth _
   * @returns string
   */
  public static toSnake(str: string): string {
    return str
      .match(
        /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g
      )
      .join('_')
      .toLowerCase();
  }
  /**
   * Convert text to snake only by white_spaces, useful for case with accents
   * @param str text to convert
   * @returns string
   */
  public static toSnakeSpaces(str: string): string {
    return str.replace(/ /g, '_').toLowerCase();
  }
  /**
   * Capitalize first letter of string
   * @param str text to convert
   * @returns string
   */
  public static capitalizeFirst(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
}
