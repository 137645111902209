import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
declare const Pusher: any;
import { AuthenticationService } from '@core/services';
import { environment } from '@environments/environment';
import { SessionState, selectProfile } from '@app/store/session';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PusherService {
  private pusher: any = undefined;
  /**
   * calculations channel by user_id
   */
  calculations: any = undefined;
  private calculationsChannelName: string = '';
  /**
   * files import channel by user_id
   */
  filesImport: any = undefined;
  private filesImportChannelName: string = '';

  /**
   * attendance calculation channel by user_id
   */
  attendancePerformed: any = undefined;
  private attendancePerformedChannelName: string = '';

  /**
   * record device created by all tenant
   */
  recordDeviceCreated: any = undefined;
  private recordDeviceCreatedChannelName: string = '';

  constructor(
    private authService: AuthenticationService,
    private store: Store<SessionState>
  ) {
    this.pusher = new Pusher(environment.pusher.key, {
      cluster: environment.pusher.cluster,
      encrypted: true,
    });

    this.store
      .select(selectProfile)
      .pipe(take(1))
      .subscribe((profile) => {
        if (profile.id !== -1) {
          this.subscribeToChannels(profile);
        } else {
          this.unSubscribeChannels();
        }
      });
  }

  subscribeToChannels(user: any) {
    this.calculationsChannelName = `calculations.${this.authService.getTenantId()}`;
    this.filesImportChannelName = `files.imports.${this.authService.getTenantId()}.${
      user.id
    }`;
    this.attendancePerformedChannelName = `attendance.${this.authService.getTenantId()}.${
      user.id
    }`;
    this.recordDeviceCreatedChannelName = `realtimelogs.${this.authService.getTenantId()}`;

    this.calculations = this.pusher.subscribe(this.calculationsChannelName);
    this.filesImport = this.pusher.subscribe(this.filesImportChannelName);
    this.attendancePerformed = this.pusher.subscribe(
      this.attendancePerformedChannelName
    );
    this.recordDeviceCreated = this.pusher.subscribe(
      this.recordDeviceCreatedChannelName
    );
  }
  unSubscribeChannels() {
    this.pusher.unsubscribe(this.calculationsChannelName);
    this.pusher.unsubscribe(this.filesImportChannelName);
    this.pusher.unsubscribe(this.attendancePerformedChannelName);
    this.pusher.unsubscribe(this.recordDeviceCreatedChannelName);
  }
}
