export class CumulativeItem {
  constructor(
    public id: number = 0,
    public value: number = 0,
    public type: CumulativeType = CumulativeType.Overtime
  ) {}
}

export enum CumulativeType {
  Overtime = 'overtime',
  Double = 'double',
  Holiday = 'holiday',
  DoubleHoliday = 'double_holiday',
}
