import { PermissionHelperService } from '@core/services';
import {
  AfterViewChecked,
  Directive,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

@Directive({
  selector: '[appDisableIfUnauthorize]',
})
export class DisableIfUnauthorizeDirective implements AfterViewChecked {
  @Input('appDisableIfUnauthorize') permission: string | string[]; // Required permission passed in

  constructor(
    private el: ElementRef,
    private permissionHelperService: PermissionHelperService
  ) {}
  ngAfterViewChecked(): void {
    // * Workaround to keep good perfomance
    // if the last status if false, review again the status to keep disable by permission
    if (this.el.nativeElement.disabled === false) {
      this.keepDisabled();
    }
  }

  ngOnInit() {
    this.keepDisabled();
  }
  keepDisabled() {
    if (!this.permissionHelperService.hasPermission(this.permission)) {
      this.el.nativeElement.disabled = true;
    }
  }
}
