import { map } from 'rxjs/operators';
import { HttpService } from '@shared/services';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionService {
  constructor(private http: HttpService) {}
  get() {
    return this.http
      .get('subscription')
      .pipe(map((response) => response.data))
      .toPromise();
  }
  update(data: any) {
    return this.http
      .put('subscription', data)
      .pipe(map((response) => response.data))
      .toPromise();
  }
  delete() {
    return this.http.delete('subscription').toPromise();
  }
  getPlans() {
    return this.http.get(`subscription/plans`).toPromise();
  }
  resendPayLink() {
    return this.http.get(`subscription/resend-paylink`).toPromise();
  }
}
