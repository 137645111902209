import { createReducer, on } from '@ngrx/store';
import { PayrollCloseState } from './payroll-close.state';
import { PayrollCloseActions } from './payroll-close.actions';

export const initialPayrollClosesState: PayrollCloseState = {
  payrollCloses: [],
  isLoading: false,
  error: null,
  isClosingPayrollClose: false,
  isCreatingPayrollClose: false,
  isUpdatingPayrollCloseDetailNote: false,
  selectedDetail: null,
};

const reducer = createReducer<PayrollCloseState>(
  initialPayrollClosesState,
  on(PayrollCloseActions.getPayrollCloses, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(
    PayrollCloseActions.getPayrollClosesSuccess,
    (state, { payrollCloses }) => ({
      ...state,
      isLoading: false,
      error: null,
      payrollCloses,
    })
  ),
  on(PayrollCloseActions.getAPayrollClose, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(
    PayrollCloseActions.getAPayrollCloseSuccess,
    (state, { payrollClose }) => ({
      ...state,
      isLoading: false,
      error: null,
      payrollCloses: state.payrollCloses.map((b) =>
        b.id === payrollClose.id ? payrollClose : b
      ),
    })
  ),
  on(PayrollCloseActions.closePayrollClose, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(
    PayrollCloseActions.closePayrollCloseSuccess,
    (state, { payrollClose }) => ({
      ...state,
      payrollCloses: state.payrollCloses.filter(
        (b) => b.id !== payrollClose.id
      ),
      isLoading: false,
      isClosingPayrollClose: false,
      error: null,
    })
  ),
  on(PayrollCloseActions.calculatePayrollClose, (state, { payrollCloseId }) => {
    let payrollClose = {
      ...state.payrollCloses.find((x) => x.id === payrollCloseId),
    };
    payrollClose.loading = true;
    return {
      ...state,
      error: null,
      payrollCloses: state.payrollCloses.map((b) =>
        b.id === payrollClose.id ? payrollClose : b
      ),
    };
  }),
  on(
    PayrollCloseActions.calculatePayrollCloseFail,
    (state, { payrollCloseId }) => {
      let payrollClose = {
        ...state.payrollCloses.find((x) => x.id === payrollCloseId),
      };
      payrollClose.loading = false;
      return {
        ...state,
        error: null,
        payrollCloses: state.payrollCloses.map((b) =>
          b.id === payrollClose.id ? payrollClose : b
        ),
      };
    }
  ),
  on(
    PayrollCloseActions.updateAPayrollCloseDetailLoadingStatus,
    (state, { payrollCloseId, loading }) => {
      let payrollClose = {
        ...state.payrollCloses.find((x) => x.id === payrollCloseId),
      };
      payrollClose.loading = loading;
      return {
        ...state,
        error: null,
        payrollCloses: state.payrollCloses.map((b) =>
          b.id === payrollClose.id ? payrollClose : b
        ),
      };
    }
  ),
  on(PayrollCloseActions.startToCloseAPayrollClose, (state) => ({
    ...state,
    isClosingPayrollClose: true,
  })),
  on(PayrollCloseActions.endToCloseAPayrollClose, (state) => ({
    ...state,
    isClosingPayrollClose: false,
  })),
  on(PayrollCloseActions.updateStoreLoading, (state, { loading }) => ({
    ...state,
    isLoading: loading,
  })),
  on(PayrollCloseActions.deletePayrollClose, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(
    PayrollCloseActions.deletePayrollCloseSuccess,
    (state, { payrollClose }) => ({
      ...state,
      payrollCloses: state.payrollCloses.filter(
        (b) => b.id !== payrollClose.id
      ),
      isLoading: false,
      error: null,
    })
  ),
  on(PayrollCloseActions.createPayrollClose, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(
    PayrollCloseActions.createPayrollCloseSuccess,
    (state, { payrollClose }) => ({
      ...state,
      isLoading: false,
      isCreatingPayrollClose: false,
      error: null,
      payrollCloses: [
        ...state.payrollCloses,
        { ...payrollClose, loading: true },
      ],
    })
  ),
  on(PayrollCloseActions.startCreatePayrollClose, (state) => ({
    ...state,
    isCreatingPayrollClose: true,
  })),
  on(PayrollCloseActions.endCreatePayrollClose, (state) => ({
    ...state,
    isLoading: false,
    isCreatingPayrollClose: false,
    error: null,
  })),
  on(PayrollCloseActions.downloadPayrollCloseCsv, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(PayrollCloseActions.downloadPayrollCloseCsvSuccess, (state) => ({
    ...state,
    isLoading: false,
    error: null,
  })),
  on(PayrollCloseActions.downloadPayrollCloseFullCsv, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(PayrollCloseActions.downloadPayrollCloseFullCsvSuccess, (state) => ({
    ...state,
    isLoading: false,
    error: null,
  })),
  on(PayrollCloseActions.updateAPayrollCloseDetailNote, (state) => ({
    ...state,
    isUpdatingPayrollCloseDetailNote: true,
  })),
  on(
    PayrollCloseActions.updateAPayrollCloseDetailNoteSuccess,
    (state, { payrollCloseDetail }) => {
      let payrollClose = {
        ...state.payrollCloses.find(
          (b) => b.id === payrollCloseDetail.payrollCloseId
        ),
      };

      payrollClose.details = payrollClose.details.map((x) =>
        x.id === payrollCloseDetail.id ? payrollCloseDetail : x
      );
      return {
        ...state,
        isUpdatingPayrollCloseDetailNote: false,
        error: null,
        payrollCloses: state.payrollCloses.map((b) =>
          b.id === payrollClose.id ? payrollClose : b
        ),
        selectedDetail:
          state.selectedDetail.payrollCloseDetail.id === payrollCloseDetail.id
            ? {
                payroll: payrollClose.payroll,
                payrollClose: payrollClose,
                payrollCloseDetail: payrollCloseDetail,
                allowedEdit: state.selectedDetail.allowedEdit,
              }
            : state.selectedDetail,
      };
    }
  ),
  on(
    PayrollCloseActions.setSelectedPayrollCloseDetail,
    (state, { payroll, payrollClose, payrollCloseDetail, allowedEdit }) => ({
      ...state,
      selectedDetail: {
        payroll,
        payrollClose,
        payrollCloseDetail,
        allowedEdit,
      },
    })
  ),
  on(PayrollCloseActions.clearSelectionOfPayrollCloseDetail, (state) => ({
    ...state,
    selectedDetail: null,
  })),
  on(
    PayrollCloseActions.refreshAPayrollCloseDetailSuccess,
    (state, { payrollCloseDetail }) => {
      let payrollClose = {
        ...state.payrollCloses.find(
          (b) => b.id === payrollCloseDetail.payrollCloseId
        ),
      };
      payrollClose.details = payrollClose.details.map((x) =>
        x.employee.id === payrollCloseDetail.employee.id
          ? payrollCloseDetail
          : x
      );
      return {
        ...state,
        error: null,
        payrollCloses: state.payrollCloses.map((b) =>
          b.id === payrollClose.id ? payrollClose : b
        ),
        selectedDetail:
          state.selectedDetail.payrollCloseDetail.id === payrollCloseDetail.id
            ? {
                payroll: payrollClose.payroll,
                payrollClose: payrollClose,
                payrollCloseDetail: payrollCloseDetail,
                allowedEdit: state.selectedDetail.allowedEdit,
              }
            : state.selectedDetail,
      };
    }
  ),
  on(PayrollCloseActions.catchErrorFromApi, (state, { payload }) => ({
    ...state,
    isLoading: false,
    error: payload,
    isUpdatingPayrollCloseDetailNote: false,
  }))
);

export function PayrollClosesReducer(
  state = initialPayrollClosesState,
  actions
): PayrollCloseState {
  return reducer(state, actions);
}
