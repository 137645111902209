export class AdditionalField {
  constructor(
    public id: number = 0,
    public name: string = '',
    public source: Array<string> = [],
    public type: string = 'text',
    public validators: Array<string> = [],
    public required: boolean = false,
    public additionalFieldableType: string = 'employees',
    public isDeletable: boolean = true,
    public groupName: string = '',
    public hideOnGrid: boolean = true,
    public isSortable: boolean = false,
    public order: number = 0
  ) {}
}
