import { Injectable } from '@angular/core';
import { HttpService } from '@app/shared/services';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class HolidayService {
  constructor(private http: HttpService) {}
  get(year: number) {
    return this.http
      .get(`holidays?year=${year}`)
      .pipe(map((response) => response.data))
      .toPromise();
  }
  save(data: any) {
    return this.http[data.id ? 'put' : 'post'](
      `holidays${data.id ? `/${data.id}` : ''}`,
      data
    )
      .pipe(map((response) => response.data))
      .toPromise();
  }
  delete(holidayId: number) {
    return this.http
      .delete(`holidays/${holidayId}`)
      .pipe(map((response) => response.data))
      .toPromise();
  }
  fromSource(year: number, lang: string, country: string) {
    return this.http
      .get(`holidays/source?year=${year}&lang=${lang}&country=${country}`)
      .pipe(map((response) => response.data))
      .toPromise();
  }
  saveMassive(holidays: Array<any>) {
    return this.http
      .post('holidays/massive', holidays)
      .pipe(map((response) => response.data))
      .toPromise();
  }
}
