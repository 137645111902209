import { map } from 'rxjs/operators';
import { HttpService } from '@shared/services';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ActivityLogService {
  constructor(private http: HttpService) {}

  get(start: string, end: string, page: number, perPage: number) {
    return this.http
      .get(
        `activity-logs?start=${start}&end=${end}&page=${page}&perPage=${perPage}`
      )
      .toPromise();
  }
}
