import { Injectable } from '@angular/core';
import { HttpService } from '@app/shared/services';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CustomVacationSettingService {
  constructor(private http: HttpService) {}

  get() {
    return this.http
      .get('custom-vacation-settings')
      .pipe(map((response) => response.data))
      .toPromise();
  }

  update(employeeId: number, amountToAdd: number) {
    return this.http
      .put(`employees/${employeeId}/vacation-settings`, { amountToAdd })
      .pipe(map((response) => response.data))
      .toPromise();
  }

  delete(employeeId: number) {
    return this.http
      .delete(`employees/${employeeId}/vacation-settings`)
      .pipe(map((response) => response.data))
      .toPromise();
  }
}
