import { StoreDevtoolsModule } from '@ngrx/store-devtools';
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  env: 'staging',
  apiUrl: 'https://staging-api.bitappcloud.com',
  appName: '(Staging) Bit App Cloud',
  companyName: 'Bit-Office',
  pusher: {
    key: '116f16521b8ba2f460e3',
    cluster: 'us2',
  },
  firebase: {
    apiKey: 'AIzaSyAVIqL49JAp_FjMwF6buVtUKjFlMKvzEFM',
    authDomain: 'staging-bit-app-cloud.firebaseapp.com',
    projectId: 'staging-bit-app-cloud',
    storageBucket: 'staging-bit-app-cloud.appspot.com',
    messagingSenderId: '604967219923',
    appId: '1:604967219923:web:560f67f1ba87927f3d5a26',
    measurementId: 'G-SD1FRTMRM8',
  },
  paddle: {
    vendor: 4587,
  },
  googleMaps: {
    apiKey: 'AIzaSyB4wUIzkMTXG8OK5hBrZJuTsRloeNk0EzQ',
  },
  googleForms: {
    cancelSubscription:
      'https://docs.google.com/forms/d/e/1FAIpQLScpwCbovN4kN4p0KGYin67hEAa_gKCqesbACLdDgfOl9F6LOA/viewform?embedded=true',
  },
  imports: [StoreDevtoolsModule.instrument({ name: 'staging' , connectInZone: true})],
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
