@if (item.employee) {
<app-employee-name [employee]="item.employee"></app-employee-name>
}
<p-table
  [value]="item.months"
  dataKey="id"
  [scrollable]="true"
  scrollHeight="300px"
>
  <ng-template pTemplate="header">
    <tr>
      <th>{{ "shared.extraMonthEmployeeDetail.date" | translate }}</th>
      <th class="text-right">
        {{ "shared.extraMonthEmployeeDetail.incomes" | translate }}
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-item>
    <tr>
      <td>
        {{ item.dateAt | customDate : "MMMM y" | titlecase }}
      </td>
      <td class="text-right">
        {{ item.amount | currency : payroll.currency }}
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="2" class="text-center">
        {{ "shared.extraMonthEmployeeDetail.no_results" | translate }}
      </td>
    </tr>
  </ng-template>
</p-table>
<h5>{{ "shared.extraMonthEmployeeDetail.subtitleAdditonals" | translate }}</h5>
@if(editable){
<div class="col-12 md:col-3">
  <button
    pButton
    pRipple
    label="{{ 'shared.extraMonthEmployeeDetail.createAdditional' | translate }}"
    icon="pi pi-plus"
    (click)="createItem(dt)"
    [appDisableIfUnauthorize]="'update_extra_month'"
    [loading]="loading"
  ></button>
</div>
}
<p-table
  #dt
  [value]="additionals"
  dataKey="id"
  [scrollable]="true"
  scrollHeight="300px"
  editMode="row"
  [loading]="loading"
>
  <ng-template pTemplate="header">
    <tr>
      <th>{{ "shared.extraMonthEmployeeDetail.description" | translate }}</th>
      <th class="text-right">
        {{ "shared.extraMonthEmployeeDetail.amount" | translate }}
      </th>
      @if(editable){
      <th></th>
      }
    </tr>
  </ng-template>
  <ng-template
    pTemplate="body"
    let-item
    let-editing="editing"
    let-ri="rowIndex"
  >
    <tr [pEditableRow]="item">
      <td>
        <p-cellEditor>
          <ng-template pTemplate="input">
            <input
              type="text"
              pInputText
              [(ngModel)]="item.description"
              minlength="3"
              maxlength="191"
              autofocus
            />
          </ng-template>
          <ng-template pTemplate="output">
            {{ item.description }}
          </ng-template>
        </p-cellEditor>
      </td>
      <td class="text-right">
        <p-cellEditor>
          <ng-template pTemplate="input">
            <p-inputNumber
              [(ngModel)]="item.amount"
              inputId="amount"
              mode="currency"
              [currency]="payroll.currency"
            />
          </ng-template>
          <ng-template pTemplate="output">
            {{ item.amount | currency : payroll.currency }}
          </ng-template>
        </p-cellEditor>
      </td>
      @if(editable){
      <td>
        <div class="flex align-items-center justify-content-end gap-2">
          @if(!editing){
          <button
            pButton
            pRipple
            icon="pi pi-trash"
            class="p-button-rounded p-button-danger mr-2"
            (click)="deleteItem(item)"
            [appDisableIfUnauthorize]="'update_extra_month'"
            pTooltip="{{
              'shared.extraMonthEmployeeDetail.deleteTooltip' | translate
            }}"
          ></button>
          } @if(!editing){
          <button
            pButton
            pRipple
            type="button"
            pInitEditableRow
            icon="pi pi-pencil"
            (click)="onRowEditInit(item, ri)"
            class="p-button-rounded bit-btn-secondary mr-2"
          ></button>
          } @if(editing){
          <button
            pButton
            pRipple
            type="button"
            pSaveEditableRow
            icon="pi pi-check"
            (click)="onRowEditSave(item, ri, dt)"
            pTooltip="{{ 'commons.save' | translate }}"
            class="p-button-rounded p-button-text p-button-success mr-2"
            [disabled]="item.description === '' || item.amount === 0"
          ></button>
          } @if(editing){
          <button
            pButton
            pRipple
            type="button"
            pCancelEditableRow
            icon="pi pi-times"
            (click)="onRowEditCancel(item, ri)"
            pTooltip="{{ 'commons.cancel' | translate }}"
            class="p-button-rounded p-button-text p-button-danger"
          ></button>
          }
        </div>
      </td>
      }
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td [attr.colspan]="editable ? 3 : 2" class="text-center">
        {{ "shared.extraMonthEmployeeDetail.no_additionals" | translate }}
      </td>
    </tr>
  </ng-template>
</p-table>

<div
  class="col-12 flex align-items-center justify-content-between mt-3 net-pay"
  [ngStyle]="{ color: 'var(--secondary-color)' }"
>
  <h5 class="my-1">
    {{ "shared.extraMonthEmployeeDetail.bonus" | translate }}
  </h5>
  <h5 class="my-1">
    {{ item.totalAmount | currency : payroll.currency }}
  </h5>
</div>
