import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import * as Sentry from '@sentry/angular';
import { BrowserTracing } from '@sentry/tracing';

const serverErrorsRegex = new RegExp(
  `500 Internal Server Error|401 Unauthorized|403 Forbidden|404 Not Found|502 Bad Gateway|503 Service Unavailable`,
  'mi'
);
Sentry.init({
  dsn: 'https://6b2f28d1c93d4acd81c1c804579608f7@o1008530.ingest.sentry.io/6239914',
  environment: environment.env,
  integrations: [
    new BrowserTracing({
      tracingOrigins: [
        environment.env === 'local' ? 'localhost' : environment.apiUrl,
      ],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
  ],
  // tracesSampleRate: 1.0, https://github.com/getsentry/sentry-javascript/issues/2859#issuecomment-1110236297
  // We ignore Server Errors. We have to define here since Angular
  // http client uses setTimeout to detect http call progress.
  // And when the call fails, it throws an exception inside that timeout
  // that bubbles up higher to the main Angular's error handler.
  ignoreErrors: [serverErrorsRegex],
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
