import { Injectable } from '@angular/core';
import * as moment from 'moment-timezone';

@Injectable({
  providedIn: 'root',
})
export class TimezoneService {
  constructor() {
    this.setDefaultTimezone();
  }
  setDefaultTimezone() {
    moment.tz.setDefault('America/Costa_Rica');
  }
  getCurrentTimeZone(): string {
    return 'America/Costa_Rica';
  }
}
