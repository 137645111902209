import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FormatType, TimeCalculationRepository } from '@shared/repositories';

@Pipe({
  name: 'issuesValue',
})
export class IssuesValuePipe implements PipeTransform {
  constructor(private translate: TranslateService) {}
  transform(value: any, ...args: string[]): unknown {
    if (!value) {
      return;
    }
    if (!args) {
      return;
    }

    return this.issue(args[0], value);
  }

  private issue(location: string, value: any): string {
    const issue: string = value[location + 'Issue'];
    if (issue === null) {
      return '';
    }
    const label: string = this.translate.instant(
      'tenant.attendanceTypes.' + issue
    ); //IssuesTypes.find((x) => x.value === issue)?.label;

    if (value.rowType === 'overtime_segment') {
      return `${label} ${this.translate.instant('commons.overtime_segment')}`;
    }

    switch (issue) {
      case 'late':
      case 'before':
      case 'overtime':
      case 'absence':
        return `${label} ${this.locationInText(location)} (${this.notAuto(
          location,
          value
        )} / ${this.auto(location, value)})`;
      case 'missing':
        return `${label} ${this.locationInText(location)} `;
      case 'exceed_break':
        return label;
      default:
        return 'not support';
    }
  }

  private notAuto(location: string, value: number): string {
    return TimeCalculationRepository.minutesToHours(
      value[location + 'NotAuto'] - value[location + 'Auto'],
      FormatType.narrow
    );
  }

  private auto(location: string, value: number): string {
    return TimeCalculationRepository.minutesToHours(
      value[location + 'Auto'],
      FormatType.narrow
    );
  }

  private locationInText(location: string): string {
    switch (location) {
      case 'in':
        return this.translate.instant(`commons.location_${location}`);
      case 'out':
        return this.translate.instant(`commons.location_${location}`);
      default:
        return '';
    }
  }
}
