import { HttpService } from '@shared/services';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class VacationLogsService {
  constructor(private http: HttpService) {}
  /**
   * Get vacations logs and vacation balance from a date ranges
   * @param employee employee object
   * @param start start date
   * @param end end date
   * @returns Observable
   */
  getLogs(employee: any, start: string, end: string) {
    return this.http.get(
      `employees/${employee.id}/vacation-logs?start=${start}&end=${end}`
    );
  }
  /**
   * Create a new vacation log
   * @param employee employee object
   * @param isBalanceModifier modify the balance
   * @param quantity quantity to add
   * @param note description
   * @returns Observable
   */
  createLog(
    employee: any,
    isBalanceModifier: boolean,
    quantity: number,
    note: string
  ) {
    return this.http.post(`employees/${employee.id}/vacation-logs`, {
      isBalanceModifier,
      quantity,
      note,
    });
  }
}
