import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  constructor() {}

  getCurrentUser(): any {
    return JSON.parse(localStorage.getItem('currentUser'));
  }
  setCurrentUser(user: any) {
    if (![null, undefined].includes(user)) {
      localStorage.setItem('currentUser', JSON.stringify(user));
    }
  }
  setTenantId(value: string) {
    localStorage.setItem('tenantId', value);
  }
  getTenantId() {
    return localStorage.getItem('tenantId');
  }
  setAccessToken(token: string) {
    localStorage.setItem('access_token', token);
  }
  getAccessToken(): string {
    return localStorage.getItem('access_token');
  }
  setRefreshToken(token: string) {
    localStorage.setItem('refresh_token', token);
  }
  getRefreshToken(): string {
    return localStorage.getItem('refresh_token');
  }
  setCustomerId(token: string) {
    localStorage.setItem('customerId', token);
  }
  getCustomerId(): string {
    return localStorage.getItem('customerId');
  }
  setLang(lang: string) {
    if ([null, undefined, ''].includes(lang)) {
      return;
    }
    localStorage.setItem('lang', lang);
  }
  getLang(): string {
    let lang = localStorage.getItem('lang');
    if ([null, undefined, ''].includes(lang)) {
      lang = 'es';
      this.setLang(lang);
    }
    return lang;
  }

  clear({ except = null }) {
    const items = ['access_token', 'refresh_token', 'currentUser', 'tenantId'];
    // remove user from local storage to log user out
    var toClean = [];
    if (except !== null) {
      toClean = items.filter((x) => !except.includes(x));
    } else {
      toClean = items;
    }
    toClean.forEach((x) => localStorage.removeItem(x));
  }
}
