<div class="exception-body access">
  <div class="exception-content">
    <div class="moon">
      <img
        src="assets/layout/images/pages/asset-moon.svg"
        alt="mirage-layout"
      />
    </div>
    <div class="exception-panel">
      <div class="exception-panel-content">
        <span class="tag">{{ code }}</span>
        <h1>{{ title }}</h1>
        <div class="seperator"></div>
        <p>
          {{ message }}
        </p>
        <button
          pButton
          type="button"
          (click)="return()"
          label="{{ 'core.access_denied.return' | translate }}"
        ></button>
      </div>
    </div>
    <!-- <div class="desert"></div> -->
  </div>
</div>
