import { Injectable } from '@angular/core';
import { HttpService } from '@shared/services';

@Injectable({
  providedIn: 'root',
})
export class PayrollCloseDetailService {
  constructor(private http: HttpService) {}

  getById(payrollCloseDetailId: number) {
    return this.http.get(`payroll-close-details/${payrollCloseDetailId}`);
  }

  updateNote(payrollCloseDetailId: number, note: string = null) {
    return this.http.put(`payroll-close-details/${payrollCloseDetailId}/note`, {
      note,
    });
  }

  downloadVoucher(payrollCloseDetailId: number) {
    return new Promise((resolve, reject) => {
      this.http
        .get(`payroll-close-details/${payrollCloseDetailId}/voucher`, {
          responseType: 'blob',
        })
        .subscribe(
          (response) => {
            this.downloadBlob(response, `voucher.pdf`);
            resolve(true);
          },
          (error) => reject(error)
        );
    });
  }

  private downloadBlob(response, nameFile) {
    const myBlob = new Blob([response], { type: 'application/csv' });
    const downloadUrl = URL.createObjectURL(myBlob);
    let a = document.createElement('a');
    a.href = downloadUrl;
    a.download = nameFile;
    // start download
    a.click();
  }
}
