import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { ToastService } from '@shared/services';
import { Position, Severity, ToastAlert } from '@app/shared/models';
import {
  SessionActions,
  SessionState,
  selectPasswordChanged,
  selectProfile,
  selectSessionError,
  selectSessionIsLoading,
} from '@app/store/session';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styles: [],
})
export class ChangePasswordComponent implements OnInit, OnDestroy {
  password: string = undefined;
  passwordConfirmation: string = undefined;
  loading$ = this.store.select(selectSessionIsLoading);
  subscription: Subscription = new Subscription();
  profile: any;
  @Output() passwordChanged = new EventEmitter();
  @Output() cancel = new EventEmitter();
  constructor(
    private toast: ToastService,
    private store: Store<SessionState>,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.subscription
      .add(
        this.store.select(selectPasswordChanged).subscribe((value) => {
          if (value === true) {
            this.toast.show({
              summary: this.translate.instant(
                'shared.changePassword.password_updated'
              ),
              detail: this.translate.instant(
                'shared.changePassword.password_updated_detail'
              ),
              severity: Severity.Info,
              position: Position.TopCenter,
            } as ToastAlert);
            this.passwordChanged.emit();
          }
        })
      )
      .add(
        this.store
          .select(selectProfile)
          .subscribe((profile) => (this.profile = profile))
      )
      .add(
        this.store.select(selectSessionError).subscribe((error) => {
          if (error !== null) {
            this.toast.showErrorString(
              error,
              this.translate.instant(
                'shared.changePassword.error_updating_password'
              )
            );
          }
        })
      );
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  changePwd() {
    if (
      this.password === '' ||
      this.password === undefined ||
      this.passwordConfirmation === '' ||
      this.passwordConfirmation === undefined
    ) {
      return;
    }
    this.store.dispatch(
      SessionActions.updatePassword({
        id: this.profile.id,
        password: this.password,
        passwordConfirmation: this.passwordConfirmation,
      })
    );
  }
  cancelBtn() {
    this.cancel.emit();
  }
}
