import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-event-items-attached',
  templateUrl: './event-items-attached.component.html',
  styles: [],
})
export class EventItemsAttachedComponent implements OnInit {
  @Input() event: any = null;
  constructor() {}

  ngOnInit(): void {}
}
