import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { LocalStorageService } from '@app/shared/services';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService
  ) {}

  refreshToken() {
    return this.http
      .post<any>(
        `${environment.apiUrl}/${
          this.isTenant() ? 'tenant' : 'central'
        }/auth/refresh`,
        { refresh_token: this.localStorageService.getRefreshToken() },
        { headers: new HttpHeaders({ 'X-Localization': navigator.language }) }
      )
      .pipe(
        map((response) => {
          this.storeItemsFormAuthentication(response.data);
          return response;
        })
      );
  }

  isAuthenticated(): boolean {
    return this.localStorageService.getAccessToken() ? true : false;
  }

  private storeItemsFormAuthentication(data: any) {
    this.localStorageService.setAccessToken(data.accessToken);
    this.localStorageService.setRefreshToken(data.refreshToken);
    this.localStorageService.setCurrentUser(data.profile);
  }

  isTenant(): boolean {
    const tenantId = this.getTenantId();
    return tenantId === null || tenantId === undefined ? false : true;
  }

  getTenantId(): string {
    return this.localStorageService.getTenantId();
  }

  findTenant(customerId: string) {
    return this.http
      .post<any>(
        `${environment.apiUrl}/tenant/auth/search-customer-id`,
        {
          customerId,
        },
        { headers: new HttpHeaders({ 'X-Localization': navigator.language }) }
      )
      .pipe(
        map((response) => {
          this.localStorageService.setTenantId(response.data.tenantId);
          return response.data.tenantId;
        })
      );
  }
}
