import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  SessionState,
  selectCanChangeBetweenModules,
  selectProfile,
  selectSelectedCompany,
} from '@app/store/session';
import { AuthActions, AuthState } from '@app/store/auth';
import { AppMainComponent } from './app.main.component';
import { Profile } from '../models';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-topbar',
  templateUrl: './app.topbar.component.html',
})
export class AppTopBarComponent implements OnInit {
  activeItem: number;
  showChangePwd: boolean = false;
  userProfile: Profile;
  canChangeModules: boolean = false;
  selectedCompany$ = this.store.select(selectSelectedCompany);

  constructor(
    public appMain: AppMainComponent,
    private router: Router,
    private store: Store<SessionState>,
    private storeAuth: Store<AuthState>,
    private translate: TranslateService
  ) {}
  ngOnInit(): void {
    this.store
      .select(selectProfile)
      .subscribe((data) => (this.userProfile = data));
    this.store
      .select(selectCanChangeBetweenModules)
      .subscribe((data) => (this.canChangeModules = data));
  }

  mobileMegaMenuItemClick(index) {
    this.appMain.megaMenuMobileClick = true;
    this.activeItem = this.activeItem === index ? null : index;
  }

  onSignOut() {
    this.storeAuth.dispatch(AuthActions.logout());
  }

  onConfigButtonClick(e) {
    this.appMain.configActive = true;
  }

  onChangePassword(e) {
    this.showChangePwd = true;
  }

  getLabelOfChangeModule(): string {
    return this.router.url.startsWith('/customer')
      ? this.translate.instant('core.mirage.topbar.change_to_self_management')
      : this.translate.instant('core.mirage.topbar.change_to_admin_management');
  }

  onChangeOfModule() {
    this.router.navigate([
      `/${
        this.router.url.startsWith('/customer') ? 'employee' : 'customer'
      }/home`,
    ]);
  }

  showCompanySelector() {
    return this.router.url.startsWith('/customer');
  }

  isCustomerModule(): boolean {
    return this.router.url.startsWith('/customer');
  }
}
