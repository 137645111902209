import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-mime-icon',
  templateUrl: './mime-icon.component.html',
  styles: [],
})
export class MimeIconComponent implements OnInit {
  @Input() mimeType: string = '';

  iconClasses: any = {
    // Media
    image: 'far fa-image',
    audio: 'far fa-file-audio',
    video: 'far fa-file-video',
    // Documents
    'application/pdf': 'far fa-file-pdf',
    'application/msword': 'far fa-file-word',
    'application/vnd.ms-word': 'far fa-file-word',
    'application/vnd.oasis.opendocument.text': 'far fa-file-word',
    'application/vnd.openxmlformats-officedocument.wordprocessingml':
      'far fa-file-word',
    'application/vnd.ms-excel': 'far fa-file-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml':
      'far fa-file-excel',
    'application/vnd.oasis.opendocument.spreadsheet': 'far fa-file-excel',
    'application/vnd.ms-powerpoint': 'far fa-file-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml':
      'far fa-file-powerpoint',
    'application/vnd.oasis.opendocument.presentation': 'far fa-file-powerpoint',
    'text/plain': 'far fa-file-alt',
    'text/html': 'far fa-file-code',
    'application/json': 'far fa-file-code',
    // Archives
    'application/gzip': 'far fa-file-archive',
    'application/zip': 'far fa-file-archive',
  };
  constructor() {}

  ngOnInit(): void {}

  getIconClass(): string {
    const candidate: any = Object.entries(this.iconClasses).find(([k]) =>
      this.mimeType.startsWith(k)
    );
    if (candidate === undefined) {
      return 'far fa-file-archive';
    } else {
      return candidate[1];
    }
  }
}
