import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'issueFulltext',
})
export class IssueFulltextPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}
  transform(value: any, ...args: any[]): unknown {
    if (!value) {
      return;
    }
    if (!args) {
      return;
    }

    return this.issue(args[0], value, args[1]);
  }

  private issue(
    location: string,
    value: any,
    isCumulative: boolean = false
  ): string {
    let issue: string = value[location + 'Issue'];
    if (issue === null) {
      return '';
    }

    if (isCumulative === true && issue === 'overtime') {
      issue = 'cumulative_overtime';
    }

    const label: string = this.translate.instant(
      'tenant.attendanceTypes.' + issue
    ); //IssuesTypes.find((x) => x.value === issue)?.label;

    if (value.rowType === 'overtime_segment') {
      return `${label} ${this.translate.instant('commons.overtime_segment')}`;
    }

    switch (issue) {
      case 'late':
      case 'before':
      case 'overtime':
      case 'absence':
      case 'missing':
      case 'cumulative_overtime':
        return `${label} ${this.locationInText(location)} `;
      case 'exceed_break':
        return label;
      default:
        return 'not support';
    }
  }

  private locationInText(location: string): string {
    switch (location) {
      case 'in':
      case 'out':
        return this.translate.instant(`commons.location_${location}`);
      default:
        return '';
    }
  }
}
