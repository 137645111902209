import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root',
})
export class CommonsErrorsService {
  constructor(private router: Router) {}

  public withOutCompany() {
    this.router.navigate(['/access-denied'], {
      state: {
        title: 'Sin empresa asignada',
        message:
          'No tienes asignada una empresa para administrar. Comuníquese con el administrador.',
      },
    });
  }
}
