import { HttpService } from '@shared/services';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ResendsService {
  constructor(private http: HttpService) {}
  /**
   * Re-send email of payroll close voucher to employee
   * @param employeeId employee id
   * @param payrollCloseId payroll close id
   * @returns Observable
   */
  payrollCloseVoucher(employeeId: number, payrollCloseId: number) {
    return this.http.get(
      `employees/${employeeId}/payroll-closes/${payrollCloseId}/resend-voucher`
    );
  }
}
