import { createReducer, on } from '@ngrx/store';
import { SessionState } from './session.state';
import { SessionActions } from './session.actions';
import { ItemsMenu as TenantItemsMenu } from '@app/tenant/data';
import { ItemsMenu as SelfItemsMenu } from '@app/self-management/data';
import { Profile } from '@app/core/models';

export const initialSessionState: SessionState = {
  isLoading: false,
  selectedCompany: null,
  companies: [],
  profile: new Profile(),
  profileFetchDone: false,
  companiesFetchDone: false,
  canChangeBetweenModules: false,
  menuItems: [],
  passwordChanged: false,
  error: null,
};

const reducer = createReducer<SessionState>(
  initialSessionState,
  on(SessionActions.getProfile, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(SessionActions.getProfileSuccess, (state, { profile }) => ({
    ...state,
    isLoading: false,
    profileFetchDone: true,
    profile,
    canChangeBetweenModules: canChangeBetweenModules(profile),
  })),
  on(SessionActions.getUserCompaniesToHandle, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(
    SessionActions.getUserCompaniesToHandleSuccess,
    (state, { companies }) => ({
      ...state,
      isLoading: false,
      companiesFetchDone: true,
      companies,
      selectedCompany: companies[0],
    })
  ),
  on(SessionActions.updatePreferences, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(SessionActions.updatePreferencesSuccess, (state, { preferences }) => ({
    ...state,
    isLoading: false,
    preferences,
  })),
  on(SessionActions.selectACompany, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(SessionActions.selectACompanySuccess, (state, { selectedCompany }) => ({
    ...state,
    isLoading: false,
    selectedCompany,
  })),
  on(SessionActions.cleanSessionStore, () => initialSessionState),
  on(SessionActions.getMenuItems, (state, { module }) => ({
    ...state,
    isLoading: true,
    module,
  })),
  on(SessionActions.getMenuItemsSuccess, (state, { menuItems }) => ({
    ...state,
    isLoading: false,
    menuItems,
  })),
  on(SessionActions.updateUserIdentity, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(
    SessionActions.updateUserIdentitySuccess,
    (state, { name, surname, lastname }) => ({
      ...state,
      isLoading: false,
      profile: { ...state.profile, name, surname, lastname },
    })
  ),
  on(SessionActions.updatePassword, (state) => ({
    ...state,
    isLoading: true,
    passwordChanged: false,
    error: null,
  })),
  on(SessionActions.updatePasswordSuccess, (state) => ({
    ...state,
    isLoading: false,
    passwordChanged: true,
  })),
  on(SessionActions.catchErrorFromApi, (state, { payload }) => ({
    ...state,
    isLoading: false,
    passwordChanged: false,
    error: payload,
  }))
);

export function SessionReducer(
  state = initialSessionState,
  actions
): SessionState {
  return reducer(state, actions);
}

export function extractPermissions(items: Array<any>): Array<string> {
  let permissions = Array<string>();
  for (let item of items) {
    if (!item.permission) {
      continue;
    }
    if (item.permission instanceof Array) {
      permissions = permissions.concat(item.permission);
    }

    if (typeof item.permission === 'string') {
      permissions.push(item.permission);
    }
  }
  return permissions;
}

export function canChangeBetweenModules(profile: Profile): boolean {
  if (profile.id === -1) {
    return false;
  }
  const tenantPermissions = extractPermissions(TenantItemsMenu);
  const selfPermissions = extractPermissions(SelfItemsMenu);

  return profile.permissions.length > 0
    ? tenantPermissions.some((x) => profile.permissions.indexOf(x) !== -1) &&
        selfPermissions.some((x) => profile.permissions.indexOf(x) !== -1)
    : false;
}
