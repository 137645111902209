import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  selectSessionIsLoading,
  selectSessionCompaniesList,
  SessionState,
  SessionActions,
  selectSelectedCompany,
} from '@app/store/session';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-company-selector',
  templateUrl: './company-selector.component.html',
  styles: [],
})
export class CompanySelectorComponent implements OnInit, OnDestroy {
  selectedCompany: any = undefined;
  companies$ = this.store.select(selectSessionCompaniesList);
  loading$ = this.store.select(selectSessionIsLoading);
  subscription: Subscription = new Subscription();

  constructor(private store: Store<SessionState>) {
    this.subscription.add(
      this.store
        .select(selectSelectedCompany)
        .subscribe((company) => (this.selectedCompany = company))
    );
  }

  ngOnInit(): void {}
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onChangeCompany(e: any) {
    this.store.dispatch(SessionActions.selectACompany(e.value));
  }
}
