import { Injectable } from '@angular/core';
import { HttpService } from '@app/shared/services';
import { map } from 'rxjs/operators';
import { AppConfig } from '../domain/appconfig';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  constructor(private http: HttpService) {}

  getProfile() {
    return this.http.get('auth/profile').pipe(map((response) => response.data));
  }
  getCompaniesToHandle(userId: number) {
    return this.http
      .get(`users/${userId}/companies`)
      .pipe(map((response) => response.data));
  }
  updateUserPreferences(userId: number, preferences: AppConfig) {
    return this.http.put(`users/${userId}`, { preferences });
  }
  updateUser(userId: number, data: any) {
    return this.http.put(`users/${userId}`, data);
  }
  updatePassword(
    userId: number,
    password: string,
    passwordConfirmation: string
  ) {
    return this.http.put(`users/${userId}`, { password, passwordConfirmation });
  }
}
