import * as moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

export class EmployeeLog {
  public uuid: string;
  public id: number;
  public enrollId: number;
  public datetime: Date;
  public active: boolean;
  public data: {
    lat?: number;
    lng?: number;
    isManual: boolean;
    createdBy?: number;
    createdFullname: string;
  };
  public createdAt: Date;
  public updatedAt: Date;
  constructor(object: any) {
    this.uuid = uuidv4();
    this.id = object.id;
    this.enrollId = object.enrollId;
    this.datetime = moment(object.datetime).toDate();
    this.data = object.data;
    this.active = object.active;
    this.createdAt = object.createdAt;
    this.updatedAt = object.updatedAt;
  }
}
