import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpService } from '@shared/services';

@Injectable({
  providedIn: 'root',
})
export class CompaniesService {
  constructor(private http: HttpService) {}
  get() {
    return this.http.get('companies');
  }
  save(data: any) {
    return [null, undefined].includes(data?.id)
      ? this.create(data)
      : this.update(data);
  }
  create(data: any) {
    return this.http.post('companies', data);
  }
  update(data: any) {
    return this.http.put(`companies/${data.id}`, data);
  }
  delete(data: any) {
    return this.http.delete(`companies/${data.id}`);
  }
  migrate(sourceId: number, destinyId: number) {
    return this.http
      .post('companies/migrate', { sourceId, destinyId })
      .pipe(map((response) => response.data))
      .toPromise();
  }
  setLogo(companyId: number, file: any) {
    const form = new FormData();
    form.append('logo', file);
    form.append('_method', 'POST');
    return this.http.post(`companies/${companyId}/media`, form);
  }
  deleteLogo(companyId: number) {
    return this.http.delete(`companies/${companyId}/media`);
  }
}
