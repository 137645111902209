<div>
  @if (!hideToolbar) {
  <p-toolbar styleClass="mb-4 pl-0">
    <ng-template pTemplate="left">
      <button
        pButton
        pRipple
        [label]="labelCreate"
        icon="pi pi-plus"
        (click)="openNew()"
        [appDisableIfUnauthorize]="createPermissionName"
      ></button>
      <!-- <button
        pButton
        pRipple
        label="Borrar"
        icon="pi pi-trash"
        class="p-button-danger hidden p-d-md-inline-flex"
        (click)="deleteSelectedItems()"
        [disabled]="!selectedItems || !selectedItems.length"
      ></button> -->
    </ng-template>
    <ng-template pTemplate="right">
      <span class="p-input-icon-right">
        <input
          pInputText
          type="text"
          (input)="dt.filterGlobal($event.target.value, 'contains')"
          [placeholder]="'commons.search' | translate"
        />
        <i class="pi pi-search"></i>
      </span>
    </ng-template>
  </p-toolbar>
  }
  <p-table
    #dt
    [value]="items"
    [rows]="10"
    [paginator]="true"
    [globalFilterFields]="getGlobalFilterFields()"
    selectionMode="single"
    [(selection)]="selectedItems"
    [rowHover]="true"
    dataKey="id"
    currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords} registros"
    [showCurrentPageReport]="true"
    [loading]="loading"
    styleClass="p-datatable-responsive-demo"
    (onRowSelect)="onRowSelectChanged($event)"
    (onRowUnselect)="onRowUnSelectChanged($event)"
  >
    <ng-template pTemplate="header">
      <tr>
        <!-- <th style="width: 3rem" class="hidden p-d-md-table-cell">
      <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
    </th> -->
        <!-- dynamic columns -->
        @for (col of columns; track col) {
        <th
          [pSortableColumn]="col.isSortable === true ? col.field : ''"
          [style]="col.style"
        >
          {{ col.label }}
          @if (col.isSortable) {
          <p-sortIcon [field]="col.field"></p-sortIcon>
          }
        </th>
        }
        <th></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-item>
      <tr [pSelectableRow]="item">
        <!-- <td class="hidden p-d-md-table-cell">
    <p-tableCheckbox [value]="item"></p-tableCheckbox>
  </td> -->

        @for (col of columns; track col) {
        <td>
          <span class="p-column-title">{{ col.label }}</span>
          <ng-container
            *ngTemplateOutlet="
              itemTemplate;
              context: {
                $implicit: { label: col.field, value: item },
                index: i
              }
            "
          ></ng-container>
          @if ( !itemTemplate && !['time', 'checkbox',
          'dropdown'].includes(col.type) && col.field !== 'updatedAt' &&
          col.pipe !== 'humanize' ) {
          <span>{{ displayValue(item, col.field) }}</span>
          } @if (!itemTemplate && col.type === 'checkbox') {
          <p-checkbox
            readonly="true"
            [(ngModel)]="item[col.field]"
            binary="true"
          ></p-checkbox>
          } @if (!itemTemplate && col.type === 'time') {
          <span>{{ item[col.field] | date : "HH:mm" }}</span>
          } @if (!itemTemplate && col.type === 'dropdown') {
          <span>{{ item[col.field] | dgDropdownValue }}</span>
          } @if (!itemTemplate && col.field === 'updatedAt') {
          <span>{{ item[col.field] | humanize }}</span>
          } @if (!itemTemplate && col.pipe === 'humanize') {
          <span>{{ item[col.field] | humanize }}</span>
          }
        </td>
        }

        <td>
          <button
            pButton
            pRipple
            icon="pi pi-trash"
            class="p-button-rounded p-button-danger mr-2"
            pTooltip="Borrar registro"
            showDelay="1000"
            (click)="deleteItem(item)"
            [appDisableIfUnauthorize]="deletePermissionName"
          ></button>
          <button
            pButton
            pRipple
            icon="pi pi-pencil"
            class="p-button-rounded bit-btn-secondary"
            (click)="editItem(item)"
            pTooltip="Editar registro"
            showDelay="1000"
            [appDisableIfUnauthorize]="updatePermissionName"
          ></button>
          <ng-container
            *ngTemplateOutlet="
              afterActionsRow;
              context: {
                $implicit: item,
                index: i
              }
            "
          ></ng-container>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td [attr.colspan]="columns.length" class="text-center">
          Sin registros para mostrar.
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="summary">
      <div class="flex align-items-center justify-content-between">
        Total de registros {{ items ? items.length : 0 }}.
      </div>
    </ng-template>
  </p-table>
</div>
<!-- dialog editor -->
<p-dialog
  [(visible)]="itemDialog"
  [style]="{ width: config?.fields.length > 6 ? '80vw' : '50vw' }"
  header="Edición de registro"
  [modal]="true"
  styleClass="p-fluid"
>
  <ng-template pTemplate="content">
    <app-dynamic-form
      #dynamicfrm
      [fields]="config.fields"
      [item]="item"
      [idForm]="idForm"
    ></app-dynamic-form>
    @if (dialogAfterTemplate) {
    <div class="px-4 grid fluid">
      <div class="card col-12">
        <ng-container
          *ngTemplateOutlet="
            dialogAfterTemplate;
            context: {
              $implicit: item,
              index: i
            }
          "
          class="card col-12"
        ></ng-container>
      </div>
    </div>
    }
  </ng-template>

  <ng-template pTemplate="footer">
    <button
      pButton
      pRipple
      label="Guardar"
      icon="pi pi-check"
      class="p-button-text"
      [loading]="loading"
      [disabled]="!dynamicfrm?.valid || loading"
      (click)="saveItem()"
    ></button>
    <button
      pButton
      pRipple
      label="Cancelar"
      icon="pi pi-times"
      class="p-button-text p-button-secondary"
      [disabled]="loading"
      (click)="hideDialog()"
    ></button>
  </ng-template>
</p-dialog>
<app-confirm></app-confirm>
