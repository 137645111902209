<div class="login-body layout-light">
  <div class="login-content">
    <div class="login-panel">
      <div class="login-panel-content">
        <div class="logo">
          <img
            src="assets/layout/images/logo-mirage@2x.png"
            alt="mirage-layout"
          />
        </div>
        <!-- <h1>{{ 'core.login.title' | translate }}</h1> -->
        <!-- <p>{{ 'core.login.welcome' | translate }}</p> -->
        <form class="forms" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
          <div class="buttons mb-3">
            @if (customerByUrl) {
            <a
              (click)="customerByUrl = false"
              class="secondary-button cursor-pointer"
            >
              {{ "core.login.changeCustomerId" | translate }}
            </a>
            }
          </div>
          @if (!customerByUrl) {
          <div class="login-input-wrapper">
            <label for="customerId">{{
              "core.login.customerIdLabel" | translate
            }}</label>
            <input
              id="customerId"
              type="text"
              pInputText
              placeholder="{{ 'core.login.customerIdPlaceholder' | translate }}"
              [ngClass]="{
                'is-invalid':
                  !loginForm.get('customerId').valid &&
                  loginForm.get('customerId').touched
              }"
              formControlName="customerId"
              name="customerId"
            />
            <i class="pi pi-cloud"></i>
          </div>
          }
          <div class="login-input-wrapper">
            <label for="username">{{
              "core.login.emailLabel" | translate
            }}</label>
            <input
              id="username"
              type="email"
              pInputText
              placeholder="{{ 'core.login.emailPlaceholder' | translate }}"
              [ngClass]="{
                'is-invalid':
                  !loginForm.get('username').valid &&
                  loginForm.get('username').touched
              }"
              formControlName="username"
              name="username"
            />
            <i class="pi pi-user"></i>
          </div>

          <div class="login-input-wrapper">
            <label for="password">{{
              "core.login.passwordLabel" | translate
            }}</label>
            <input
              type="password"
              id="password"
              placeholder="{{ 'core.login.passwordPlaceholder' | translate }}"
              pPassword
              [feedback]="false"
              [ngClass]="{
                'is-invalid':
                  !loginForm.get('password').valid &&
                  loginForm.get('password').touched
              }"
              formControlName="password"
              name="password"
            />
            <i class="pi pi-lock"></i>
          </div>
          @if (error$ | async) {
          <p-message severity="error" text="{{ error$ | async }}"></p-message>
          }
          <div class="buttons">
            <button
              pButton
              type="submit"
              label="{{ 'core.login.submitButton' | translate }}"
              [disabled]="!loginForm.valid"
              [loading]="loading$ | async"
              icon="pi pi-check"
              [appEventTracker]="{ action: 'login' }"
            ></button>
            <a routerLink="/forgot-password" class="secondary-button">
              {{ "core.login.forgotPassword" | translate }}
            </a>
          </div>
        </form>
      </div>
    </div>

    <!-- <div class="desert"></div> -->
  </div>
</div>
