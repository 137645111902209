import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-common-status',
  templateUrl: './common-status.component.html',
  styles: ``,
})
export class CommonStatusComponent {
  @Input() status: string = undefined;
  @Input() rounded: boolean = true;

  getIcon(): string {
    switch (this.status) {
      case 'approved':
        return 'pi pi-check';
      case 'rejected':
        return 'pi pi-times';
      case 'pending':
        return 'pi pi-exclamation-circle';
      case 'cancelled':
        return 'pi pi-times-circle';
      case 'closed':
        return 'pi pi-check-circle';
      case 'in_progress':
        return 'pi pi-play';
      default:
        return '';
    }
  }

  getSeverity(): string {
    switch (this.status) {
      case 'approved':
        return 'success';
      case 'rejected':
        return 'danger';
      case 'pending':
        return 'warning';
      // case 'cancelled':
      //   return 'secondary';
      case 'closed':
        return 'success';
      case 'in_progress':
        return 'info';
      default:
        return '';
    }
  }
}
