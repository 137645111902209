import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
  AbstractControl,
} from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { PermissionHelperService } from '@core/services';
import { LocalStorageService } from '@shared/services';
import {
  AuthActions,
  AuthState,
  selectAuthError,
  selectAuthIsLoading,
  selectRestorePasswordSuccess,
} from '@app/store/auth';
import { SessionState, selectProfile } from '@app/store/session';

@Component({
  selector: 'app-restore-password',
  templateUrl: './restore-password.component.html',
  styles: [],
})
export class RestorePasswordComponent implements OnInit, OnDestroy {
  token: string;
  tenantId: string;
  frm: UntypedFormGroup;
  error$: Observable<any> = this.store.select(selectAuthError);
  loading$: Observable<boolean> = this.store.select(selectAuthIsLoading);

  // convenience getter for easy access to form fields
  get f() {
    return this.frm.controls;
  }

  _subscription: Subscription = new Subscription();

  constructor(
    private route: ActivatedRoute,
    private permissionHelperService: PermissionHelperService,
    private store: Store<AuthState>,
    private storeSession: Store<SessionState>,
    private localStorageService: LocalStorageService
  ) {
    // avoid problems with session
    localStorage.clear();

    this.route.queryParams.subscribe((params) => {
      this.token = params.t;
      this.tenantId = params.tid;
      // if (this.token === undefined || this.tenantId === undefined) {
      //   this.router.navigate(['/login']);
      // }
    });
    // frm
    this.frm = new UntypedFormGroup({
      email: new UntypedFormControl('', [
        Validators.required,
        Validators.email,
      ]),
      password: new UntypedFormControl('', [
        Validators.required,
        Validators.pattern(
          '^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&+=.-_*])([a-zA-Z0-9!@#$%^&+=*.-_]){8,}$'
        ),
      ]),
      passwordConfirmation: new UntypedFormControl('', [
        Validators.required,
        this.match('password'),
      ]),
    });
  }

  ngOnInit(): void {
    this._subscription
      .add(
        this.store.select(selectRestorePasswordSuccess).subscribe((value) => {
          if (value === true) {
            this.login();
          }
        })
      )
      .add(
        this.storeSession.select(selectProfile).subscribe((profile) => {
          if (profile.id !== -1) {
            this.permissionHelperService.navigateToHome();
          }
        })
      );
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  match(controlKey: string) {
    return (control: AbstractControl): { [s: string]: boolean } => {
      // control.parent es el FormGroup
      if (control.parent) {
        // en las primeras llamadas control.parent es undefined
        const checkValue = control.parent.controls[controlKey].value;
        if (control.value !== checkValue) {
          return {
            match: false,
          };
        }
      }
      return null;
    };
  }

  onSubmit() {
    this.localStorageService.clear({});
    if (this.tenantId !== 'central') {
      this.localStorageService.setTenantId(this.tenantId);
    }
    this.changePwd();
  }

  changePwd() {
    this.store.dispatch(
      AuthActions.restorePassword({
        email: this.f.email.value,
        password: this.f.password.value,
        passwordConfirmation: this.f.passwordConfirmation.value,
        token: this.token,
      })
    );
  }
  login() {
    this.store.dispatch(
      AuthActions.login({
        username: this.f.email.value,
        password: this.f.password.value,
        tenant: this.tenantId !== 'central',
      })
    );
  }
}
