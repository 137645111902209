import { HttpService } from '@shared/services';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
@Injectable({
  providedIn: 'root',
})
export class ExportsService {
  constructor(private http: HttpService) {}

  getPayrollDetails(
    ids: Array<number>,
    fileName: string,
    beginAt: string,
    endAt: string = ''
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(
          `exports/payroll-closes?${ids.map((x) => `ids[]=${x}`).join('&')}`,
          {
            responseType: 'blob',
          }
        )
        .subscribe(
          (response) => {
            this.downloadBlob(
              response,
              `${fileName}_${beginAt}${
                ['', null, undefined].includes(endAt) ? '' : `_${endAt}`
              }.xlsx`
            );
            resolve(true);
          },
          (error) => reject(error)
        );
    });
  }
  /**
   * Get a only pay_net of each employee in a payroll
   * @param payrollClose payrollClose Object
   * @returns Promise
   */
  getPayrollDetailsFullDetails(payrollClose: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(`exports/payroll-closes/${payrollClose.id}/full-details`, {
          responseType: 'blob',
        })
        .subscribe(
          (response) => {
            this.downloadBlob(
              response,
              `${payrollClose.payroll.name}_${payrollClose.beginAt}_${payrollClose.endAt}.xlsx`
            );
            resolve(true);
          },
          (error) => reject(error)
        );
    });
  }
  /**
   * Get a only pay_net of each employee in a payroll
   * @param payrollClose payrollClose Object
   * @returns Promise
   */
  getEmployeeList(company: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(`exports/companies/${company.id}/employees`, {
          responseType: 'blob',
        })
        .subscribe(
          (response) => {
            this.downloadBlob(
              response,
              `${company.name}_${moment().format('YYYY-MM-DD HH:mm:ss')}.xlsx`
            );
            resolve(true);
          },
          (error) => reject(error)
        );
    });
  }
  /**
   * helper to download file
   * @param response response
   * @param nameFile string
   */
  private downloadBlob(response, nameFile) {
    const myBlob = new Blob([response], { type: 'application/csv' });
    const downloadUrl = URL.createObjectURL(myBlob);
    let a = document.createElement('a');
    a.href = downloadUrl;
    a.download = nameFile;
    // start download
    a.click();
  }
  /**
   * Get Conventional attendance xls by sheet
   * @param company company object
   * @param start from date
   * @param end to date
   * @returns promise
   */
  getConventionalCard(company: any, start: string, end: string) {
    return new Promise((resolve, reject) => {
      this.http
        .get(
          `exports/companies/${company.id}/conventional-card?start=${start}&end=${end}`,
          {
            responseType: 'blob',
          }
        )
        .subscribe(
          (response) => {
            this.downloadBlob(
              response,
              `${company.name}_tarjeta_convencional_${moment().format(
                'YYYY-MM-DD HH:mm:ss'
              )}.xlsx`
            );
            resolve(true);
          },
          (error) => reject(error)
        );
    });
  }
  /**
   * Get Calculated attendance xls by sheet
   * @param company company object
   * @param start from date
   * @param end to date
   * @returns promise
   */
  getCalculatedCard(payroll: any, start: string, end: string) {
    return new Promise((resolve, reject) => {
      this.http
        .get(
          `exports/calculated-card?start=${start}&end=${end}&payroll_id=${payroll.id}`,
          {
            responseType: 'blob',
          }
        )
        .subscribe(
          (response) => {
            this.downloadBlob(
              response,
              `${payroll.name}_tarjeta_analizada_${moment().format(
                'YYYY-MM-DD HH:mm:ss'
              )}.xlsx`
            );
            resolve(true);
          },
          (error) => reject(error)
        );
    });
  }

  downloadExtraMonthBankReport(extraMonth: any) {
    return new Promise((resolve, reject) => {
      this.http
        .get(`exports/extra-months/${extraMonth.id}/bank`, {
          responseType: 'blob',
        })
        .subscribe(
          (response) => {
            this.downloadBlob(
              response,
              `${extraMonth.year}_aguinaldo_banco_${moment().format(
                'YYYY-MM-DD HH:mm:ss'
              )}.xlsx`
            );
            resolve(true);
          },
          (error) => reject(error)
        );
    });
  }

  downloadExtraMonthReport(extraMonth: any) {
    return new Promise((resolve, reject) => {
      this.http
        .get(`exports/extra-months/${extraMonth.id}/full`, {
          responseType: 'blob',
        })
        .subscribe(
          (response) => {
            this.downloadBlob(
              response,
              `${extraMonth.year}_aguinaldo_completo_${moment().format(
                'YYYY-MM-DD HH:mm:ss'
              )}.xlsx`
            );
            resolve(true);
          },
          (error) => reject(error)
        );
    });
  }

  getConsolidatedReport(closes: Array<number>): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(
          `exports/closes/consolidated?${closes
            .map((x) => `ids[]=${x}`)
            .join('&')}`,
          {
            responseType: 'blob',
          }
        )
        .subscribe(
          (response) => {
            this.downloadBlob(
              response,
              `consolidado_cierres_(${closes.length})_${moment().format(
                'YYYY-MM-DD HH:mm:ss'
              )}.xlsx`
            );
            resolve(true);
          },
          (error) => reject(error)
        );
    });
  }

  doAllVouchers(closes: Array<number>): Promise<any> {
    return this.http
      .get(
        `exports/closes/all-vouchers?${closes
          .map((x) => `ids[]=${x}`)
          .join('&')}`
      )
      .toPromise();
  }
}
