import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Loader } from '@googlemaps/js-api-loader';

@Injectable({
  providedIn: 'root',
})
export class GoogleMapsService {
  loader = new Loader({
    apiKey: environment.googleMaps.apiKey,
  });
  isReady: boolean = false;
  constructor() {
    this.loader
      .importLibrary('maps')
      .then(() => {
        this.isReady = true;
      })
      .catch(() => {});
  }
}
