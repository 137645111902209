import { PayrollManualItemType } from '@shared/data';
import { EditorManualItemService } from '@shared/services';
import {
  Component,
  Input,
  OnInit,
  OnDestroy,
  Output,
  EventEmitter,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-editor-manual-item',
  templateUrl: './editor-manual-item.component.html',
  styles: [],
})
export class EditorManualItemComponent implements OnInit, OnDestroy {
  items: Array<any> = [];
  @Input() payrollCloseId: number;
  @Input() employeeId: number;
  private _type: string = PayrollManualItemType[0].value;
  @Input() get type(): string {
    return this._type;
  }
  set type(value: string) {
    this._type = value;
    this.buttonLabel = this.translate.instant(
      'shared.editorManualItem.button_label',
      {
        value: this.translate.instant(
          'shared.payrollTypes.payrollManualItemType.' +
            PayrollManualItemType.find((x) => x.value === this._type).value
        ),
      }
    );
  }
  @Input() currency: string = 'USD';
  private original: Array<any> = [];

  private _summarize: number = 0;

  private get summarize(): number {
    return this._summarize;
  }

  buttonLabel: string = 'Crear adicional';

  loading: boolean = false;

  subscription: Subscription = new Subscription();

  @Input() allowEditData: boolean = true;

  @Output() changed = new EventEmitter();

  constructor(
    private _service: EditorManualItemService,
    private translate: TranslateService
  ) {
    this.subscription
      .add(
        this._service.data.subscribe((data) => {
          if (data !== null && data !== undefined && data.length > 0) {
            this.items = (data as Array<any>)
              .filter((x) => x.type === this.type)
              .map((y) => Object.assign(y, { uuid: uuidv4() }));
            this.sumDetails();
          } else {
            this.items = [];
          }
        })
      )
      .add(
        this._service.loading.subscribe((loading) => (this.loading = loading))
      );
  }
  ngOnDestroy(): void {
    // clean data to avoid show data of other employee
    this._service.clearDataSource();
  }

  ngOnInit(): void {}

  addItem(dt) {
    this.items.push({
      name: `${this.translate.instant(
        'shared.payrollTypes.payrollManualItemType.' +
          PayrollManualItemType.find((x) => x.value === this._type).value
      )} ${this.items.length + 1}`,
      amount: 0,
      type: this.type,
      uuid: uuidv4(),
    });
    dt.initRowEdit(this.items[this.items.length - 1]);
  }

  onRowEditInit(item) {
    const index = this.original.findIndex((x) => x.id === item.id);
    if (index === -1) {
      this.original.push(this.deepCopy(item));
    } else {
      this.original[index] = this.deepCopy(item);
    }
  }
  onRowEditSave(item) {
    item.loading = true;
    this._service
      .save({
        data: item,
        ids: [this.payrollCloseId, this.employeeId],
      })
      .then((response) => {
        this.changeOfItemsSuccess(item);
      })
      .catch(() => {})
      .finally(() => {
        item.loading = false;
        this.sumDetails();
      });
  }
  onRowEditCancel(item) {
    if (item.id) {
      this.items[this.items.findIndex((x) => x.id === item.id)] =
        this.original.find((x) => x.id === item.id);
    } else {
      this.items.splice(
        this.items.findIndex((x) => x === item),
        1
      );
    }
  }
  onRowEditDelete(item) {
    item.loading = true;
    if (item.id) {
      this._service
        .destroy({
          id: item.id,
          ids: [this.payrollCloseId, this.employeeId],
        })
        .then(() => this.changeOfItemsSuccess(item))
        .finally(() => {
          item.loading = false;
          this.sumDetails();
        });
    } else {
      this.items.splice(
        this.items.findIndex((x) => x === item),
        1
      );
    }
  }
  sumDetails() {
    this._summarize = this.items
      .filter((x) => x.type === this.type)
      .map((y) => Number.parseInt(y.amount))
      .reduce((prev, curr) => prev + curr, 0);
  }
  changeOfItemsSuccess(item: any) {
    this.changed.next(item);
    // publish that the payroll calculation has been updated
    this._service.payrollCloseWasUpdated(this.payrollCloseId);
  }

  deepCopy(obj: any) {
    return JSON.parse(JSON.stringify(obj));
  }
}
