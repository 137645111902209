import { Injectable } from '@angular/core';
import { HttpService } from '@app/shared/services';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LoanPaymentService {
  constructor(private http: HttpService) {}

  save(loanId: number, data: any) {
    return this.http[data.id ? 'put' : 'post'](
      `loans/${loanId}/payments${data.id ? `/${data.id}` : ''}`,
      data
    )
      .pipe(map((response) => response.data))
      .toPromise();
  }

  delete(loanId: number, paymentId: number) {
    return this.http
      .delete(`loans/${loanId}/payments/${paymentId}`)
      .pipe(map((response) => response.data))
      .toPromise();
  }
}
