import { HttpService } from '@shared/services';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ChartsService {
  constructor(private http: HttpService) {}
  attendance(
    companyId: number,
    start: string,
    end: string,
    chartType: string = 'line'
  ) {
    return this.http.get(
      `charts/companies/${companyId}/attendance?start=${start}&end=${end}&chartType=${chartType}`
    );
  }
  payrollIncomes(payrollId: number, start: string, end: string) {
    return this.http.get(
      `charts/payrolls/${payrollId}/incomes?start=${start}&end=${end}`
    );
  }
}
