export const ItemsMenu = [
  {
    label: 'central.menu.dashboard',
    icon: 'pi pi-chart-bar',
    routerLink: ['/central/dashboard'],
  },
  {
    label: 'central.menu.presignup',
    icon: 'pi pi-file',
    routerLink: ['/central/tenants/signup-requests'],
    permission: 'view_signup_request',
  },
  {
    label: 'central.menu.tenants',
    icon: 'pi pi-sitemap',
    routerLink: ['/central/tenants/crud'],
    permission: 'view_tenant',
  },
  {
    label: 'central.menu.devices',
    icon: 'pi pi-clock',
    routerLink: ['/central/devices'],
    permission: 'view_device',
  },
  {
    label: 'central.menu.roles',
    icon: 'pi pi-lock',
    routerLink: ['/central/roles'],
    permission: 'view_role',
  },
  {
    label: 'central.menu.users',
    icon: 'pi pi-user',
    routerLink: ['/central/users'],
    permission: 'view_user',
  },
  {
    label: 'central.menu.activity_log',
    icon: 'pi pi-history',
    routerLink: ['/central/activity-log'],
    permission: 'view_activity_log',
  },
  {
    label: 'central.menu.referrals',
    icon: 'pi pi-share-alt',
    routerLink: ['/central/referrals'],
    permission: ['viewany_referral', 'view_referral'],
  },
  // {
  //   label: 'central.menu.billing_settings',
  //   icon: 'pi pi-dollar',
  //   routerLink: ['/central/billing-settings'],
  // },
  {
    label: 'central.menu.custom_calculation_rules',
    icon: 'pi pi-cog',
    routerLink: ['/central/custom-calculation-rules'],
    permission: 'view_calculation_rule',
  },
];
