import { Injectable } from '@angular/core';
import { HttpService } from '@app/shared/services';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PayrollsService {
  constructor(private http: HttpService) {}
  getByCompany(companyId: number, withTrashed: boolean = false) {
    return this.http
      .get(`companies/${companyId}/payrolls?withTrashed=${Number(withTrashed)}`)
      .pipe(map((res) => res.data))
      .toPromise();
  }
  save(companyId: number, data: any) {
    return this.http[data.id === undefined ? 'post' : 'put'](
      `companies/${companyId}/payrolls` +
        (data.id !== undefined ? `/${data.id}` : ''),
      data
    )
      .pipe(map((res) => res.data))
      .toPromise();
  }
  delete(companyId: number, id: number) {
    return this.http
      .delete(`companies/${companyId}/payrolls/${id}`)
      .pipe(map((res) => res.data))
      .toPromise();
  }
}
