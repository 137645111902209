<div class="grid">
  <div class="col-12 md:col-5">
    @if (allowEditData) {
    <button
      pButton
      pRipple
      [label]="buttonLabel"
      icon="pi pi-plus"
      (click)="addItem(dt)"
      [loading]="loading"
      [appEventTracker]="{ action: 'create_manual_additional' }"
    ></button>
    }
  </div>
</div>
<p-table
  #dt
  [value]="items"
  dataKey="uuid"
  editMode="row"
  responsiveLayout="scroll"
>
  <ng-template pTemplate="header">
    @if (items.length > 0) {
    <tr>
      <th>{{ "shared.editorManualItem.concept" | translate }}</th>
      <th class="text-right">
        {{ "shared.editorManualItem.amount" | translate }}
      </th>
      <th style="min-width: 160px"></th>
    </tr>
    }
  </ng-template>
  <ng-template
    pTemplate="body"
    let-item
    let-editing="editing"
    let-ri="rowIndex"
  >
    @if (items.length > 0) {
    <tr [pEditableRow]="item">
      <td>
        <p-cellEditor>
          <ng-template pTemplate="input">
            <input
              pInputText
              type="text"
              maxlength="191"
              [(ngModel)]="item.name"
            />
          </ng-template>
          <ng-template pTemplate="output">
            {{ item.name }}
          </ng-template>
        </p-cellEditor>
      </td>
      <td class="text-right">
        <p-cellEditor>
          <ng-template pTemplate="input">
            <p-inputNumber
              [(ngModel)]="item.amount"
              mode="currency"
              [currency]="currency"
            >
            </p-inputNumber>
          </ng-template>
          <ng-template pTemplate="output">
            {{ item.amount | currency : currency }}
          </ng-template>
        </p-cellEditor>
      </td>
      <td class="text-right" style="width: 160px">
        @if (!editing) {
        <button
          pButton
          pRipple
          type="button"
          pInitEditableRow
          icon="pi pi-pencil"
          (click)="onRowEditInit(item)"
          class="p-button-rounded p-button-text"
          [disabled]="!allowEditData"
          [loading]="item?.loading"
        ></button>
        } @if (editing) {
        <button
          pButton
          pRipple
          type="button"
          pSaveEditableRow
          icon="pi pi-check"
          (click)="onRowEditSave(item)"
          [disabled]="item.amount <= 0"
          class="p-button-rounded p-button-text p-button-success mr-2"
          pTooltip="{{ 'shared.editorManualItem.save_changes' | translate }}"
          [appEventTracker]="{ action: 'save_changes_manual_additional' }"
        ></button>
        } @if (editing) {
        <button
          pButton
          pRipple
          type="button"
          pCancelEditableRow
          icon="pi pi-times"
          (click)="onRowEditCancel(item)"
          class="p-button-rounded p-button-text p-button-secondary mr-2"
          pTooltip="{{ 'shared.editorManualItem.cancel_editing' | translate }}"
        ></button>
        } @if (editing) {
        <button
          pButton
          pRipple
          type="button"
          pCancelEditableRow
          icon="pi pi-trash"
          (click)="onRowEditDelete(item)"
          class="p-button-rounded p-button-text p-button-danger"
          pTooltip="{{ 'shared.editorManualItem.delete_record' | translate }}"
          [appEventTracker]="{ action: 'delete_changes_manual_additional' }"
        ></button>
        }
      </td>
    </tr>
    }
  </ng-template>
  <ng-template pTemplate="summary">
    @if (items.length > 0) {
    <table class="col-12">
      <tr>
        <td class="font-bold" colspan="2">
          {{ "shared.editorManualItem.total" | translate }}
        </td>
        <td class="text-right">
          {{ summarize | currency : currency }}
        </td>
        <td style="width: 150px"></td>
      </tr>
    </table>
    }
  </ng-template>
</p-table>
