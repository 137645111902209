import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'viewDateRanges',
})
export class ViewDateRangesPipe implements PipeTransform {
  transform(value: unknown, ...args: unknown[]): unknown {
    if (!value) {
      return;
    }
    if (!args) {
      return;
    }
    return (
      moment(args[0]).format('DD/MM/YYYY') +
      ' ~ ' +
      moment(args[1]).format('DD/MM/YYYY')
    );
  }
}
