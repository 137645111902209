import { Component } from '@angular/core';

@Component({
  selector: 'app-footer',
  template: `
    <div class="layout-footer">
      <div class="logo-text">
        <div class="text">
          <span>
            <a href="https://bitappcloud.statuspage.io/" target="_blank">
              Status</a
            >
            •
            <a
              href="https://bitappcloud.com/politica-privacidad/"
              target="_blank"
            >
              Privacidad</a
            >
            •
            <a href="https://bit-office.com" target="_blank">
              Bit-Office © {{ year }}</a
            ></span
          >
        </div>
      </div>
      <div class="icons">
        <div class="icon">
          <a href="https://bitappcloud.com" target="_blank">
            <i class="pi pi-home"></i>
          </a>
        </div>
        <div class="icon">
          <a href="https://bitappcloud.com/contacto" target="_blank">
            <i class="pi pi-book"></i>
          </a>
        </div>
        <div class="icon">
          <a href="https://bitappcloud.com/contacto" target="_blank">
            <i class="pi pi-question"></i>
          </a>
        </div>
      </div>
    </div>
  `,
})
export class AppFooterComponent {
  year: number = new Date().getFullYear();
}
