export class PayrollCloseDetail {
  constructor(
    public id: number,
    public userId: number,
    public payrollCloseId: number,
    public employee: any,
    public resume: {
      netPay: number;
      totalIncomes: number;
      totalDeductions: number;
    },
    public incomes: any,
    public deductions: any,
    public attendance: any,
    public note: any
  ) {}
}
