import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { CompaniesReducer } from './company.reducers';
import { CompaniesEffects } from './company.effects';

@NgModule({
  imports: [
    StoreModule.forFeature('companies', CompaniesReducer),
    EffectsModule.forFeature([CompaniesEffects]),
  ],
})
export class CompanyStoreModule {}
