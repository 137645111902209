import {
  AccessDeniedComponent,
  ForgotPasswordComponent,
  AppLoginComponent,
  RestorePasswordComponent,
} from '@core/auth';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  {
    path: 'login',
    component: AppLoginComponent,
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
    data: {
      title: 'Recuperar contraseña',
    },
  },
  {
    path: 'reset-password',
    component: RestorePasswordComponent,
    data: {
      title: 'Establecer contraseña',
    },
  },
  {
    path: 'access-denied',
    component: AccessDeniedComponent,
    data: {
      title: 'Acceso denegado',
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CoreRoutingModule {}
