import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-icon-event-type',
  templateUrl: './icon-event-type.component.html',
  styles: [],
})
export class IconEventTypeComponent {
  @Input() event: any = null;
  @Input() iconClass: string = '';

  private classMap = {
    disability: 'fa-regular fa-heart text-primary',
    leave: 'fa-regular fa-calendar text-secondary-color',
    vacation: 'fa-regular fa-face-smile text-thirty-color',
    log: 'fa-regular fa-face-smile text-500',
    late: 'fa-regular fa-clock text-pink-500',
    before: 'fa-solid fa-person-running text-yellow-500',
    pause: 'fa-regular fa-circle-pause text-blue-500',
  };

  getClasses(eventType: string): string {
    return `${this.iconClass} ${this.classMap[eventType] || ''}`;
  }
}
