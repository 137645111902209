<form [formGroup]="dynamicForm">
  <div class="px-4 grid fluid">
    @for (group of groups | keyvalue; track group) {
      <div
        class="card col-12"
      [ngClass]="{
        'md:col-6': countGroups() === 2,
        'md:col-6 p-xl-4': countGroups() > 2
      }"
        >
        @if (group.value[0].groupName) {
          <h5>{{ group.value[0].groupName }}</h5>
        }
        @for (field of group.value; track field) {
          <div
            class="field"
            [ngClass]="{ grid: field.style?.layout === 'horizontal' }"
            >
            @if (field?.hideOnNew === true ? item?.id : true) {
              <div
                class="mb-2"
          [ngClass]="{
            'col-12 md:col-3 md:mb-0 p-d-md-flex md:align-items-center':
              field.style?.layout === 'horizontal'
          }"
                >
                @if (field.style?.icon) {
                  <i
                    class="pr-2"
                    [ngClass]="field.style.icon"
                  ></i>
                }
                <label
                  [for]="field.field"
                  [ngClass]="{ 'is-required': field.validators?.required === true }"
                  >{{ field.label | titlecase }}</label
                  >
                </div>
              }
              @if (field?.hideOnNew === true ? item?.id : true) {
                <div
          [ngClass]="{
            'col-12 md:col-7': field.style?.layout === 'horizontal'
          }"
                  >
                  <!-- input text -->
                  @if (field.type === 'text') {
                    <input
                      [formControlName]="field.field"
                      type="text"
                      pInputText
                      [placeholder]="field?.placeholder ? field.placeholder : ''"
                      [maxlength]="field.validators?.maxLength"
                      [autofocus]="field?.autofocus"
                      />
                  }
                  <!-- number -->
                  @if (field.type === 'number') {
                    <p-inputNumber
                      [formControlName]="field.field"
                      [maxlength]="field.validators?.maxLength"
                      [min]="field.number?.min"
                      [max]="field.number?.max"
                      [useGrouping]="field.number?.useGrouping"
                      [placeholder]="field?.placeholder ? field.placeholder : ''"
                    ></p-inputNumber>
                  }
                  <!-- currency -->
                  @if (field.type === 'currency') {
                    <p-inputNumber
                      [formControlName]="field.field"
                      [maxlength]="field.validators?.maxLength"
                      [min]="field.number?.min"
                      [max]="field.number?.max"
                      [useGrouping]="field.number?.useGrouping"
                      [placeholder]="field?.placeholder ? field.placeholder : ''"
                      [currency]="currencies[field.number.binding]"
                      [minFractionDigits]="2"
                      mode="currency"
                    ></p-inputNumber>
                  }
                  <!-- decimal -->
                  @if (field.type === 'decimal') {
                    <p-inputNumber
                      [formControlName]="field.field"
                      [maxlength]="field.validators?.maxLength"
                      [min]="field.number?.min"
                      [max]="field.number?.max"
                      [useGrouping]="field.number?.useGrouping"
                      [placeholder]="field?.placeholder ? field.placeholder : ''"
                      [minFractionDigits]="field.number?.minFractionDigits"
                      mode="decimal"
                    ></p-inputNumber>
                  }
                  <!-- percent -->
                  @if (field.type === 'percent') {
                    <p-inputNumber
                      [formControlName]="field.field"
                      [min]="field.number?.min"
                      [max]="field.number?.max"
                      [useGrouping]="field.number?.useGrouping"
                      [placeholder]="field?.placeholder ? field.placeholder : ''"
                      [minFractionDigits]="2"
                      suffix="%"
                    ></p-inputNumber>
                  }
                  <!-- text area -->
                  @if (field.type === 'textarea') {
                    <textarea
                      pInputTextarea
                      [formControlName]="field.field"
                      [maxlength]="field.validators?.maxLength"
                      [autofocus]="field?.autofocus"
                      [rows]="field.rows ? field.rows : 5"
                      [placeholder]="field?.placeholder ? field.placeholder : ''"
                    ></textarea>
                  }
                  <!-- datetime -->
                  @if (['date', 'datetime'].includes(field.type)) {
                    <p-calendar
                      [formControlName]="field.field"
                      [showIcon]="true"
                      [inputId]="field.field"
                      [showTime]="field.type === 'datetime'"
                      appendTo="body"
                      icon="pi pi-calendar"
                      [monthNavigator]="field.date?.showMonth"
                      [yearNavigator]="field.date?.showYear"
                      [touchUI]="useTouchUI()"
                    ></p-calendar>
                  }
                  <!-- date with year & month -->
                  @if (field.type === 'dateyearmonth') {
                    <p-calendar
                      [formControlName]="field.field"
                      [showIcon]="true"
                      [inputId]="field.field"
                      [showTime]="false"
                      appendTo="body"
                      icon="pi pi-calendar"
                      [monthNavigator]="true"
                      [yearNavigator]="true"
                      [yearRange]="yearRange"
                      [touchUI]="useTouchUI()"
                    ></p-calendar>
                  }
                  <!-- time -->
                  @if (['time'].includes(field.type)) {
                    <p-calendar
                      [formControlName]="field.field"
                      [showIcon]="true"
                      [inputId]="field.field"
                      [timeOnly]="true"
                      appendTo="body"
                      icon="pi pi-clock"
                      [touchUI]="useTouchUI()"
                    ></p-calendar>
                  }
                  <!-- autocomplete  -->
                  @if (field.type === 'autocomplete') {
                    <p-autoComplete
                      [formControlName]="field.field"
            [suggestions]="
              dynamicFormService.autocompletData[idForm + '.' + field.field]
                ?.filtered
            "
            (completeMethod)="
              dynamicFormService.filterSingle($event, field.field, idForm)
            "
                      [size]="field.size ? field.size : 60"
                      [placeholder]="field?.placeholder ? field.placeholder : ''"
                      [maxlength]="field.validators?.maxLength"
                    ></p-autoComplete>
                  }
                  <!-- dropdown -->
                  @if (field.type === 'dropdown') {
                    <p-dropdown
                      [formControlName]="field.field"
                      appendTo="body"
                      [placeholder]="field?.placeholder ? field.placeholder : ''"
            [options]="
              field.dropdown?.type === 'array'
                ? field.dropdown.items
                : dynamicFormService.dropdownData[idForm + '.' + field.field]
                    ?.data
            "
                      [required]="field?.validators?.required === false ? false : true"
                      [showClear]="field?.validators?.required === false ? true : false"
                    ></p-dropdown>
                  }
                  <!-- checkbox -->
                  @if (field.type === 'checkbox') {
                    <p-checkbox
                      [formControlName]="field.field"
                      binary="true"
                      [inputId]="field.field"
                    ></p-checkbox>
                  }
                  <!-- image -->
                  @if (field.type === 'image' && existsImageToShow(field.field)) {
                    <div
                      class="card widget-team"
                      >
                      <ul>
                        <li>
                          <div class="grid member">
                            <div class="col-7 team-box">
                              <div class="profile-image">
                                <img
                                  [src]="getImageUrl(field.field)"
                                  [alt]="field.field"
                                  />
                              </div>
                            </div>
                            <div class="col-5 icons">
                              <button
                                pButton
                                pRipple
                                type="button"
                                icon="pi pi-trash"
                                class="p-button-rounded p-button-danger"
                                (click)="onDeleteImage(field)"
                              ></button>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  }
                  <!-- fileupload -->
                  @if (field.type === 'image') {
                    <p-fileUpload
                      #uploader
                      fileLimit="1"
                      [chooseLabel]="'Subir ' + field.label"
                      customUpload="true"
                      (uploadHandler)="uploadMedia($event, field)"
                      accept="image/*"
                      maxFileSize="5000000"
                      mode="basic"
                      auto="true"
                      [chooseIcon]="'pi pi-plus'"
                      invalidFileSizeMessageSummary="{0}: tamaño de archivo invalido, "
                      invalidFileSizeMessageDetail="el tamaño máximo de carga es {0}."
                      invalidFileTypeMessageSummary="{0}: tipo de archivo invalido, "
                      invalidFileLimitMessageDetail="el limite es {0}."
                      invalidFileLimitMessageSummary="número de máximo de archivos excedido, "
                      invalidFileTypeMessageDetail="tipos de archivos permitidos: {0}."
                      >
                    </p-fileUpload>
                  }
                  @if (files.length && field.type === 'image') {
                    <div
                      class="card widget-team"
                      >
                      <!-- List of files images to upload -->
                      <ul>
                        @for (file of getFilesByLabel(field.field); track file) {
                          <li>
                            <div class="grid member">
                              <div class="col-7 team-box">
                                <div class="profile-image">
                                  <img
                                    [src]="file.value.objectURL"
                                    [alt]="file.value.name"
                                    />
                                </div>
                                <div>
                                  <h6 class="team-member">{{ file.value.name }}</h6>
                                  <!-- <span class="team-member-role">Sales</span> -->
                                </div>
                              </div>
                              <!-- <div class="col-5 icons">
                              <button
                                pButton
                                pRipple
                                type="button"
                                icon="pi pi-trash"
                                class="p-button-rounded p-button-danger"
                                (click)="onDeleteImage(field)"
                              ></button>
                            </div> -->
                          </div>
                        </li>
                      }
                    </ul>
                  </div>
                }
                <!-- <div
                *ngIf="files.length && field.type === 'image'"
                class="card widget-download-files"
                >
                <ul>
                  <li *ngFor="let file of files">
                    <div class="grid list-item">
                      <div class="col-2 icon">
                        <img [src]="file.value.objectURL" [alt]="file.value.name" />
                      </div>
                    </div>
                    <div class="col-10 file-name">
                      <h6 class="name">{{ file.value.name }}</h6>
                    </div>
                  </li>
                </ul>
              </div> -->
              <!-- select button -->
              @if (field.type === 'selectbutton') {
                <p-selectButton
                  [options]="field.items"
                  [formControlName]="field.field"
                ></p-selectButton>
              }
              <!-- errors message -->
              @if (
                dynamicForm.controls[field.field].errors?.required &&
                dynamicForm.controls[field.field].dirty
                ) {
                <small
                  [id]="field.type + '-help-required'"
                  class="p-error block"
                  >El valor de {{ field.label | lowercase }} es requerido.</small
                  >
                }
                @if (dynamicForm.controls[field.field].errors?.minlength) {
                  <small
                    [id]="field.type + '-help-required'"
                    class="p-error block"
                    >El valor {{ field.label | lowercase }} debe tener un minimo de
                    {{ field.validators.minLength }}.</small
                    >
                  }
                  @if (dynamicForm.controls[field.field].errors?.maxlength) {
                    <small
                      [id]="field.type + '-help-required'"
                      class="p-error block"
                      >El valor {{ field.label | lowercase }} debe tener un máximo de
                      {{ field.validators.maxLength }}</small
                      >
                    }
                  </div>
                }
              </div>
            }
          </div>
        }
        <!-- end of card -->
      </div>
      <!-- end of grid -->
    </form>
