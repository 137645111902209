export const IssuesTypes = [
  { label: 'tenant.attendanceTypes.late', value: 'late' },
  { label: 'tenant.attendanceTypes.before', value: 'before' },
  { label: 'tenant.attendanceTypes.overtime', value: 'overtime' },
  { label: 'tenant.attendanceTypes.missing', value: 'missing' },
  { label: 'tenant.attendanceTypes.absence', value: 'absence' },
  { label: 'tenant.attendanceTypes.exceed_break', value: 'exceed_break' },
  {
    label: 'tenant.attendanceTypes.cumulative_overtime',
    value: 'cumulative_overtime',
  },
];
