import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CompanyState } from './company.state';

export const selectCompanyState =
  createFeatureSelector<CompanyState>('companies');

export const selectCompaniesList = createSelector(
  selectCompanyState,
  (state) => state.companies
);

export const selectCompaniesIsLoading = createSelector(
  selectCompanyState,
  (state) => state.isLoading
);
export const selectCompaniesError = createSelector(
  selectCompanyState,
  (state) => state.error
);
export const selectCompaniesIsEditing = createSelector(
  selectCompanyState,
  (state) => state.isEditing
);
