import { ServiceDataStorage } from '@shared/helpers';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class GeneralSettingsService extends ServiceDataStorage {
  constructor() {
    super('general-settings');
  }

  getBankXLSSettings() {
    return this.httpService.get('bank-entity-xls-settings');
  }
  updateBankXLSSettings(fields: Array<string>) {
    return this.httpService.put('bank-entity-xls-settings', { fields });
  }
  getVacationSettings() {
    return this.httpService.get('vacation-settings');
  }
  updateVacationSettings(data: any) {
    return this.httpService.put('vacation-settings', data);
  }
}
