<div class="login-body layout-light">
  <div class="login-content">
    @if (!successEmail) {
    <div class="login-panel">
      <div class="login-panel-content">
        <div class="logo">
          <img
            src="assets/layout/images/logo-mirage@2x.png"
            alt="mirage-layout"
          />
        </div>
        <h1>{{ "core.forgot_password.recover_password_title" | translate }}</h1>
        <p>
          {{ "core.forgot_password.message" | translate }}
        </p>
        <form class="forms" [formGroup]="pwdForm" (ngSubmit)="onSubmit()">
          <div class="login-input-wrapper">
            <label class="is-required" for="customerId">{{
              "core.forgot_password.customer_id" | translate
            }}</label>
            <input
              id="customerId"
              type="text"
              pInputText
              [placeholder]="
                ('core.forgot_password.customer_id' | translate) + '...'
              "
              [ngClass]="{
                'is-invalid':
                  !pwdForm.get('customerId').valid &&
                  pwdForm.get('customerId').touched
              }"
              formControlName="customerId"
              name="customerId"
            />
            <i class="pi pi-cloud"></i>
          </div>
          <div class="login-input-wrapper">
            <label class="is-required" for="username">{{
              "core.forgot_password.email_address" | translate
            }}</label>
            <input
              id="username"
              type="email"
              pInputText
              [placeholder]="
                ('core.forgot_password.email_address' | translate) + '...'
              "
              [ngClass]="{
                'is-invalid':
                  !pwdForm.get('username').valid &&
                  pwdForm.get('username').touched
              }"
              formControlName="username"
              name="username"
            />
            <i class="pi pi-user"></i>
          </div>
          @if (error) {
          <p-message severity="error" text="{{ error }}"></p-message>
          }
          <div class="buttons">
            <button
              pButton
              type="submit"
              [label]="'core.forgot_password.send_email' | translate"
              [disabled]="!pwdForm.valid"
              [loading]="loading"
              icon="pi pi-check"
              [appEventTracker]="{ action: 'request_password_email' }"
            ></button>
            <button
              pButton
              routerLink="/login"
              pRipple
              type="button"
              icon="pi pi-arrow-left"
              [label]="'core.forgot_password.return_login' | translate"
              class="secondary-button"
            ></button>
          </div>
        </form>
      </div>
    </div>
    }

    <!-- success message -->
    @if (successEmail) {
    <div class="login-panel">
      <div class="login-panel-content">
        <div class="logo">
          <img
            src="assets/layout/images/logo-mirage@2x.png"
            alt="mirage-layout"
          />
        </div>
        <h1>{{ "core.forgot_password.recover_password_title" | translate }}</h1>
        <p-message
          severity="success"
          [text]="'core.forgot_password.email_send_success' | translate"
        ></p-message>
        <p-tag
          styleClass="p-mt-2"
          severity="info"
          [value]="'core.forgot_password.close_window' | translate"
        ></p-tag>
      </div>
    </div>
    }

    <!-- <div class="desert"></div> -->
  </div>
</div>
