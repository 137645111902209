import { AuthenticationService } from '@app/core/services';
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError, filter, take, switchMap, finalize } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ErrorRepository } from '@app/shared/repositories';
import { AuthActions, AuthState } from '@app/store/auth';
import { Store } from '@ngrx/store';
import { LocalStorageService } from '@app/shared/services';

@Injectable({
  providedIn: 'root',
})
export class ErrorInterceptorService implements HttpInterceptor {
  private refreshTokenInProgress = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private store: Store<AuthState>,
    private localStorageService: LocalStorageService
  ) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error) => {
        switch ((error as HttpErrorResponse).status) {
          // case 400:
          //   this.authService.logout();
          //   break;
          case 401:
            return this.handleError401(request, next, error);
          case 403:
            this.router.navigate(['/access-denied'], {
              state: {
                message:
                  ErrorRepository.extractErrorMessagesFromErrorResponse(
                    error
                  ).join(', '),
              },
            });
            break;
          case 500:
            return this.handleError500(request, next, error);
          case 503:
            this.router.navigate(['/maintenance']);
            break;
        }

        // const error = err.error.message || err.statusText;
        return throwError(error);
      })
    );
  }

  private handleError401(
    request: HttpRequest<any>,
    next: HttpHandler,
    error: HttpErrorResponse
  ) {
    // We don't want to refresh token for some requests like login or refresh token itself
    // So we verify url and we throw an error if it's the case
    if (
      request.url.includes('refresh') ||
      request.url.includes('login') ||
      request.url.includes('logout')
    ) {
      // We do another check to see if refresh token failed
      // In this case we want to logout user and to redirect it to login page

      if (request.url.includes('refresh')) {
        this.store.dispatch(AuthActions.logout());
      }

      return throwError(error);
    }

    // 401 errors are most likely going to be because we have an expired token that we need to refresh.
    if (this.refreshTokenInProgress) {
      // If refreshTokenInProgress is true, we will wait until refreshTokenSubject has a non-null value
      // which means the new token is ready and we can retry the request again
      return this.refreshTokenSubject.pipe(
        filter((result) => result !== null),
        take(1),
        switchMap(() => next.handle(this.addAuthenticationToken(request)))
      );
    } else {
      this.refreshTokenInProgress = true;
      // Set the refreshTokenSubject to null so that subsequent API calls will wait until the new token has been retrieved
      this.refreshTokenSubject.next(null);

      // if not has token avoid refresh
      if (!this.authService.isAuthenticated()) {
        this.store.dispatch(AuthActions.logout()); // force to clean & navigate to login
        this.refreshTokenSubject.next(true);
        this.refreshTokenInProgress = false;
        return throwError(error);
      }

      if (
        error.status === 401 &&
        [
          'Token has been revoked',
          'Check the `refresh_token` parameter',
          'Cannot decrypt the refresh token',
          // 'Unauthorized: Access is denied due to invalid credentials.',
        ].indexOf(error.error.hint) > -1
      ) {
        this.store.dispatch(AuthActions.logout());
        this.refreshTokenSubject.next(true);
        this.refreshTokenInProgress = false;
        return throwError(error);
      }

      return this.authService.refreshToken().pipe(
        switchMap((success: boolean) => {
          this.refreshTokenSubject.next(success);
          return next.handle(this.addAuthenticationToken(request));
        }),
        // When the call to refreshToken completes we reset the refreshTokenInProgress to false
        // for the next time the token needs to be refreshed
        finalize(() => (this.refreshTokenInProgress = false)),
        catchError((err) => {
          this.store.dispatch(AuthActions.logout());
          this.refreshTokenSubject.next(true);
          this.refreshTokenInProgress = false;
          return throwError(err);
        })
      );
    }
  }
  private addAuthenticationToken(request: HttpRequest<any>): HttpRequest<any> {
    // If we do not have a token yet then we should not set the header.
    // Here we could first retrieve the token from where we store it.
    if (!this.localStorageService.getAccessToken()) {
      return request;
    }
    // // If you are calling an outside domain then do not add the token.
    // if (!request.url.match(/www.mydomain.com\//)) {
    //   return request;
    // }
    return request.clone({
      headers: request.headers.set(
        'Authorization',
        `Bearer ${this.localStorageService.getAccessToken()}`
      ),
    });
  }

  private handleError500(
    request: HttpRequest<any>,
    next: HttpHandler,
    error: HttpErrorResponse
  ) {
    // We don't want to refresh token for some requests like login or refresh token itself
    // So we verify url and we throw an error if it's the case
    if (
      request.url.includes('refresh') ||
      request.url.includes('login') ||
      request.url.includes('logout')
    ) {
      // We do another check to see if refresh token failed
      // In this case we want to logout user and to redirect it to login page

      if (request.url.includes('refresh')) {
        this.store.dispatch(AuthActions.logout());
      }

      return throwError(error);
    }

    //Tenant could not be identified
    if (
      error.status === 500 &&
      ['Tenant could not be identified', 'does not exist.'].some((w) =>
        error.error.error.toLowerCase().includes(w.toLowerCase())
      )
    ) {
      this.store.dispatch(AuthActions.logout());
    }
    return throwError(error);
  }
}
