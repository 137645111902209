<div class="grid p-3">
  <app-employee-name
    class="col-12"
    [employee]="payrollCloseDetail.employee"
  ></app-employee-name>

  <h5 class="col-12">
    {{ "shared.employeePayrollDetail.incomes" | translate }}
  </h5>
  <p-table [value]="incomes" class="col-12">
    <ng-template pTemplate="body" let-income>
      @if (showRow(income)) {
      <tr>
        <td>
          <span class="p-column-title">{{
            "shared.employeePayrollDetail.concept" | translate
          }}</span
          >{{ income.name }}
        </td>
        <td class="text-center">
          <span class="p-column-title">{{
            "shared.employeePayrollDetail.time" | translate
          }}</span>
          @if (payrollClose) {
          <app-cumulative-time-editor
            [itemDetail]="payrollCloseDetail"
            [isCumulative]="isCumulative()"
            [rowName]="income.name"
            [minutesKey]="income.rule?.minutes"
            (changed)="onChangedManual($event)"
            [payrollCloseStatus]="payrollClose.status"
          ></app-cumulative-time-editor>
          }
        </td>
        <td class="text-right">
          <span class="p-column-title">{{
            "shared.employeePayrollDetail.amount" | translate
          }}</span
          >{{ income.total | currency : payroll.currency }}
        </td>
      </tr>
      }
    </ng-template>
    <ng-template pTemplate="summary">
      <div
        class="flex align-items-center justify-content-between"
        [ngStyle]="{ color: 'var(--secondary-color)' }"
      >
        <span
          >{{
            "shared.employeePayrollDetail.total_gross_income" | translate
          }}:</span
        >
        <span>{{
          payrollCloseDetail?.resume?.totalIncomes | currency : payroll.currency
        }}</span>
      </div>
    </ng-template>
  </p-table>

  <h5 class="col-12">
    {{ "shared.employeePayrollDetail.additional_incomes" | translate }}
  </h5>
  <app-editor-manual-item
    [payrollCloseId]="payrollCloseDetail.payrollCloseId"
    [employeeId]="payrollCloseDetail.employee.id"
    [type]="'income'"
    [currency]="payroll.currency"
    (changed)="onChangedManual($event)"
    [allowEditData]="allowEditData"
    class="col-12"
  ></app-editor-manual-item>

  <h5 class="col-12">
    {{ "shared.employeePayrollDetail.deductions" | translate }}
  </h5>
  <p-table [value]="deductions" class="col-12">
    <ng-template pTemplate="body" let-deduction>
      @if (deduction.total != 0) {
      <tr>
        <td>
          <span class="p-column-title">{{
            "shared.employeePayrollDetail.concept" | translate
          }}</span
          >{{ deduction.name }}
        </td>
        <td class="text-right">
          <span class="p-column-title">{{
            "shared.employeePayrollDetail.amount" | translate
          }}</span>
          {{ deduction.total | currency : payroll.currency }}
        </td>
      </tr>
      }
    </ng-template>
    <ng-template pTemplate="summary">
      <div
        class="flex align-items-center justify-content-between"
        [ngStyle]="{ color: 'var(--secondary-color)' }"
      >
        <span
          >{{
            "shared.employeePayrollDetail.total_deductions" | translate
          }}:</span
        >
        <span>{{
          payrollCloseDetail?.resume?.totalDeductions
            | currency : payroll.currency
        }}</span>
      </div>
    </ng-template>
  </p-table>

  <h5 class="col-12">
    {{ "shared.employeePayrollDetail.additional_deductions" | translate }}
  </h5>
  <app-editor-manual-item
    [payrollCloseId]="payrollCloseDetail.payrollCloseId"
    [employeeId]="payrollCloseDetail.employee.id"
    [type]="'deduction'"
    [currency]="payroll.currency"
    (changed)="onChangedManual($event)"
    [allowEditData]="allowEditData"
    class="col-12"
  ></app-editor-manual-item>

  <div
    class="col-12 flex align-items-center justify-content-between mt-3 net-pay"
    [ngStyle]="{ color: 'var(--secondary-color)' }"
  >
    <h5 class="my-1">
      {{ "shared.employeePayrollDetail.net_pay" | translate }}:
    </h5>
    <h5 class="my-1">
      {{ payrollCloseDetail?.resume?.netPay | currency : payroll.currency }}
    </h5>
  </div>

  <h5 class="col-12">{{ "shared.employeePayrollDetail.notes" | translate }}</h5>
  <div class="field col-12">
    <textarea
      placeholder="{{
        'shared.employeePayrollDetail.notes_placeholder' | translate
      }}"
      rows="2"
      pInputTextarea
      [(ngModel)]="note"
      maxlength="500"
      [disabled]="isUpdatingNote$ | async"
      [readonly]="!allowEditData"
    ></textarea>
    @if (allowEditData) {
    <button
      pButton
      pRipple
      label="{{ 'shared.employeePayrollDetail.save_note' | translate }}"
      icon="pi pi-check"
      (click)="saveNote()"
      [loading]="isUpdatingNote$ | async"
      class="col-12 md:col-5 mt-3"
    ></button>
    }
  </div>
</div>
