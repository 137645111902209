import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, map, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppMainComponent } from '@core/mirage';
import { AuthenticationService, PermissionHelperService } from '@core/services';
import {
  SessionActions,
  SessionState,
  selectProfile,
  selectSessionMenuItems,
} from '@app/store/session';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-menu',
  templateUrl: './app.menu.component.html',
})
export class AppMenuComponent implements OnInit, OnDestroy {
  model$ = this.store.select(selectSessionMenuItems);
  currentModule: string = 'central';
  subscription: Subscription = new Subscription();

  constructor(
    public appMain: AppMainComponent,
    private authService: AuthenticationService,
    private permissionHelperService: PermissionHelperService,
    private router: Router,
    private store: Store<SessionState>
  ) {
    this.navigationEnd()
      .pipe(take(1))
      .subscribe((url) => this.identifyModule(url));
  }

  ngOnInit() {
    this.subscription.add(
      this.store
        .select(selectProfile)
        .pipe(take(1))
        .subscribe((profile) =>
          this.store.dispatch(
            SessionActions.getMenuItems({ module: this.currentModule })
          )
        )
    );
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  identifyModule(url: string) {
    if (url.startsWith('/customer')) {
      this.currentModule = 'tenant';
      return;
    }
    if (url.startsWith('/employee')) {
      this.currentModule = 'self-management';
      return;
    }
    this.currentModule = 'central';
  }

  changeModule() {
    if (!this.authService.isTenant()) {
      this.currentModule = 'central';
    }
    if (this.permissionHelperService.isSelfManagement()) {
      this.currentModule = 'self-management';
    } else {
      this.currentModule = 'tenant';
    }
  }

  onMenuClick() {
    this.appMain.menuClick = true;
  }

  navigationEnd() {
    return this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map((event: NavigationEnd) => event.url)
    );
  }
}
