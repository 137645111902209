import { HttpErrorResponse } from '@angular/common/http';

export class ErrorRepository {
  public static extractErrorMessagesFromErrorResponse(
    errorResponse: HttpErrorResponse
  ): Array<any> {
    // 1 - Create empty array to store errors
    const errors = [];
    // 2 - check if the error object is present in the response
    if (errorResponse.error) {
      // 3 - Push the main error message to the array of errors
      // errors.push(errorResponse.error.message);

      // 4 - Check for Laravel form validation error messages object
      if (errorResponse.error.errors) {
        // 5 - For each error property (which is a form field)
        for (const property in errorResponse.error.errors) {
          if (errorResponse.error.errors.hasOwnProperty(property)) {
            // 6 - Extract it's array of errors
            const propertyErrors: Array<string> =
              errorResponse.error.errors[property];

            // 7 - Push all errors in the array to the errors array
            propertyErrors.forEach((error) => errors.push(error));
          }
        }
      }

      // 8 - Check for Laravel form validation error messages object
      if (errorResponse.error.error) {
        if (
          errorResponse.error.error instanceof Array ||
          errorResponse.error.error instanceof Object
        ) {
          // 9 - For each error property (which is a form field)
          for (const property in errorResponse.error.error) {
            if (errorResponse.error.error.hasOwnProperty(property)) {
              // 10 - Extract it's array of errors
              const propertyErrors: Array<string> =
                errorResponse.error.error[property];
              // 11 - Push all errors in the array to the errors array
              propertyErrors.forEach((error) => errors.push(error));
            }
          }
        } else {
          // 12- Is a flat message
          errors.push(errorResponse.error.error);
        }
      }
    }
    return errors;
  }
}
